import React from "react";
import "./index.scss";

import axios from "axios";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";

import * as backendModule from "../../../../../modules/backendModule";
import * as basicStylesModule from "../../../../../modules/basicStylesModule";
import * as siteFunctionsActions from "../../../../../actions/siteFunctionsActions";
import useDefer from "../../../../../modules/hooks/useDefer";
import useOnScreen from "../../../../../modules/hooks/useOnScreen";
import { animateBox } from "../../../../../modules/componentAnimation";
import { countries } from "../../../../../modules/countryModule";

import { FilteredCustomTable } from "../../../../../components/customComponents/Table";
import Spinner from "../../../../../components/customComponents/Spinner";
import StyledButton from "../../../../../components/styledComponents/Button";
import StyledInput from "../../../../../components/styledComponents/Input";
import Dropdown from "../../../../../components/customComponents/Dropdown";
import RadioButton from "../../../../../components/customComponents/RadioButton";

import YesNoModal from "../../../../../components/modals/YesNoModal";

const AdminAutomations_FbWarmup = () => {
    const [data, setData] = React.useState();
    const [canPaginate, setCanPaginate] = React.useState(false);
    const [search, setSearch] = React.useState("");
    const [includeWatcher, setIncludeWatcher] = React.useState(false);

    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");
    const curDispatch = useDispatch();
    const curOnScreen = useOnScreen();
    const curDefer = useDefer();
    const timestampRef = React.useRef();

    const getData = ts => {
        if (ts !== timestampRef.current) return;
        setCanPaginate(false);

        let filters = [];
        if (search) {
            filters.push({and: [
                {name: "AccountName", op: "like", value: search},
                {name: "PageName", op: "like", value: search}
            ]});
        };

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/integrations/facebook/warmup/getAll`,
            data: {
                limit: 20,
                offset: 0,
                orders: [{name: "createdAt", order: "desc"}],
                filters,
                includeWatcher
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (ts !== timestampRef.current) return;
            setData(res.data);
            if (res.data.status === "ok") {
                if (res.data.data.length >= 20) setCanPaginate(true);
            };
        }).catch(() => {
            if (ts !== timestampRef.current) return;
            setData(backendModule.genericError);
        });
    };

    const continueData = ts => {
        if (ts !== timestampRef.current) return;
        if (data?.status !== "ok") return;
        setCanPaginate(false);

        let filters = [];
        if (search) {
            filters.push({and: [
                {name: "AccountName", op: "like", value: search},
                {name: "PageName", op: "like", value: search}
            ]});
        };

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/integrations/facebook/warmup/getAll`,
            data: {
                limit: 20,
                offset: 0,
                orders: [{name: "createdAt", order: "desc"}],
                filters: [
                    {name: "ID", op: "notIn", value: data.data.map(d => d.ID)},
                    ...filters
                ],
                includeWatcher
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (ts !== timestampRef.current) return;
            if (res.data.status === "ok") {
                if (res.data.data.length >= 20) setCanPaginate(true);
                setData(d => {
                    return {
                        ...d,
                        data: [
                            ...d.data,
                            ...res.data.data
                        ]
                    };
                });
            };
        }).catch(() => null);
    };

    const stopWramup = ID => {
        animateBox(<YesNoModal
            heading="Are you sure?"
            text="Stopping the warmup is irreversible. You can't start the same warmup once it has been stopped and you will need to create a new one."
            buttonLeftText="No"
            buttonRightText="Yes"
            isRightButtonNormal={true}
            buttonRightCallback={args => {
                args.spinner(true);
                args.disabledAll(true);
                args.errorMessage();

                axios({
                    method: "POST",
                    url: `${backendModule.backendURL}/integrations/facebook/warmup/stop`,
                    data: {ID},
                    ...backendModule.axiosConfig
                }).then(res => {
                    if (res.data.status === "ok") {
                        args.close();

                        let ts = Date.now();
                        timestampRef.current = ts;
                        getData(ts);
                    } else {
                        args.errorMessage("There was an error while stopping the warmup process!");
                    };
                }).catch(() => {
                    args.errorMessage("Server timed out!");
                }).finally(() => {
                    args.spinner(false);
                    args.disabledAll(false);
                });
            }}
        />)
    };

    React.useEffect(() => {
        if (!canPaginate) return;
        if (!curOnScreen.isIntersecting) return;

        try {
            curOnScreen.observer.unobserve(curOnScreen.measureRef.current);
        } catch {};

        let ts = Date.now();
        timestampRef.current = ts;
        continueData(ts);
    }, [canPaginate, curOnScreen.isIntersecting]);

    React.useEffect(() => {
        const handler = () => {
            let ts = Date.now();
            timestampRef.current = ts;
            curDefer(() => getData(ts), 500);
        };
        handler();

        curDispatch(siteFunctionsActions.addHeaderRefreshAction(handler));

        return () => curDispatch(siteFunctionsActions.removeHeaderRefreshAction(handler));
    }, [includeWatcher, search]);

    return <div className="route__automations__fbWarmup">
        <div className="route__automations__fbWarmup__head">
            <StyledInput onChange={e => setSearch(e.target.value)} placeholder="Search..." alternateStyle />
            <StyledButton style={{display: "flex", alignItems: "center", gap: "10px"}} onClick={() => {
                animateBox(<AdminAutomations_FbWarmup_add onChange={() => {
                    let ts = Date.now();
                    timestampRef.current = ts;
                    getData(ts);
                }} />)
            }}>
                <img src="/images/icon_add.svg" />
                <span>Add</span>
            </StyledButton>
        </div>
        <div className="route__automations__fbWarmup__filters" onClick={() => setIncludeWatcher(iw => !iw)}>
            <div>
                <RadioButton checked={includeWatcher} />
                <span>Include watcher</span>
            </div>
        </div>
        <FilteredCustomTable
            theme={themeSelector}
            accent="#6C5DD3"
            headers={["Account", "Page", "AdsPower ID", "Ads created", "Last executed", "Ends in", "Created at", "Integration active", ""]}
            style={{columnGap: "40px"}}
            customColumns={(new Array(8)).fill("max-content")}
            data={(() => {
                if (!data) return [[{keyID: "noData-spinner", type: "spinner", color: themeSelector === "dark" ? "white" : "black"}]];
                if (data.status !== "ok") return [[{keyID: "noData-error", type: "text", text: "Error while fetching data!", style: {color: themeSelector == "dark" ? basicStylesModule.errorColor : basicStylesModule.errorColorLight}}]];

                let out = [];
                for (let item of data.data) {
                    out.push([
                        {keyID: item.ID, type: "text", text: `[ ${item.IntegrationID.replace("fb-act_", "")} ] ${item.AccountName}`},
                        {keyID: item.ID, type: "text", text: item.PageName},
                        {keyID: item.ID, type: "text", text: item.AdsPowerID ? item.AdsPowerID : <span style={{color: "gray"}}>(none)</span>, style: {cursor: "pointer"}, onClick: () => animateBox(<AdminAutomations_FbWarmup_adspowerid
                            ID={item.ID}
                            AdsPowerID={item.AdsPowerID}
                            onChange={() => {
                                let ts = Date.now();
                                timestampRef.current = ts;
                                getData(ts);
                            }}
                        />)},
                        {keyID: item.ID, type: "text", text: item.AdsCreated},
                        {keyID: item.ID, type: "text", text: item.AdsCreated === 0 ? <span style={{color: "gray"}}>Not yet executed</span> : moment(item.lastExecutedAt).fromNow()},
                        {keyID: item.ID, type: "text", text: moment(item.expiresAt).isBefore(moment()) ? "Ended" : moment(item.expiresAt).fromNow()},
                        {keyID: item.ID, type: "text", text: moment(item.createdAt).toDate().toLocaleString()},
                        {keyID: item.ID, type: "text", text: item._IntegrationActive ? "Yes" : "No", style: {color: item._IntegrationActive ? (themeSelector === "dark" ? basicStylesModule.successColor : basicStylesModule.successColorLight) : (themeSelector === "dark" ? basicStylesModule.errorColor : basicStylesModule.errorColorLight)}},
                        {keyID: item.ID, type: "custom", data: <div style={{display: "flex", alignItems: "center", justifyContent:"flex-start", gap: "10px", width: "100%"}}>
                            {!moment(item.expiresAt).isBefore(moment()) && <StyledButton isSecondary style={{
                                "height": "100%",
                                "display": "block",
                                "background": "transparent",
                                "border": "1px solid rgba(92, 117, 130, 0.698)",
                                "color": themeSelector === "dark" ? "white" : "black",
                                "padding": "0px 10px"
                            }} onClick={() => stopWramup(item.ID)}>Stop</StyledButton>}

                            <StyledButton isSecondary style={{
                                "height": "100%",
                                "display": "block",
                                "background": "transparent",
                                "border": "1px solid rgba(92, 117, 130, 0.698)",
                                "color": themeSelector === "dark" ? "white" : "black",
                                "padding": "0px 10px"
                            }} onClick={() => animateBox(<AdminAutomations_FbWarmup_history history={item.History} />)}>History</StyledButton>
                        </div>}
                    ].filter(f => f));
                };

                if (out.length === 0) return [[{keyID: "noData-noData", type: "text", text: "Nothing to show..."}]];
                return out;                
            })()}
        />
        {canPaginate && <div ref={curOnScreen.measureRef}>
            <Spinner color={themeSelector === "dark" ? "white" : "black"} />
        </div>}
    </div>
};

const AdminAutomations_FbWarmup_adspowerid = props => {
    const [spinner, setSpinner] = React.useState(false);

    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");
    const adspowerIDRef = React.useRef();

    const saveNewID = () => {
        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/integrations/facebook/warmup/editAdsPowerID`,
            data: {
                ID: props.ID,
                AdsPowerID: adspowerIDRef.current.value ?? ""
            },
            ...backendModule.axiosConfig
        }).then(() => null).catch(() => null).finally(() => {
            setSpinner(false);
            if (typeof(props.onChange) === "function") props.onChange();
            props.onClose();
        });
    };

    return <div className="genericModal">
        <div className="genericModal__wrap">
            <div className="genericModal__wrap__head">
                <div className="genericModal__wrap__head__left">Edit AdsPower ID</div>
                <div className="genericModal__wrap__head__right" onClick={props.onClose} style={{backgroundImage: `url("/images/icon_close.svg")`}}></div>
            </div>

            <div className="genericModal__wrap__input">
                <p>AdsPower ID</p>
                <input ref={adspowerIDRef} type="text" defaultValue={props.AdsPowerID} />
            </div>

            <div className="genericModal__wrap__buttons">
                <div className="genericModal__wrap__buttons__btn genericModal__wrap__buttons__btn--secondary" onClick={props.onClose}>Close</div>
                <div className="genericModal__wrap__buttons__btn" onClick={() => !spinner && saveNewID()}>
                    {spinner ? <Spinner style={{width: "17px", height: "17px"}} color={themeSelector === "dark" ? "white" : "black"} /> : "Save"}
                </div>
            </div>
        </div>
    </div>
};

const AdminAutomations_FbWarmup_add = props => {
    const [selectedAccount, setSelectedAccount] = React.useState();
    const [selectedPage, setSelectedPage] = React.useState();
    const [allPages, setAllPages] = React.useState();
    const [selectedCreatives, setSelectedCreatives] = React.useState([""]);
    const [selectedCountry, setSelectedCountry] = React.useState();
    const [spinner, setSpinner] = React.useState(false);
    const [infoP, setInfoP] = React.useState("");

    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");
    const selectedTrackingProfilesSelector = useSelector(state => state?.trackingProfiles?.profiles ?? []);

    const durationRef = React.useRef();
    const adsPowerIDRef = React.useRef();

    const addAccount = async () => {
        if (spinner) return;
        setInfoP("");
        let data = {
            ...selectedAccount,
            PageID: selectedPage,
            Creatives: [...new Set(selectedCreatives.filter(c => c))],
            Duration: durationRef.current.value,
            Country: selectedCountry,
            AdsPowerID: adsPowerIDRef.current.value
        };

        if (!data.IntegrationID || !data.AccountID) return setInfoP("Select an account!");
        if (!data.PageID) return setInfoP("Select a page");
        if (data.Creatives.length === 0) return setInfoP("Add at least 1 creative");
        data.Duration = Number(data.Duration);
        if (isNaN(data.Duration)) return setInfoP("Duration must be a number");
        if (data.Duration < 24) return setInfoP("Duration must be at least 24 hours");
        if (!data.Country) return setInfoP("Country not selected");

        setSpinner(true);
        let allCreatives = await axios({
            method: "POST",
            url: `${backendModule.backendURL}/creatives/getAllCreatives`,
            data: {
                limit: null,
                filters: [
                    {name: "CustomID", op: "in", value: data.Creatives},
                    {name: "FileType", op: "eq", value: "image"}
                ]
            },
            ...backendModule.axiosConfig
        }).then(res => res.data).catch(() => backendModule.genericError);
        if (allCreatives.status !== "ok") {
            setSpinner(false);
            setInfoP("Failed to fetch creatives!");
            return;
        };
        if (allCreatives.data.length !== data.Creatives.length) {
            setSpinner(false);
            setInfoP("Some creatives werent found");
            return;
        };

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/integrations/facebook/warmup/create`,
            data: {
                ...data,
                Creatives: allCreatives.data.map(c => c.ID)
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                if (typeof(props.onChange) === "function") props.onChange();
                props.onClose();
            } else {
                if (res.data.data === "ALREADY_EXISTS") {
                    return setInfoP("Warmup for this ad account is already in progress.");
                };
                setInfoP("Error while creating warmup request");
            };
        }).catch(() => {
            setInfoP("Server timed out!");
        }).finally(() => {
            setSpinner(false);
        });
    };

    React.useEffect(() => {
        setAllPages();
        setSelectedPage();
        setSelectedCreatives([""]);

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/integrations/facebook/getAdAccountPages`,
            data: {
                ...selectedAccount
            },
            ...backendModule.axiosConfig
        }).then(res => setAllPages(res.data)).catch(() => setAllPages(backendModule.genericError));
    }, [selectedAccount]);

    return <div className="genericModal">
        <div className="genericModal__wrap">
            <div className="genericModal__wrap__head">
                <div className="genericModal__wrap__head__left">Add account</div>
                <div className="genericModal__wrap__head__right" style={{backgroundImage: `url("/images/icon_close.svg")`}} onClick={props.onClose}></div>
            </div>

            <div className="genericModal__wrap__input">
                <p>Select an ad account</p>
                <Dropdown
                    theme={themeSelector}
                    accent="#6C5DD3"
                    data={selectedTrackingProfilesSelector.filter(tp => tp.type === "facebook").map(tp => {
                        return {name: tp.name, value: {IntegrationID: tp.ID, AccountID: tp.AccountID, AccountName: tp.name}};
                    })}
                    onChange={e => setSelectedAccount(e?.value)}
                />
            </div>

            {selectedAccount && <div className="genericModal__wrap__input">
                <p>Select a page</p>
                {allPages ? <>
                    {allPages.status === "ok" ? <Dropdown
                        theme={themeSelector}
                        accent="#6C5DD3"
                        data={allPages.data.map(p => {
                            return {name: p.Name, value: p.ID};
                        })}
                        onChange={e => setSelectedPage(e?.value)}
                    /> : <p style={{color: themeSelector === "dark" ? basicStylesModule.errorColor : basicStylesModule.errorColorLight}}>Error while fetching pages!</p>}
                </> : <Spinner style={{width: "32px", height: "32px"}} color={themeSelector === "dark" ? "white" : "black"} />}
            </div>}

            {selectedAccount && <div className="genericModal__wrap__input">
                <p>Duration (in hours)</p>
                <StyledInput ref={durationRef} defaultValue="168" />
            </div>}

            {selectedAccount && <div className="genericModal__wrap__input">
                <p>Adset country</p>
                <Dropdown
                    theme={themeSelector}
                    accent="#6C5DD3"
                    data={countries.map(c => {
                        return {name: c.name, value: c.code}
                    })}
                    onChange={e => setSelectedCountry(e?.value)}
                />
            </div>}

            {selectedAccount && <div className="genericModal__wrap__input">
                <p>Creatives</p>    
                {selectedCreatives.map((c, cIdx) => {
                    return <StyledInput placeholder="fm-xxxx..." value={c} onChange={e => {
                        let val = e.target.value;
                        let finalCreatives = [...selectedCreatives];
                        finalCreatives[cIdx] = val;
                        finalCreatives = finalCreatives.filter(f => f);
                        if (finalCreatives.length === 0 || finalCreatives[finalCreatives.length-1]) finalCreatives.push("");
                        setSelectedCreatives(finalCreatives);
                    }} />
                })}
            </div>}

            {selectedAccount && <div className="genericModal__wrap__input">
                <p>AdsPower ID (optional)</p>
                <StyledInput ref={adsPowerIDRef} />
            </div>}

            <div className="genericModal__wrap__buttons">
                <div className="genericModal__wrap__buttons__btn genericModal__wrap__buttons__btn--secondary" onClick={props.onClose}>Cancel</div>
                {selectedPage && <div className="genericModal__wrap__buttons__btn" onClick={addAccount}>
                    {spinner ? <Spinner style={{width: "16px", height: "16px"}} color={themeSelector === "dark" ? "white" : "black"} /> : "Add"}
                </div>}
            </div>

            {infoP && <p className="genericModal__wrap__infoP" style={{opacity: 1}}>{infoP}</p>}
        </div>
    </div>
};

const AdminAutomations_FbWarmup_history = props => {
    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");
    const wrapRef = React.useRef();

    const onClose = () => {
        if (wrapRef.current) {
            wrapRef.current.animate([
                { right: getComputedStyle(wrapRef.current).right },
                { right: "-100%" }
            ], {
                duration: 300,
                iterations: 1,
                fill: "both",
                easing: "ease"
            });
        };

        props.onClose();
    };

    React.useEffect(() => {
        if (!wrapRef.current) return;

        wrapRef.current.animate([
            { right: getComputedStyle(wrapRef.current).right },
            { right: 0 }
        ], {
            duration: 300,
            iterations: 1,
            fill: "both",
            easing: "ease"
        });
    }, [wrapRef.current]);

    return <div className="route__automations__fbWarmup__history" onClick={() => onClose()}>
        <div className="route__automations__fbWarmup__history__wrap" ref={wrapRef} onClick={e => e?.stopPropagation()}>
            <div className="route__automations__fbWarmup__history__wrap__top">
                <div className="route__automations__fbWarmup__history__wrap__top__left">Warmup history</div>
                <div className="route__automations__fbWarmup__history__wrap__top__right"><img src="/images/icon_close.svg" onClick={() => onClose()} /></div>
            </div>

            <div className="route__automations__fbWarmup__history__wrap__content">
                <FilteredCustomTable
                    theme={themeSelector}
                    accent="#6C5DD3"
                    noTimeout={true}
                    canAnimate={false}
                    data={props.history.sort((a, b) => a > b ? -1 : 1).map((h, hIdx) => {
                        return [
                            {keyID: `${h}-${hIdx}`, type: "text", text: moment(h.Date).toDate().toLocaleString()},
                            {keyID: `${h}-${hIdx}`, type: "text", text: h.Value}
                        ]
                    })}
                    headers={["Date", "Content"]}
                    customColumns={["max-content", "max-content"]}
                    style={{columnGap: "40px"}}
                />
            </div>
        </div>
    </div>
};

export default AdminAutomations_FbWarmup;