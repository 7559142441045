import React from "react";
import "./index.scss";

import axios from "axios";
import { useSelector } from "react-redux";
import * as backendModule from "../../../modules/backendModule";
import * as basicStylesModule from "../../../modules/basicStylesModule";
import useDefer from "../../../modules/hooks/useDefer";

import { FilteredCustomTable } from "../../customComponents/Table";
import AdvancedDropdown from "../../../components/customComponents/AdvancedDropdown";

const breakdownMappings = {
    "City": "Date_LocationData-City",
    "Device type": "Date_DeviceData-Type",
    "Device brand": "Date_DeviceData-Brand"
};
const CampaignBreakdownStats = (props) => {
    const [selectedBreakdown, setSelectedBreakdown] = React.useState(breakdownMappings["City"]);
    const [data, setData] = React.useState();
    const [orders, setOrders] = React.useState();
    const curDefer = useDefer();

    const timestampRef = React.useRef();
    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");
    const currencySignSelector = useSelector(state => state?.types?.currencySign ?? "?");

    const performSort = (data, headers) => {
        let finalOrd = orders;

        if (!finalOrd) finalOrd = {name: "Conversions", order: "desc"};
        if (!finalOrd?.name || !finalOrd?.order) finalOrd = {name: "Leads", order: "desc"};
        
        headers = headers ? headers : ["-", "Visits", "Leads", "CLR", "CLs", "CARC", "PPL", "CPL", "CPAL", "CR", "CTR", "CPC", "ROI", "ROAS", "Revenue", "Spent", "ADP", "Profit"];
        let curHeaderIndex = ["Date", "Offer", "User"].includes(finalOrd.name) ? 0 : headers.indexOf(finalOrd.name);
        if (curHeaderIndex < 0) return data;

        const checkExists = item => item !== null && item !== undefined;
        const removeExtra = item => {
            let final = String(item).replace(/x/gmis, "").replace(/,/gmis, "").split(" ")[0];
            return Number(final);
        };
        return data.sort((a, b) => {
            if (
                (
                    !checkExists(a.columns[curHeaderIndex]?.text) ||
                    !checkExists(b.columns[curHeaderIndex]?.text)
                ) &&
                (
                    !checkExists(a.columns[curHeaderIndex]?.group?.[0]?.text) ||
                    !checkExists(b.columns[curHeaderIndex]?.group?.[0]?.text)
                )
            ) return -1;

            let a1 = null;
            let b1 = null;

            if (Array.isArray(a.columns[curHeaderIndex]?.group)) {
                a1 = removeExtra(a.columns[curHeaderIndex]?.group?.[0]?.text);
            } else {
                a1 = removeExtra(a.columns[curHeaderIndex].text);
            };
            if (Array.isArray(b.columns[curHeaderIndex]?.group)) {
                b1 = removeExtra(b.columns[curHeaderIndex]?.group?.[0]?.text);
            } else {
                b1 = removeExtra(b.columns[curHeaderIndex].text);
            };

            if (finalOrd.order === "asc") {
                if (+a1 > +b1) return 1; else return -1;
            } else {
                if (+b1 > +a1) return 1; else return -1;
            };
        });
    };

    const getData = async ts => {
        setData();

        if (timestampRef.current !== ts) return;

        let trackFilters = [];
        if (Array.isArray(props.filters)) trackFilters.push(...props.filters);

        let tmpSpent = await axios({
            method: "POST",
            url: `${backendModule.backendURL}/campaigns/getAllCampaigns`,
            data: {
                IntegrationID: props.integrationID,
                allUsers: true,
                trackFilters,
                filters: [
                    {name: "ID", op: "eq", value: props.ID}
                ],
                TableHeaders: [
                    "Spent",
                    "Spent_IN"
                ]
            },
            ...backendModule.axiosConfig
        }).then(res => res.data).catch(() => backendModule.genericError);

        if (tmpSpent.status !== "ok") return setData(tmpSpent);
        if (tmpSpent.data.length !== 1) return setData(backendModule.genericError);
        
        let finalSpent = tmpSpent.data[0]?.TableData?.["Spent_IN"] || tmpSpent.data[0]?.TableData?.["Spent"] || 0;

        let finalData = {};
        let trackData = await axios({
            method: "POST",
            url: `${backendModule.backendURL}/campaigns/getTrackingStats`,
            data: {
                allUsers: true,
                CampaignIDs: [props.ID],
                filters: props.filters ?? [],
                trackGroupByDate: true,
                utcOffset: (new Date()).getTimezoneOffset(),
                skipIntegrationDataPull: true,
                _o_useDateHints: true,
                TableHeaders: [
                    selectedBreakdown,
                    "Visits",
                    "Conversions",
                    "CR",
                    "Processed",
                    "Approved",
                    "AR",
                    "ADP",
                    "Revenue"
                ]
            },
            ...backendModule.axiosConfig
        }).then(res => res.data).catch(() => backendModule.genericError);
        if (trackData.status !== "ok") return setData(trackData);

        let uniqueData = [];
        let totalVisits = 0;
        for (let item of (trackData.data.TableData["Visits"] || [])) {
            totalVisits += item.Value;
            if (!uniqueData.includes(item[selectedBreakdown])) uniqueData.push(item[selectedBreakdown]);
        };

        for (let item of uniqueData) {
            finalData[item] = {};
            for (let trackKey of Object.keys(trackData.data.TableData)) {
                finalData[item][trackKey] = 0;
                if (Array.isArray(trackData.data.TableData[trackKey])) {
                    for (let td of trackData.data.TableData[trackKey]) {
                        if (td[selectedBreakdown] === item) {
                            if (!finalData[item][trackKey]) finalData[item][trackKey] = 0;
                            if (!isNaN(td.Value)) finalData[item][trackKey] += td.Value;
                        };
                    }
                };
            };
        };

        for (let key of Object.keys(finalData)) {
            finalData[key].Spent = finalSpent / (totalVisits || 0) * finalData[key].Visits;
            if (isNaN(finalData[key].Spent) || !Number.isFinite(finalData[key].Spent)) finalData[key].Spent = 0;

            finalData[key].CPA = finalData[key].Spent / finalData[key].Conversions;
            finalData[key].CPAO = finalData[key].Spent / finalData[key].Approved;
            finalData[key].ROAS = finalData[key].Revenue / finalData[key].Spent;

            for (let fk of ["CPA", "CPAO", "ROAS"]) {
                if (isNaN(finalData[key][fk]) || !Number.isFinite(finalData[key][fk])) finalData[key][fk] = 0;
            };
        };

        return setData({status: "ok", data: finalData});
    };

    const prepareTableData = (data, column) => {
        switch (column) {
            case "Revenue":
            case "Spent":
            case "Profit":
            case "EPV":
            case "CPA":
            case "CPAO":
            case "CPC":
            case "CostPerAnyLead":
            case "CostPerLead":
            case "ADP":
            case "LTV":
                let tmpRevenue = Number(data);
                if (isNaN(tmpRevenue)) return "-";
                return `${Number(tmpRevenue.toFixed(2)).toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })} ${currencySignSelector}`;
            case "CR":
            case "AR":
            case "CARC":
            case "CUR":
            case "CRR":
            case "ROI":
            case "CTR":
            case "AbandonedR":
                let tmpCR = Number(data);
                if (isNaN(tmpCR)) return "-";
                return `${Number(tmpCR.toFixed(2)).toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })} %`;
            case "ROAS":
                let tmpROAS = Number(data);
                if (isNaN(tmpROAS)) return "-";
                return `${Number(tmpROAS.toFixed(2)).toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}x`;
            default:
                let tmp = Number(data);
                if (isNaN(tmp)) return data;
                return tmp.toLocaleString("en-US");
        };
    };

    React.useEffect(() => {
        let ts = Date.now();
        timestampRef.current = ts;
        curDefer(() => getData(ts), 1000);
    }, [props.filters, props.ID, props.integration, props.integrationID, selectedBreakdown]);

    return <div className="modals__campaignBreakdownStats__wrap__content__left">
        <AdvancedDropdown
            headline="Breakdown column"
            style={{marginBottom: "20px", width: "max-content"}}
            data={Object.keys(breakdownMappings).map(key => {
                return {key: key, name: key, value: breakdownMappings[key]}
            })}
            onChange={e => e?.value && selectedBreakdown !== e?.value && setSelectedBreakdown(e.value)}
            selected={(()=>{
                if (!selectedBreakdown) return null;
                let allKeys = Object.keys(breakdownMappings);
                return allKeys.indexOf(allKeys.find(k => breakdownMappings[k] === selectedBreakdown));
            })()}
        />
        <FilteredCustomTable
            theme={themeSelector}
            accent="#6C5DD3"
            headers={[Object.keys(breakdownMappings).find(key => breakdownMappings[key] === selectedBreakdown), "Visits", "Conversions", "CR", "Processed", "Approved", "AR", "Spent", "CPA", "CPAO", "ADP", "ROAS"]}
            customColumns={(new Array(12)).fill("max-content")}
            style={{columnGap: "40px"}}
            headerStyle={{position: "sticky", top: 0}}
            orderCB={setOrders}
            data={(()=>{
                if (!data) return [{columns: [{keyID: "noData-spinner", type: "spinner", color: themeSelector === "dark" ? "white" : "black"}]}];
                if (data.status !== "ok") return [{columns: [{keyID: "noData-error", type: "custom", data: "Error while fetching data", style: {color: themeSelector === "dark" ? basicStylesModule.errorColor : basicStylesModule.errorColorLight}}]}];

                let out = [];
                for (let key of Object.keys(data.data)) {
                    out.push({
                        columns: [
                            {keyID: key, type: "text", text: key || <span style={{color: "gray"}}>(Unknown)</span>},
                            {keyID: key, type: "text", text: prepareTableData(data.data[key].Visits, "Conversions")},
                            {keyID: key, type: "text", text: prepareTableData(data.data[key].Conversions, "Conversions")},
                            {keyID: key, type: "text", text: prepareTableData(data.data[key].CR, "CR")},
                            {keyID: key, type: "text", text: prepareTableData(data.data[key].Processed, "Conversions")},
                            {keyID: key, type: "text", text: prepareTableData(data.data[key].Approved, "Conversions")},
                            {keyID: key, type: "text", text: prepareTableData(data.data[key].AR, "AR")},
                            {keyID: key, type: "text", text: prepareTableData(data.data[key].Spent, "Spent")},
                            {keyID: key, type: "text", text: prepareTableData(data.data[key].CPA, "CPA")},
                            {keyID: key, type: "text", text: prepareTableData(data.data[key].CPAO, "CPAO")},
                            {keyID: key, type: "text", text: prepareTableData(data.data[key].ADP, "ADP")},
                            {keyID: key, type: "text", text: prepareTableData(data.data[key].ROAS, "ROAS")},
                        ]
                    })
                };

                if (out.length > 0) out = performSort(out, [Object.keys(breakdownMappings).find(key => breakdownMappings[key] === selectedBreakdown), "Visits", "Conversions", "CR", "Processed", "Approved", "AR", "Spent", "CPA", "CPAO", "ADP", "ROAS"]);
                if (out.length === 0) out.push({columns: [{keyID: "noData-noData", type: "custom", data: "Nothing to show..."}]});
                return out;
            })()}
        />
    </div>
};

export default CampaignBreakdownStats;