import React from "react";
import "./index.scss";

import axios from "axios";
import { useSelector } from "react-redux";

import * as backendModule from "../../../../../modules/backendModule";
import * as basicStylesModule from "../../../../../modules/basicStylesModule";
import { countries } from "../../../../../modules/countryModule";
import { animateBox } from "../../../../../modules/componentAnimation";

import { FilteredCustomTable } from "../../../../../components/customComponents/Table";
import StyledButton from "../../../../../components/styledComponents/Button";
import StyledInput from "../../../../../components/styledComponents/Input";

import Dropdown from "../../../../../components/customComponents/Dropdown";
import Spinner from "../../../../../components/customComponents/Spinner";

import YesNoModal from "../../../../../components/modals/YesNoModal";

const AdminAutomations_FbAdGen = () => {
    const [accounts, setAccounts] = React.useState([]);
    const [pages, setPages] = React.useState({});
    const [pixels, setPixels] = React.useState({});
    const [selectedCountry, setSelectedCountry] = React.useState();

    const trackingProfilesSelector = useSelector(state => state?.trackingProfiles?.profiles ?? []);
    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");

    const adBudgetRef = React.useRef();
    const stCreativeIDRef = React.useRef();
    const creativeTitleRef = React.useRef();
    const creativeDescriptionRef = React.useRef();
    const postTextRef = React.useRef();
    const campaignIDRef = React.useRef();

    const addAccount = () => {
        setAccounts(a => [...a, {ID: null, AccountID: null, PageID: null, PixelID: null, _key: `${Math.floor(Math.random() * 999999)}-${Date.now()}`}]);
    };

    const removeAccount = idx => setAccounts(a => a.filter((_, aIdx) => aIdx !== idx));

    const getAccountPages = acc => {
        let [intID, accID] = acc.split("~");
        if (pages[acc] !== undefined) return;

        setPages(p => {
            return {...p, [acc]: null};
        });

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/integrations/facebook/getAdAccountPages`,
            data: {
                IntegrationID: intID,
                AccountID: accID
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                setPages(p => {
                    return {...p, [acc]: res.data.data};
                });
            } else {
                setPages(p => {
                    return {...p, [acc]: []};
                });    
            };
        }).catch(() => {
            setPages(p => {
                return {...p, [acc]: []};
            });
        });
    };

    const getAccountPixels = acc => {
        let [intID, accID] = acc.split("~");
        if (pages[acc] !== undefined) return;

        setPixels(p => {
            return {...p, [acc]: null};
        });

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/integrations/facebook/getAdAccountPixels`,
            data: {
                IntegrationID: intID,
                AccountID: accID
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                setPixels(p => {
                    return {...p, [acc]: res.data.data};
                });
            } else {
                setPixels(p => {
                    return {...p, [acc]: []};
                });    
            };
        }).catch(() => {
            setPixels(p => {
                return {...p, [acc]: []};
            });
        });
    };

    const getPageController = (acc, accIdx) => {
       if (!acc.ID || !acc.AccountID) return null;
       let finalID = `${acc.ID}~${acc.AccountID}`;
       if (pages[finalID] === undefined) return null;
       if (pages[finalID] === null) return <Spinner style={{width: "32px", height: "32px"}} color={themeSelector === "dark" ? "white" : "black"} />
       return <Dropdown
            accent="#6C5DD3"
            theme={themeSelector}
            style={{height: "43px"}}
            data={pages[finalID].map(p => {
                return {name: `${p.Name} (${p.ID})`, value: p.ID};
            })}
            onChange={e => {
                if (!e?.value) return;
                if (acc.PageID === e.value) return;

                setAccounts(accs => [
                    ...accs.filter((_, ai) => ai < accIdx),
                    {...acc, PageID: e.value},
                    ...accs.filter((_, ai) => ai > accIdx)
                ]);
            }}
            selected={(() => {
                if (!acc.PageID) return null;
                return pages[finalID].indexOf(pages[finalID].find(p => p.ID === acc.PageID));
            })()}
        />;
    };
    const getPixelController = (acc, accIdx) => {
       if (!acc.ID || !acc.AccountID) return null;
       let finalID = `${acc.ID}~${acc.AccountID}`;
       if (pixels[finalID] === undefined) return null;
       if (pixels[finalID] === null) return <Spinner style={{width: "32px", height: "32px"}} color={themeSelector === "dark" ? "white" : "black"} />
       return <Dropdown
            accent="#6C5DD3"
            theme={themeSelector}
            style={{height: "43px"}}
            data={pixels[finalID].map(p => {
                return {name: `${p.Name} (${p.ID})`, value: p.ID};
            })}
            onChange={e => {
                if (!e?.value) return;
                if (acc.PixelID === e.value) return;

                setAccounts(accs => [
                    ...accs.filter((_, ai) => ai < accIdx),
                    {...acc, PixelID: e.value},
                    ...accs.filter((_, ai) => ai > accIdx)
                ]);
            }}
            selected={(() => {
                if (!acc.PixelID) return null;
                return pixels[finalID].indexOf(pixels[finalID].find(p => p.ID === acc.PixelID));
            })()}
        />;
    };

    const performValidation = () => animateBox(<YesNoModal
        heading="Data validation"
        text="Starting..."
        buttonLeftHidden={true}
        isRightButtonNormal={true}
        buttonRightText="Ok"
        onOpenCallback={async args => {
            const err = e => {
                args.spinner(false);
                args.disabledAll(false);
                args.setText("Failed!");
                args.errorMessage(e);
            };
            args.spinner(true);
            args.disabledAll(true);
            args.setText("Checking ad accounts...");

            let finalAcc = accounts.filter(a => a);
            for (let acc of finalAcc) {
                if (!acc.ID) return err("One or more ad accounts are missing their ID (bad integration)");
                if (!acc.AccountID) return err("One or more ad accounts are missing their AccountID (bad integration)");
                if (!acc.PageID) return err("One or more ad accounts dont have a page selected");
                if (!acc.PixelID) return err("One or more ad accounts dont have a pixel selected");

                let tmpID = `${acc.ID}~${acc.AccountID}`;
                if (!pages[tmpID] || !pages[tmpID].find(p => p.ID === acc.PageID)) return err("One or more ad accounts have an invalid page!");
                if (!pixels[tmpID] || !pixels[tmpID].find(p => p.ID === acc.PixelID)) return err("One or more ad accounts have an invalid page!");

                if (finalAcc.filter(f => f.ID === acc.ID && f.AccountID === acc.IntegrationID).length > 1) return err("Duplicate ad accounts found");
            };

            args.setText("Checking creative...");
            if (!stCreativeIDRef.current.value) return err("Creative ID missing");
            let creativeFound = await axios({
                method: "POST",
                url: `${backendModule.backendURL}/creatives/getAllCreatives`,
                data: {
                    limit: 1,
                    filters: [
                        {name: "CustomID", op: "eq", value: stCreativeIDRef.current.value},
                        {name: "FileType", op: "eq", value: "image"}
                    ]
                },
                ...backendModule.axiosConfig
            }).then(res => {
                if (res.data.status === "ok" && res?.data?.data?.length > 0) return res.data.data[0];
                return false;
            }).catch(() => false);
            if (!creativeFound) return err(`Creative ${stCreativeIDRef.current.value} not found, or the provided creative is not an image (video is not supported).`);

            args.setText("Checking campaign...");
            if (!campaignIDRef.current.value) return err("Campaign ID cant be empty");
            let campaignFound = await axios({
                method: "POST",
                url: `${backendModule.backendURL}/campaigns/getAllCampaignsWithoutData`,
                data: {
                    limit: 1,
                    filters: [{and: [
                        {or: [
                            {name: "ID", op: "eq", value: campaignIDRef.current.value},
                            {name: "RollingID", op: "eq", value: campaignIDRef.current.value}
                        ]},
                        {name: "IntegrationType", op: "eq", value: 0}
                    ]}]
                },
                ...backendModule.axiosConfig
            }).then(res => {
                if (res.data.status === "ok" && res.data?.data?.length > 0) return res.data.data[0];
            }).catch(() => false);
            if (!campaignFound) return err("Campaign not found, or not a facebook campaign!");

            args.setText("Checking final data...");
            if (!selectedCountry) return err("Country not selected");
            if (isNaN(Number(adBudgetRef.current.value))) return err("Ado budget must be a number");
            if (Number(adBudgetRef.current.value) <= 0) return err("Ad budget must be greater than 0");
            if (!creativeTitleRef.current.value) return err("Creative headline cant be empty");
            if (!postTextRef.current.value) return err("Post text cant be empty");

            args.spinner(false);
            args.disabledAll(false);
            args.close();

            let finalData = {
                Accounts: finalAcc,
                CampaignID: campaignFound.ID,
                STCreativeID: creativeFound.ID,
                Country: selectedCountry,
                AdBudget: Number(adBudgetRef.current.value),
                CreativeTitle: creativeTitleRef.current.value,
                CreativeDescription: creativeDescriptionRef.current.value,
                PostText: postTextRef.current.value
            };

            animateBox(<YesNoModal
                heading="Are you sure?"
                text="This ad configuration will be created for all selected ad accounts. Double-check your inputs before you proceed."
                buttonLeftText="No"
                buttonRightText="Yes"
                isRightButtonNormal={true}
                buttonRightCallback={args => {
                    args.spinner(true);
                    args.disabledAll(true);

                    axios({
                        method: "POST",
                        url: `${backendModule.backendURL}/integrations/facebook/createAdsAutoFlow`,
                        data: finalData,
                        ...backendModule.axiosConfig
                    }).then(res => {
                        if (res.data.status === "ok") {
                            let finalList = [];
                            for (let item of res.data.data.completed) {
                                let foundAcc = trackingProfilesSelector.find(t => t.ID === item);
                                finalList.push(<span style={{color: themeSelector === "dark" ? basicStylesModule.successColor : basicStylesModule.successColorLight}}>{foundAcc ? foundAcc.name : item}</span>);
                            };
                            for (let item of res.data.data.failed) {
                                let foundAcc = trackingProfilesSelector.find(t => t.ID === item);
                                finalList.push(<span style={{color: themeSelector === "dark" ? basicStylesModule.errorColor : basicStylesModule.errorColorLight}}>{foundAcc ? foundAcc.name : item}</span>);
                            };
                            animateBox(<YesNoModal
                                heading="Success"
                                text={[
                                    `${finalAcc.length} ad accounts have been processed.`,
                                    <br />,
                                    ...finalList
                                ]}
                                buttonLeftHidden={true}
                                isRightButtonNormal={true}
                                buttonRightText="Ok"
                            />);
                        } else {
                            animateBox(<YesNoModal
                                heading="Error"
                                text={["Server returned an error", <br />, res.data.data]}
                                buttonLeftHidden={true}
                                isRightButtonNormal={true}
                                buttonRightText="Ok"
                            />);
                        };
                    }).catch(() => {
                        animateBox(<YesNoModal
                            heading="Error"
                            text="Server timed out"
                            buttonLeftHidden={true}
                            isRightButtonNormal={true}
                            buttonRightText="Ok"
                        />);
                    }).finally(() => {
                        args.spinner(false);
                        args.disabledAll(false);
                        args.close();
                    });
                }}
            />);
        }}
    />)

    React.useEffect(() => {
        for (let acc of accounts) {
            if (!acc.ID || !acc.AccountID) continue;
            let finalID = `${acc.ID}~${acc.AccountID}`;

            if (pages[finalID] === undefined) getAccountPages(finalID);
            if (pixels[finalID] === undefined) getAccountPixels(finalID);
        };
    }, [accounts]);

    return <div className="route__automations__fbAdGen">
        <h2 className="route__automations__fbAdGen__title">Facebook Ad generator</h2>

        <p>
            This tool will automatically clone a given campaign
            to all ad accounts provided, and create a leadgen ad with a specific budget, page and pixel.
            <br />
            <br />
            <b>NOTE: Only image creatives will work, video is not supported for now!</b>
            <br />
            <br />
        </p>

        <FilteredCustomTable
            accent="#6C5DD3"
            theme={themeSelector}
            headers={["Ad account", "Page", "Pixel", "Actions"]}
            customColumns={["300px", "200px", "200px", "max-content"]}
            style={{columnGap: "40px", height: "max-content", overflow: "visible", zIndex: 2}}
            data={(()=>{
                let out = [];

                let fbProfiles = trackingProfilesSelector.filter(t => t?.type === "facebook" && !t?.banned);

                for (let i=0; i<=accounts.length-1; i++) {
                    let item = accounts[i];

                    out.push([
                        {keyID: item._key, type: "custom", data: <Dropdown
                            accent="#6C5DD3"
                            theme={themeSelector}
                            style={{height: "43px"}}
                            data={fbProfiles.map(p => {
                                return {name: p.name, value: {ID: p.ID, AccountID: p.AccountID}};
                            })}
                            onChange={e => {
                                if (!e?.value) return;
                                setAccounts(acc => [
                                    ...acc.filter((_, accIdx) => accIdx < i),
                                    {...item, ...e.value},
                                    ...acc.filter((_, accIdx) => accIdx > i)
                                ]);
                            }}
                            selected={(()=>{
                                if (!item.ID || !item.AccountID) return null;
                                return fbProfiles.indexOf(fbProfiles.find(fb => fb.ID === item.ID && fb.AccountID === item.AccountID));
                            })()}
                        />},
                        {keyID: item._key, type: "custom", data: getPageController(item, i)},
                        {keyID: item._key, type: "custom", data: getPixelController(item, i)},
                        {keyID: item._key, type: "custom", data: <StyledButton isSecondary={true} onClick={() => removeAccount(i)}>Remove</StyledButton>}
                    ]);
                };

                if (out.length === 0) out.push([{keyID: "noData-noData", type: "text", text: "No accounts selected"}]);
                out.push([{keyID: "add-btn", type: "custom", data: <StyledButton onClick={addAccount}>Add account</StyledButton>}]);

                return out;
            })()}
            canAnimate={false}
            noTimeout={true}
        />

        <div className="route__automations__fbAdGen__configItems">
            <div>
                <p>Ad target country</p>
                <Dropdown
                    accent="#6C5DD3"
                    theme={themeSelector}
                    data={countries.map(c => {return {name: c.name, value: c.code}})}
                    style={{width: "100%"}}
                    dropdownStyle={{height: "300px"}}
                    onChange={e => (e?.value && e?.value !== selectedCountry) && setSelectedCountry(e.value)}
                    selected={(()=>{
                        if (!selectedCountry) return null;
                        return countries.indexOf(countries.find(c => c.code === selectedCountry));
                    })()}
                />
            </div>
            <div>
                <p>ST Campaign ID (will be cloned)</p>
                <StyledInput ref={campaignIDRef} placeholder="UUID or RollingID" />
            </div>
            <div>
                <p>ST Creative ID (vm-xxx, fm-xxx....)</p>
                <StyledInput ref={stCreativeIDRef} placeholder="fm-123123" />
            </div>
        </div>
        <div className="route__automations__fbAdGen__configItems">
            <div>
                <p>Ad daily budget (fb currency)</p>
                <StyledInput ref={adBudgetRef} defaultValue="10" />
            </div>
            <div>
                <p>Creative headline</p>
                <StyledInput ref={creativeTitleRef} placeholder="Title" />
            </div>
            <div>
                <p>Creative description</p>
                <StyledInput ref={creativeDescriptionRef} placeholder="Description (optional)" />
            </div>
        </div>
        
        <div className="route__automations__fbAdGen__text">
            <p>Post text</p>
            <textarea ref={postTextRef} placeholder="Text that will appear in post that is promoted by ad"></textarea>
        </div>

        <StyledButton style={{marginTop: "20px"}} onClick={performValidation}>Validate</StyledButton>
    </div>
};

export default AdminAutomations_FbAdGen;