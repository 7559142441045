import React from "react";

import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import moment from "moment";
import axios from "axios";
import * as backendModule from "../../../../modules/backendModule";
import * as basicStylesModule from "../../../../modules/basicStylesModule";
import * as siteFunctionsActions from "../../../../actions/siteFunctionsActions";
import { chartColorSets } from "../../../../modules/miscModule";
import { animateBox } from "../../../../modules/componentAnimation";

import { FilteredCustomTable } from "../../../../components/customComponents/Table";
import Spinner from "../../../../components/customComponents/Spinner";
import StyledButton from "../../../../components/styledComponents/Button";

import FilterByDate from "../../../../components/filters/FilterByDate";
import FilterByUserAccount from "../../../../components/filters/FilterByUserAccount";

import OfferSelectModal from "../../../../components/modals/OfferSelectModal";

const CustomReportView = props => {
    const [curReport, setCurReport] = React.useState();
    const [reportData, setReportData] = React.useState();
    const [order, setOrder] = React.useState();
    const [allUsers, setAllUsers] = React.useState();
    const [allOffers, setAllOffers] = React.useState();
    const [openedDimensions, setOpenedDimensions] = React.useState({});
    const [filterTimestamp, setFilterTimestamp] = React.useState(Date.now());
    const [filtersUpdated, setFiltersUpdated] = React.useState(false);
    const [filters_date, setFilters_date] = React.useState();
    const [filters_selectedUser, setFilters_selectedUser] = React.useState();
    const [filters_selectedOffer, setFilters_selectedOffer] = React.useState();

    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");
    const userInfoSelector = useSelector(state => state?.userData?.userData?.UserInfo ?? {});
    const selectedTrackingProfileSelector = useSelector(state => state?.trackingProfiles?.selectedProfile ?? null);
    const allTrackingProfilesSelector = useSelector(state => state?.trackingProfiles?.profiles ?? []);
    const reportDimensionsSelector = useSelector(state => state?.types?.customReports?.supportedDimensions ?? {});
    const curParams = useParams();
    const curDispatch = useDispatch();
    const timestampRef = React.useRef();

    const sortData = d => {
        if (!order) return d;
        let tmp = [...d];
        
        // TODO: sort by _dimensionName as well :)
        if (order.name.startsWith("Date_")) {
            return tmp.sort((a, b) => {
                if (a._dimensionName !== order.name) return 0;
                if (b._dimensionName !== order.name) return 0; 

                let a1 = a.Dimension;
                let b1 = b.Dimension;

                if (a1 > b1) return order.order === "asc" ? 1 : -1;
                return order.order === "asc" ? -1 : 1;
            }).map(t => {
                if (t.Next) t.Next = sortData(t.Next);
                return t;
            });
        };

        tmp = tmp.sort((a, b) => {
            let a1 = a.Data[order.name];
            a1 = a1.split(" ")[0];
            a1 = Number(a1);
            if (isNaN(a1)) return -1;
            let b1 = b.Data[order.name];
            b1 = b1.split(" ")[0];
            b1 = Number(b1);
            if (isNaN(b1)) return -1;

            if (a1 > b1) return order.order === "asc" ? 1 : -1;
            return order.order === "asc" ? -1 : 1;
        }).map(t => {
            if (t.Next) t.Next = sortData(t.Next);
            return t;
        });
        
        return tmp;
    };

    const drillDownData = (data, previousDimensions = [], depth = 0) => {
        let curColor = depth === 0 ? undefined : chartColorSets[depth-1];
        let out = [];

        for (let dimItem of data) {
            let row = [];
            let dKey = `${depth}-${previousDimensions.join("-")}-${dimItem._dimensionName}-${dimItem.Dimension}`;
            let oKey = order ? `${order.name}-${order.order}` : "no"
    
            if (previousDimensions) row.push(...previousDimensions.map(p => {return {keyID: `${depth}-${dKey}-${oKey}`, type: "text", text: ""}}));
            row.push({
                keyID: `${depth}-${dKey}-${oKey}`,
                type: "custom",
                data: <>
                    {dimItem?.Next && <StyledButton
                        isSecondary={openedDimensions[dKey]}
                        style={{height: "calc(100% - 6px)", marginRight: "10px", padding: "3px 3px", minWidth: "26px"}}
                        onClick={() => {
                            setOpenedDimensions(od => {
                                return {
                                    ...od,
                                    [dKey]: !od[dKey]
                                };
                            });
                        }}
                    >{openedDimensions[dKey] ? "-" : "+"}</StyledButton>}
                    {dimItem.Dimension}
                </>,
                style: {color: curColor}
            });
            while (row.length !== curReport.data.Dimensions.length) row.push({keyID: `${depth}-${dKey}-${oKey}`, type: "text", text: "(all)", style: {color: "gray"}});

            for (let m of curReport.data.Metrics) {
                if (!m.Visible) continue;
                row.push({
                    keyID: `${depth}-${dKey}-${oKey}`,
                    type: "text",
                    text: dimItem.Data[m.Name] || 0,
                    style: {color: curColor, ...(dimItem?.Style?.Column[m.Name] || {})}
                });
            };

            out.push({columns: row, style: (dimItem?.Style?.Row || {})});
            if (openedDimensions[dKey] && dimItem?.Next) {
                out.push(...drillDownData(dimItem.Next, [...previousDimensions, `${dimItem._dimensionName}-${dimItem.Dimension}`], depth + 1));
            };
        };

        return out;
    };

    const parseDimensions = dims => {
        let out = {};
        for (let d of dims) {
            let tmp = String(d);
            if (reportDimensionsSelector[tmp]) {
                tmp = reportDimensionsSelector[tmp];
            } else {
                tmp = tmp.replace("Date_", "");
                tmp = `${tmp.charAt(0).toUpperCase()}${tmp.substring(1, tmp.length)}`;
            };
            out[d] = <span style={{color: "rgb(108, 93, 211)"}}>[{tmp}]</span>;
        };
        return out;
    };

    const getReport = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/reports/custom/getAll`,
            data: {
                limit: 1,
                offset: 0,
                filters: [
                    {name: "ID", op: "eq", value: curParams.id}
                ],
                extended: true
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                if (res.data.data.length === 1) {
                    setCurReport({status: "ok", data: res.data.data[0]});
                } else {
                    setCurReport({status: "error", data: "NOT_FOUND"});
                };
            } else {
                setCurReport(res.data);
            };
        }).catch(() => setCurReport(backendModule.genericError));
    };

    const getReportData = (ts) => {
        if (timestampRef.current !== ts) return;

        const campaignFilters = [];
        const trackingFilters = [];

        if (filters_date) {
            campaignFilters.push({name: "lastTrackedAt", op: "pdgeq", value: filters_date.start.toDate().getTime()});
            trackingFilters.push({name: "createdAt", op: "pdgeq", value: filters_date.start.toDate().getTime()});
            trackingFilters.push({name: "createdAt", op: "pdleq", value: filters_date.end.toDate().getTime()});
        };

        if (filters_selectedUser) {
            // 1) UserID
            if (filters_selectedUser.UserID) {
                if (filters_selectedUser.UserID === "all-team-users") {
                    let tIDs = allUsers.data.map(u => u.ID);
                    if (tIDs.length === 0) return setReportData({status: "ok", data: []});
                    campaignFilters.push({name: "CreatedBy", op: "in", value: tIDs});
                } else if (filters_selectedUser.UserID.startsWith("all-team--")) {
                    let tName = filters_selectedUser.UserID.replace("all-team--", "");
                    let tIDs = allUsers.data.filter(u => u.Team == tName).map(u => u.ID);
                    if (tIDs.length === 0) return setReportData({status: "ok", data: []});
                    campaignFilters.push({name: "CreatedBy", op: "in", value: tIDs});
                } else {
                    campaignFilters.push({name: "CreatedBy", op: "eq", value: filters_selectedUser.UserID});
                };
            } else {
                // current user
                campaignFilters.push({name: "CreatedBy", op: "eq", value: userInfoSelector.ID});
            };

            // 2) IntegrationID
            if (filters_selectedUser.IntegrationID) {
                if (filters_selectedUser.IntegrationID === "usr-all") {
                    let curUser = allUsers.data.find(u => u.ID === filters_selectedUser.UserID);
                    let tmp = [];
                    for (let s of (curUser?._profiles ?? [])) if (!tmp.includes(s.ID)) tmp.push(s.ID);
                    if (tmp.length === 0) {
                        setReportData({status: "ok", data: []});
                        return;
                    };
                    campaignFilters.push({name: "IntegrationID", op: "in", value: tmp});
                } else if (filters_selectedUser.IntegrationID === "all-all") {
                    // skip it
                } else if (filters_selectedUser.IntegrationID.endsWith("-all")) {
                    campaignFilters.push({name: "IntegrationID", op: "startsWith", value: filters_selectedUser.IntegrationID.substring(0, 2)})
                } else {
                    campaignFilters.push({name: "IntegrationID", op: "eq", value: filters_selectedUser.IntegrationID});
                };
            } else {
                // current user
                campaignFilters.push({name: "IntegrationID", op: "eq", value: selectedTrackingProfileSelector});
            };
        } else {
            // not yet loaded
            campaignFilters.push({name: "CreatedBy", op: "eq", value: userInfoSelector.ID});
            campaignFilters.push({name: "IntegrationID", op: "eq", value: selectedTrackingProfileSelector});
        };

        if (filters_selectedOffer) {
            if (filters_selectedOffer?.length) {
                let tIDs = [...new Set(filters_selectedOffer.map(t => t.Sites).flat(3))];
                if (tIDs.length === 0) {
                    setReportData({status: "ok", data: []});
                    return;
                };
                campaignFilters.push({or: [
                    {name: "PreLandingSiteID", op: "in", value: tIDs},
                    {name: "LandingSiteID", op: "in", value: tIDs}
                ]});
            };
        };

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/reports/custom/process`,
            data: {
                ID: curParams.id,
                utcOffset: (new Date()).getTimezoneOffset(),
                CampaignFilters: campaignFilters,
                TrackingFilters: trackingFilters
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (timestampRef.current !== ts) return;
            setReportData(res.data);
        }).catch(() => {
            if (timestampRef.current !== ts) return;
            setReportData(backendModule.genericError);
        });
    };

    const getAllUsers = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/users/getAllUsersTeamLead`,
            data: {
                excludeSelf: true,
                includeProfiles: true,
                includeDeleted: true,
                allUsers: true,
                checkActive: false,
                filters: [
                    { name: "Flags:userVisibleInReports", op: "eq", value: true }
                ],
                useCache: true
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                return setAllUsers({
                    status: "ok", data: [{
                        ...userInfoSelector,
                        _profiles: allTrackingProfilesSelector
                    }, ...res.data.data]
                });
            } else {
                setAllUsers(res.data);
            };
        }).catch(() => {
            setAllUsers(backendModule.genericError);
        });
    };
    const getAllOffers = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/sites/getAllSites`,
            data: {
                limit: null,
                offset: 0,
                getOfferInfo: true,
                extended: false
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                let out = {};

                for (let item of res.data.data) {
                    if (!item?._Offer) continue;
                    if (!item._Offer?.ID) continue;

                    if (!out[item._Offer.ID]) {
                        out[item._Offer.ID] = {
                            Name: item._Offer.OfferName,
                            Price: item._Offer.OfferPrice,
                            OfferBrand: item._Offer.OfferBrand,
                            OfferType: item._Offer.OfferType,
                            OfferNiche: item._Offer.OfferNiche,
                            Country: item._Offer.Country,
                            ResponsiblePerson: item._Offer.ResponsiblePerson,
                            Sites: [item.ID]
                        };
                    } else {
                        out[item._Offer.ID].Sites.push(item.ID);
                    };
                };

                setAllOffers({
                    status: "ok", data: Object.keys(out).map(id => {
                        return {
                            ID: id,
                            ...out[id]
                        };
                    })
                });
            } else {
                setAllOffers(backendModule.genericError);
            };
        }).catch(() => {
            setAllOffers(backendModule.genericError);
        });
    };
    const getTeams = () => {
        if (!allUsers) return [];
        if (allUsers?.status !== "ok") return [];

        let finalUsers = [
            {
                ID: userInfoSelector.ID,
                Username: userInfoSelector?.Username,
                Team: userInfoSelector?.Team ?? null,
                _profiles: allTrackingProfilesSelector
            },
            ...(allUsers?.data ? allUsers.data : [])
        ];

        let out = [];
        for (let item of finalUsers) {
            if (!item.Team) continue;
            if (!out.includes(item.Team)) out.push(item.Team);
        };
        return out;
    };

    React.useEffect(() => {
        if (!filters_date) return;

        setOpenedDimensions({});
        setReportData();

        let handler = () => {
            let ts = Date.now();
            timestampRef.current = ts;
            getReportData(ts);
        };

        setTimeout(() => {
            handler();
        }, 500);

        curDispatch(siteFunctionsActions.addHeaderRefreshAction(handler));
        return () => curDispatch(siteFunctionsActions.removeHeaderRefreshAction(handler));
    }, [filterTimestamp]);

    React.useEffect(() => {
        if (!curReport) return;
        if (curReport.status === "error") {
            setAllUsers({status: "ok", data: []});
            return;
        };

        if (!curReport?.data?.predefinedFilters?.allUsers || (!userInfoSelector?.Flags?.isAdmin && !userInfoSelector?.Flags?.isTeamLeader && !userInfoSelector?.Flags?.isManager)) {
            setAllUsers({ status: "ok", data: [{
                ...userInfoSelector,
                _profiles: allTrackingProfilesSelector
            }]});
            return;
        } else {
            getAllUsers();
        };
    }, [curReport]);

    React.useEffect(() => {
        getReport();
        getAllOffers();
    }, []);

    if (!curReport) return <div className="route__reports__custom__view">
        <h3>Loading report...</h3>
        <Spinner style={{width: "32px", height: "32px"}} color={themeSelector === "dark" ? "white" : "black"} />
    </div>
    if (curReport.status !== "ok") return <div className="route__reports__custom__view"><h3 style={{color: themeSelector === "dark" ? basicStylesModule.errorColor : basicStylesModule.errorColorLight}}>Error while fetching report structure!</h3></div>

    return <div className="route__reports__custom__view">
        <h3><span style={{color: "rgb(108, 93, 211)"}}>[Custom report]</span> {curReport.data.Name}</h3>

        <div className="route__reports__custom__view__filters">
            <FilterByUserAccount
                headline="Select user / account"
                data={(() => {
                    if (!allUsers) return null;
                    if (allUsers.status === "error") return [];
                    return [
                        ...[{
                            ID: userInfoSelector.ID,
                            Username: "Your profiles",
                            Team: userInfoSelector?.Team ?? null,
                            _profiles: allTrackingProfilesSelector
                        }].map(ud => {
                            return {
                                ...ud,
                                _profiles: [
                                    { ID: "usr-all", type: "all", name: "All traffic sources", generic: true },
                                    ...(ud._profiles.sort((a, b) => {
                                        return (a.active !== b.active && a.active) ? (a.active ? -1 : 1) : 0
                                    }).find(p => p?.ID?.startsWith?.("fb-")) ? [
                                        { ID: "fb-all", type: "facebook", name: "All accounts" },
                                    ] : []),
                                    ...(ud._profiles.sort((a, b) => {
                                        return (a.active !== b.active && a.active) ? (a.active ? -1 : 1) : 0
                                    }).find(p => p?.ID?.startsWith?.("mg-")) ? [
                                        { ID: "mg-all", type: "mgid", name: "All accounts" }
                                    ] : []),
                                    ...(ud._profiles.sort((a, b) => {
                                        return (a.active !== b.active && a.active) ? (a.active ? -1 : 1) : 0
                                    }).find(p => p?.ID?.startsWith?.("tt-")) ? [
                                        { ID: "tt-all", type: "tiktok", name: "All accounts" }
                                    ] : []),
                                    ...(ud._profiles.sort((a, b) => {
                                        return (a.active !== b.active && a.active) ? (a.active ? -1 : 1) : 0
                                    }).find(p => p?.ID?.startsWith?.("ak-")) ? [
                                        { ID: "ak-all", type: "adskeeper", name: "All accounts" }
                                    ] : []),
                                    ...(ud._profiles.sort((a, b) => {
                                        return (a.active !== b.active && a.active) ? (a.active ? -1 : 1) : 0
                                    }).find(p => p?.ID?.startsWith?.("gg-")) ? [
                                        { ID: "gg-all", type: "google", name: "All accounts" }
                                    ] : []),
                                    ...ud._profiles
                                ]
                            };
                        }),
                        {
                            ID: "all-team-users",
                            Username: "All teams",
                            _profiles: [
                                { ID: "all-all", type: "all", name: "All traffic sources", generic: true },
                                { ID: "-1", type: "scale-track", name: "Generic tracking" },
                                { ID: "-2", type: "scale-track-sms", name: "SMS marketing" },
                                { ID: "-3", type: "scale-track-social", name: "Social campaigns" },
                                { ID: "fb-all", type: "facebook", name: "Facebook", generic: true },
                                { ID: "tt-all", type: "tiktok", name: "Tiktok", generic: true },
                                { ID: "mg-all", type: "mgid", name: "Mgid", generic: true },
                                { ID: "md-all", type: "midas", name: "Midas Network", generic: true },
                                { ID: "an-all", type: "adnow", name: "AdNow One", generic: true },
                                { ID: "ts-all", type: "trafficstars", name: "Traffic stars", generic: true },
                                { ID: "ak-all", type: "adskeeper", name: "Ads keeper", generic: true },
                                { ID: "tf-all", type: "trafficfactory", name: "Traffic factory", generic: true },
                                { ID: "gg-all", type: "google", name: "Google", generic: true },
                            ]
                        },
                        ...(getTeams().map(t => {
                            return {
                                ID: `all-team--${t}`,
                                style: {color: "orange"},
                                Username: `[Team] ${t}`,
                                _profiles: [
                                    { ID: "all-all", type: "all", name: "All traffic sources", generic: true },
                                    { ID: "-1", type: "scale-track", name: "Generic tracking" },
                                    { ID: "-2", type: "scale-track-sms", name: "SMS marketing" },
                                    { ID: "-3", type: "scale-track-social", name: "Social campaigns" },
                                    { ID: "fb-all", type: "facebook", name: "Facebook", generic: true },
                                    { ID: "tt-all", type: "tiktok", name: "Tiktok", generic: true },
                                    { ID: "mg-all", type: "mgid", name: "Mgid", generic: true },
                                    { ID: "md-all", type: "midas", name: "Midas Network", generic: true },
                                    { ID: "an-all", type: "adnow", name: "AdNow One", generic: true },
                                    { ID: "ts-all", type: "trafficstars", name: "Traffic stars", generic: true },
                                    { ID: "ak-all", type: "adskeeper", name: "Ads keeper", generic: true },
                                    { ID: "tf-all", type: "trafficfactory", name: "Traffic factory", generic: true },
                                    { ID: "gg-all", type: "google", name: "Google", generic: true },
                                ]
                            }
                        })),
                        ...([
                            ...allUsers.data
                        ].map(ud => {
                            return {
                                ...ud,
                                _profiles: [
                                    { ID: "usr-all", type: "all", name: "All traffic sources", generic: true },
                                    ...(ud._profiles.sort((a, b) => {
                                        return (a.active !== b.active && a.active) ? (a.active ? -1 : 1) : 0
                                    }).find(p => p?.ID?.startsWith?.("fb-")) ? [
                                        { ID: "fb-all", type: "facebook", name: "All accounts" },
                                    ] : []),
                                    ...(ud._profiles.sort((a, b) => {
                                        return (a.active !== b.active && a.active) ? (a.active ? -1 : 1) : 0
                                    }).find(p => p?.ID?.startsWith?.("mg-")) ? [
                                        { ID: "mg-all", type: "mgid", name: "All accounts" }
                                    ] : []),
                                    ...(ud._profiles.sort((a, b) => {
                                        return (a.active !== b.active && a.active) ? (a.active ? -1 : 1) : 0
                                    }).find(p => p?.ID?.startsWith?.("tt-")) ? [
                                        { ID: "tt-all", type: "tiktok", name: "All accounts" }
                                    ] : []),
                                    ...(ud._profiles.sort((a, b) => {
                                        return (a.active !== b.active && a.active) ? (a.active ? -1 : 1) : 0
                                    }).find(p => p?.ID?.startsWith?.("ak-")) ? [
                                        { ID: "ak-all", type: "adskeeper", name: "All accounts" }
                                    ] : []),
                                    ...(ud._profiles.sort((a, b) => {
                                        return (a.active !== b.active && a.active) ? (a.active ? -1 : 1) : 0
                                    }).find(p => p?.ID?.startsWith?.("gg-")) ? [
                                        { ID: "gg-all", type: "google", name: "All accounts" }
                                    ] : []),
                                    ...ud._profiles
                                ]
                            };
                        }))
                    ];
                })()}
                onChange={e => {
                    setFilters_selectedUser(e);
                    setFiltersUpdated(true);
                }}
                defaultUser={null}
            />
            <FilterByDate
                disable24h={true}
                disableAll={true}
                defaultValue={"yesterday"}
                onChange={(e) => {
                    if (!filters_date) {
                        setFilters_date(e);
                        setFilterTimestamp(Date.now() + 1);
                    } else {
                        setFilters_date(e);
                        setFiltersUpdated(true);
                    };
                }}
            />
            {allOffers?.status === "ok" && <div className="route__reports__custom__view__filters__btn" onClick={e => animateBox(e, <OfferSelectModal
                onChange={e => {
                    setFilters_selectedOffer(e);
                    setFiltersUpdated(true);
                }}
                defaultValue={filters_selectedOffer}
                data={allOffers?.data ?? []}
            />)}>Select offers ({filters_selectedOffer?.length ?? 0} / {allOffers?.data?.length ?? 0})</div>}

            {filtersUpdated && <StyledButton style={{height: "54px"}} onClick={() => {
                setFiltersUpdated(false);
                setFilterTimestamp(Date.now());
            }}>Apply</StyledButton>}
        </div>

        <FilteredCustomTable
            theme={themeSelector}
            accent="#6C5DD3"
            headers={[
                ...curReport.data.Dimensions,
                ...curReport.data.Metrics.filter(m => m.Visible).map(m => m.Name)
            ]}
            customHeaders={{
                ...parseDimensions(curReport.data.Dimensions)
            }}
            customColumns={(new Array(curReport.data.Dimensions.length + curReport.data.Metrics.length)).fill("max-content")}
            style={{columnGap: "40px"}}
            canAnimate={false}
            noTimeout={true}
            data={(() => {
                if (!reportData) return [{columns: [{keyID: "noData-spinner", type: "spinner", color: themeSelector === "dark" ? "white" : "black"}]}];
                if (reportData.status !== "ok") return [{columns: [{keyID: "noData-error", type: "text", text: "Error while fetching report data", style: {color: themeSelector === "dark" ? basicStylesModule.errorColor : basicStylesModule.errorColorLight}}]}];

                if (reportData.data.length > 0) return drillDownData(sortData(reportData.data));
                return [{columns: [{keyID: "noData-noData", type: "text", text: "Nothing to show..."}]}];
            })()}
            orderCB={o => setOrder(o)}
        />
    </div>
};

export default CustomReportView;