import React from "react";
import "./index.scss";

import axios from "axios";
import { useSelector } from "react-redux";
import * as backendModule from "../../../modules/backendModule";
import * as basicStylesModule from "../../../modules/basicStylesModule";
import useDefer from "../../../modules/hooks/useDefer";
import { animateBox } from "../../../modules/componentAnimation";

import { FilteredCustomTable } from "../../customComponents/Table";
import { OrdersModal_asModal } from "../OrdersModal/modalInline";

const CampaignCRMAgentStatsModal = (props) => {
    const [data, setData] = React.useState();
    const [orders, setOrders] = React.useState();
    const curDefer = useDefer();

    const timestampRef = React.useRef();
    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");
    const currencySignSelector = useSelector(state => state?.types?.currencySign ?? "?");

    const performSort = (data, headers) => {
        let finalOrd = orders;

        if (!finalOrd) finalOrd = {name: "Leads", order: "desc"};
        if (!finalOrd?.name || !finalOrd?.order) finalOrd = {name: "Leads", order: "desc"};
        
        headers = headers ? headers : ["-", "Visits", "Leads", "CLR", "CLs", "CARC", "PPL", "CPL", "CPAL", "CR", "CTR", "CPC", "ROI", "ROAS", "Revenue", "Spent", "ADP", "Profit"];
        let curHeaderIndex = ["Date", "Offer", "User"].includes(finalOrd.name) ? 0 : headers.indexOf(finalOrd.name);
        if (curHeaderIndex < 0) return data;

        const checkExists = item => item !== null && item !== undefined;
        const removeExtra = item => {
            let final = String(item).replace(/x/gmis, "").replace(/,/gmis, "").split(" ")[0];
            return Number(final);
        };
        return data.sort((a, b) => {
            if (
                (
                    !checkExists(a.columns[curHeaderIndex]?.text) ||
                    !checkExists(b.columns[curHeaderIndex]?.text)
                ) &&
                (
                    !checkExists(a.columns[curHeaderIndex]?.group?.[0]?.text) ||
                    !checkExists(b.columns[curHeaderIndex]?.group?.[0]?.text)
                )
            ) return -1;

            let a1 = null;
            let b1 = null;

            if (Array.isArray(a.columns[curHeaderIndex]?.group)) {
                a1 = removeExtra(a.columns[curHeaderIndex]?.group?.[0]?.text);
            } else {
                a1 = removeExtra(a.columns[curHeaderIndex].text);
            };
            if (Array.isArray(b.columns[curHeaderIndex]?.group)) {
                b1 = removeExtra(b.columns[curHeaderIndex]?.group?.[0]?.text);
            } else {
                b1 = removeExtra(b.columns[curHeaderIndex].text);
            };

            if (finalOrd.order === "asc") {
                if (+a1 > +b1) return 1; else return -1;
            } else {
                if (+b1 > +a1) return 1; else return -1;
            };
        });
    };

    const getData = async ts => {
        setData();
        if (timestampRef.current !== ts) return;

        let trackFilters = [];
        if (Array.isArray(props.filters)) trackFilters.push(...props.filters);

        let tmpLeads = await axios({
            method: "POST",
            url: `${backendModule.backendURL}/campaigns/getAllOrderCRMIDs`,
            data: {
                IntegrationType: props.integration,
                CampaignIDs: [props.ID],
                filters: trackFilters
            },
            ...backendModule.axiosConfig
        }).then(res => res.data).catch(() => backendModule.genericError);
        if (tmpLeads.status !== "ok") return setData(tmpLeads);
        if (tmpLeads.data.length === 0) return setData({status: "ok", data: {}});

        let tmpSpent = await axios({
            method: "POST",
            url: `${backendModule.backendURL}/campaigns/getAllCampaigns`,
            data: {
                IntegrationID: props.integrationID,
                allUsers: true,
                trackFilters,
                filters: [
                    {name: "ID", op: "eq", value: props.ID}
                ],
                TableHeaders: [
                    "Spent",
                    "Spent_IN"
                ]
            },
            ...backendModule.axiosConfig
        }).then(res => res.data).catch(() => backendModule.genericError);

        if (tmpSpent.status !== "ok") return setData(tmpSpent);
        if (tmpSpent.data.length !== 1) return setData(backendModule.genericError);
        
        let finalSpent = tmpSpent.data[0]?.TableData?.["Spent_IN"] || tmpSpent.data[0]?.TableData?.["Spent"] || 0;

        let finalData = await axios({
            method: "POST",
            url: `${backendModule.backendURL}/integrations/scalecrm/getAgentStatsByConversion`,
            data: {
                IDs: tmpLeads.data,
                Spent: finalSpent
            },
            ...backendModule.axiosConfig
        }).then(res => res.data).catch(() => backendModule.genericError);
        setData(finalData);
    };

    const prepareTableData = (data, column) => {
        switch (column) {
            case "Revenue":
            case "Spent":
            case "Profit":
            case "EPV":
            case "CPA":
            case "CPAO":
            case "CPC":
            case "CostPerAnyLead":
            case "CostPerLead":
            case "ADP":
            case "LTV":
                let tmpRevenue = Number(data);
                if (isNaN(tmpRevenue)) return "-";
                return `${Number(tmpRevenue.toFixed(2)).toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })} ${currencySignSelector}`;
            case "CR":
            case "AR":
            case "CARC":
            case "CUR":
            case "CRR":
            case "ROI":
            case "CTR":
            case "AbandonedR":
                let tmpCR = Number(data);
                if (isNaN(tmpCR)) return "-";
                return `${Number(tmpCR.toFixed(2)).toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })} %`;
            case "ROAS":
                let tmpROAS = Number(data);
                if (isNaN(tmpROAS)) return "-";
                return `${Number(tmpROAS.toFixed(2)).toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}x`;
            case "Hold":
                let tmpHold = Number(data);
                if (isNaN(tmpHold)) return "-";
                let hSeconds = Math.round(tmpHold / 1000);
                let hMinutes = 0;
                let hHours = 0;
                while (hSeconds >= 60) {
                    hMinutes += 1;
                    hSeconds -= 60;
                };
                while (hMinutes >= 60) {
                    hHours += 1;
                    hMinutes -= 60;
                };
                let hOut = [];
                if (hHours) hOut.push(`${hHours}h`);
                if (hMinutes || hHours) hOut.push(`${hMinutes}m`);
                if (hOut.length === 0 && !hSeconds) return "-";
                hOut.push(`${hSeconds}s`);
                return hOut.join(" ");
            default:
                let tmp = Number(data);
                if (isNaN(tmp)) return data;
                return tmp.toLocaleString("en-US");
        };
    };

    React.useEffect(() => {
        let ts = Date.now();
        timestampRef.current = ts;
        curDefer(() => getData(ts), 1000);
    }, [props.filters, props.ID, props.integration, props.integrationID]);

    return <div className="modals__campaignCRMAgentStatsModal__wrap__content__left">
        <FilteredCustomTable
            theme={themeSelector}
            accent="#6C5DD3"
            headers={["Agent", "Leads", "Approved", "CPA", "AR", "CPAO", "ADP", "ROAS", "SCR", "Hold"]}
            customColumns={(new Array(9)).fill("max-content")}
            style={{columnGap: "40px"}}
            headerStyle={{position: "sticky", top: 0}}
            orderCB={setOrders}
            data={(()=>{
                if (!data) return [{columns: [{keyID: "noData-spinner", type: "spinner", color: themeSelector === "dark" ? "white" : "black"}]}];
                if (data.status !== "ok") return [{columns: [{keyID: "noData-error", type: "custom", data: "Error while fetching data", style: {color: themeSelector === "dark" ? basicStylesModule.errorColor : basicStylesModule.errorColorLight}}]}];

                let out = [];
                for (let key of Object.keys(data.data)) {
                    out.push({
                        columns: [
                            {keyID: key, type: "text", text: `[${key}] ${data.data[key].Name}`, onClick: () => {
                                if (data.data[key].LeadIDs?.length > 0) window.open(`https://scale-crm.com/#/modals/viewLeads?ids=${data.data[key].LeadIDs.join(",")}`, "_blank");
                            }},
                            {keyID: key, type: "text", text: prepareTableData(data.data[key].Leads, "Conversions"), onClick: () => {
                                if (data.data[key].LeadIDs?.length > 0) return animateBox(<OrdersModal_asModal integration={props.integration} item={[props.ID]} filters={[{name: "v_integration_scalecrmLead", op: "in", value: data.data[key].LeadIDs}]} />);
                            }},
                            {keyID: key, type: "text", text: prepareTableData(data.data[key].Approved, "Approved")},
                            {keyID: key, type: "text", text: prepareTableData(data.data[key].CPA, "CPA")},
                            {keyID: key, type: "text", text: prepareTableData(data.data[key].AR, "AR")},
                            {keyID: key, type: "text", text: prepareTableData(data.data[key].CPAO, "CPAO")},
                            {keyID: key, type: "text", text: prepareTableData(data.data[key].ADP, "ADP")},
                            {keyID: key, type: "text", text: prepareTableData(data.data[key].ROAS, "ROAS")},
                            {keyID: key, type: "text", text: prepareTableData(data.data[key].SCR, "Conversions")},
                            {keyID: key, type: "text", text: prepareTableData(data.data[key].Hold, "Hold")}
                        ]
                    });
                };

                if (out.length > 0) out = performSort(out, ["Agent", "Leads", "Approved", "CPA", "AR", "CPAO", "ADP", "ROAS", "SCR", "Hold"]);
                if (out.length === 0) out.push({columns: [{keyID: "noData-noData", type: "custom", data: "Nothing to show..."}]});
                return out;
            })()}
        />
    </div>
};

export default CampaignCRMAgentStatsModal;