import React from "react";
import "./index.scss";

import axios from "axios";
import moment from "moment";
import { useSelector } from "react-redux";

import * as basicStylesModule from "../../../modules/basicStylesModule";
import * as backendModule from "../../../modules/backendModule";
import { animateBox } from "../../../modules/componentAnimation";
import useDefer from "../../../modules/hooks/useDefer";

import { FilteredCustomTable } from "../../customComponents/Table";
import Dropdown from "../../customComponents/Dropdown";

import StyledButton from "../../styledComponents/Button";
import StyledInput from "../../styledComponents/Input";
import RadioButton from "../../customComponents/RadioButton";
import Spinner from "../../customComponents/Spinner";

import YesNoModal from "../YesNoModal";

const CampaignAutomationModal = props => {
    const [data, setData] = React.useState();

    const mainRef = React.useRef();
    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");
    const automationsSelector = useSelector(state => state?.types?.campaignAutomations?.[String(props.IntegrationType)] ?? []);

    const onClose = (e) => {
        if (e) e?.stopPropagation();
        if (!mainRef.current) return props.onClose();

        mainRef.current.animate([
            { right: getComputedStyle(mainRef.current).right },
            { right: "-100%" }
        ], { duration: 300, iterations: 1, fill: "both", easing: "ease" });
        return props.onClose();
    };

    const getData = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/campaigns/automations/getAll`,
            data: {
                CampaignID: props.ID
            },
            ...backendModule.axiosConfig
        }).then(res => setData(res.data)).catch(() => setData(backendModule.genericError));
    };

    const operandMaps = op => {
        switch (op) {
            case "eq": return "=";
            case "neq": return "!=";
            case "gt": return ">";
            case "geq": return ">=";
            case "lt": return "<";
            case "leq": return "<=";
            default: return "?";
        };
    };

    const getHistoryItem = item => {
        if (!Array.isArray(item.History)) return "-";
        if (item.History.length === 0) return "-";
        return <span><span style={{color: "gray"}}>[{moment(item.History[item.History.length-1].Date).fromNow()}]&nbsp;</span>{item.History[item.History.length-1].Value}</span>;
    };

    const removeAutomation = id => {
        animateBox(<YesNoModal
            heading="Are you sure?"
            text="This will permanently remove the automation and its history. This wont affect the campaign any more."
            buttonLeftText="No"
            buttonRightText="Yes"
            isRightButtonNormal={true}
            buttonRightCallback={args => {
                args.spinner(true);
                args.disabledAll(true);

                axios({
                    method: "POST",
                    url: `${backendModule.backendURL}/campaigns/automations/remove`,
                    data: {ID: id},
                    ...backendModule.axiosConfig
                }).then(res => {
                    if (res.data.status === "ok") {
                        getData();
                        args.close();
                    } else {
                        args.errorMessage("Error while removing the automation!");
                    };
                }).catch(() => {
                    args.errorMessage("Server timed out!");
                }).finally(() => {
                    args.disabledAll(false);
                    args.spinner(false);
                });
            }}
        />);
    };

    React.useEffect(() => {
        if (!mainRef.current) return;

        mainRef.current.animate([
            { right: getComputedStyle(mainRef.current).right },
            { right: 0 }
        ], { duration: 300, iterations: 1, fill: "both", easing: "ease" });
    }, [mainRef.current]);

    React.useEffect(() => {
        getData();
    }, []);

    return <div className="modals__campaignAutomationModal" onClick={onClose}>
        <div className="modals__campaignAutomationModal__wrap" ref={mainRef} onClick={e => e?.stopPropagation()}>
            <div className="modals__campaignAutomationModal__wrap__top">
                <div className="modals__campaignAutomationModal__wrap__top__left">Campaign automation</div>

                <div className="modals__campaignAutomationModal__wrap__top__right"><img src="/images/icon_close.svg" onClick={() => onClose()} /></div>
            </div>
            <div className="modals__campaignAutomationModal__wrap__content">
                <StyledButton
                    style={{marginBottom: "20px", position: "sticky", left: 0}}
                    onClick={() => {
                        animateBox(<CampaignAutomationModal_add
                            onChange={() => {
                                getData();
                            }}
                            IntegrationType={props.IntegrationType}
                            columns={props.columns}
                            ID={props.ID}
                        />);
                    }}
                >Add automation</StyledButton>

                <FilteredCustomTable
                    theme={themeSelector}
                    accent="#6C5DD3"
                    headers={["Type", "Watched columns", "Delay", "Data", "Last execution", "Runs until", "Last history event"]}
                    customColumns={(new Array(7)).fill("max-content")}
                    style={{columnGap: "40px"}}
                    data={(()=>{
                        if (!data) return [[{keyID: "noData-spinner", type: "spinner", color: themeSelector === "dark" ? "white" : "black"}]];
                        if (data.status === "error") return [[{keyID: "noData-error", type: "text", text: "Error while fetching data", style: {color: themeSelector === "dark" ? basicStylesModule.errorColor : basicStylesModule.errorColorLight}}]];

                        let out = [];
                        for (let item of data.data) {
                            let automationName = automationsSelector.find(a => a.value === item.Name)?.name ?? "-";

                            out.push([
                                {keyID: item.ID, type: "text", text: automationName},
                                {keyID: item.ID, type: "custom", data: <div style={{display: "flex", flexDirection: "column", gap: "5px"}}>
                                    {item.Columns.map(col => {
                                        return <p>
                                            <span>{col.name}</span>
                                            <span>&nbsp;{operandMaps(col.op)}&nbsp;</span>
                                            <span>{col.value}</span>
                                        </p>
                                    })}
                                </div>},
                                {keyID: item.ID, type: "text", text: `${item.Delay} hours`},
                                {keyID: item.ID, type: "custom", data: <div>
                                    {Object.keys(item.Data).map(key => {
                                        return <p><span>{key}:</span>&nbsp; <span>{JSON.stringify(item.Data[key])}</span></p>
                                    })}
                                </div>},
                                {keyID: item.ID, type: "text", text: moment(item.lastExecuted).toDate().toLocaleString()},
                                {keyID: item.ID, type: "text", text: moment(item.expiresAt).toDate().toLocaleString()},
                                {keyID: item.ID, type: "text", text: getHistoryItem(item)},

                                {keyID: item.ID, type: "groupNewline", group: [
                                    {keyID: item.ID, type: "custom", data: <div style={{display: "flex", alignItems: "center", gap: "10px"}}>
                                        <StyledButton onClick={() => animateBox(<CampaignAutomationModal_history history={item.History} />)}>History</StyledButton>
                                        <StyledButton isSecondary onClick={() => removeAutomation(item.ID)}>Remove</StyledButton>
                                    </div>}
                                ]}
                            ])
                        };

                        if (out.length === 0) return [[{keyID: "noData-noData", type: "text", text: "Nothing to show..."}]];
                        return out;
                    })()}
                />
            </div>
        </div>
    </div>
};

const CampaignAutomationModal_history = props => {
    const mainRef = React.useRef();
    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");

    const onClose = (e) => {
        if (e) e?.stopPropagation();
        if (!mainRef.current) return props.onClose();

        mainRef.current.animate([
            { right: getComputedStyle(mainRef.current).right },
            { right: "-100%" }
        ], { duration: 300, iterations: 1, fill: "both", easing: "ease" });
        return props.onClose();
    };

    React.useEffect(() => {
        if (!mainRef.current) return;

        mainRef.current.animate([
            { right: getComputedStyle(mainRef.current).right },
            { right: 0 }
        ], { duration: 300, iterations: 1, fill: "both", easing: "ease" });
    }, [mainRef.current]);

    return <div className="modals__campaignAutomationModal" onClick={onClose}>
        <div className="modals__campaignAutomationModal__wrap" ref={mainRef} onClick={e => e?.stopPropagation()}>
            <div className="modals__campaignAutomationModal__wrap__top">
                <div className="modals__campaignAutomationModal__wrap__top__left">Automation history</div>

                <div className="modals__campaignAutomationModal__wrap__top__right"><img src="/images/icon_close.svg" onClick={() => onClose()} /></div>
            </div>

            <div className="modals__campaignAutomationModal__wrap__content">
                <FilteredCustomTable
                    theme={themeSelector}
                    accent="#6C5DD3"
                    headers={["Date", "Event"]}
                    customColumns={["max-content", "max-content"]}
                    style={{columnGap: "40px"}}
                    data={(()=>{
                        let out = [];
                        for (let item of props.history) {
                            out.push([
                                {keyID: item.Date, type: "text", text: moment(item.Date).toDate().toLocaleString()},
                                {keyID: item.Date, type: "text", text: item.Value}
                            ]);
                        };

                        if (out.length === 0) return [[{keyID: "noData-noData", type: "text", text: "Nothing to show..."}]];
                        return out;
                    })()}
                    canAnimate={false}
                    noTimeout={true}
                />
            </div>
        </div>
    </div>
};

const CampaignAutomationModal_add = props => {
    const [state, setState] = React.useState(0);
    const [data, setData] = React.useState({});
    const [name, setName] = React.useState();
    const [delay, setDelay] = React.useState(1);
    const [expiresAt, setExpiresAt] = React.useState(24);
    const [dataCompare, setDataCompare] = React.useState(1);
    const [showNotification, setShowNotification] = React.useState(false);
    const [header, setHeader] = React.useState("");
    const [curCampaign, setCurCampaign] = React.useState();
    const [columns, setColumns] = React.useState([]);
    const [spinner, setSpinner] = React.useState(false);

    const mainRef = React.useRef();
    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");

    const onClose = (e) => {
        if (e) e?.stopPropagation();
        if (!mainRef.current) return props.onClose();

        mainRef.current.animate([
            { right: getComputedStyle(mainRef.current).right },
            { right: "-100%" }
        ], { duration: 300, iterations: 1, fill: "both", easing: "ease" });
        return props.onClose();
    };

    const addAutomation = () => {
        const showError = err => {
            animateBox(<YesNoModal
                heading="Error"
                text={err}
                buttonLeftHidden={true}
                buttonRightText="Ok"
                isRightButtonNormal={true}
            />);
        };
        let finalData = {
            CampaignID: props.ID,
            Delay: delay,
            ComparePeriod: dataCompare,
            MaxDuration: expiresAt,
            SendNotification: showNotification,
            Name: name,
            Data: {...data},
            Columns: [...columns].map(c => {return {...c}})
        };

        if (!finalData.CampaignID) return showError("Campaign info missing (refresh the page)");
        if (!finalData.Name) return showError("Automation type missing (refrehs the page)");
        finalData.Delay = Number(finalData.Delay);
        finalData.ComparePeriod = Number(finalData.ComparePeriod);
        finalData.MaxDuration = Number(finalData.MaxDuration);
        if (!finalData.Data) return showError("Automation data missing - please fill in all the fields");
        if (isNaN(finalData.Delay) || finalData.Delay < 1) return showError("Delay must be a number greater than 0");
        if (isNaN(finalData.ComparePeriod) || finalData.ComparePeriod < 1) return showError("Compare period must be a number greater than 0");
        if (isNaN(finalData.MaxDuration) || finalData.MaxDuration < 1) return showError("Maximum run time must be a number greater than 0");

        switch (finalData.Name) {
            case "adset-budget-up-%":
                finalData.Data.by = Number(finalData.Data.by);
                finalData.Data.max = Number(finalData.Data.max);
                if (isNaN(finalData.Data.max) || finalData.Data.max < 0) return showError("Maximum budget must be a number greater than 0");
                if (isNaN(finalData.Data.by) || finalData.Data.by < 0) return showError("Budget increase must be a number greater than 0");
                if (!Array.isArray(finalData.Data.ids) && finalData.Data.ids !== true) return showError("You need to select at least 1 adset");
                break;
            case "adset-budget-down-%":
                finalData.Data.by = Number(finalData.Data.by);
                finalData.Data.max = Number(finalData.Data.max);
                if (isNaN(finalData.Data.max) || finalData.Data.max < 0) return showError("Minimum budget must be a number greater than 0");
                if (isNaN(finalData.Data.by) || finalData.Data.by < 0) return showError("Budget increase must be a number greater than 0");
                if (!Array.isArray(finalData.Data.ids) && finalData.Data.ids !== true) return showError("You need to select at least 1 adset");
                break;
            case "adset-on":
            case "adset-off":
                if (!Array.isArray(finalData.Data.ids) && finalData.Data.ids !== true) return showError("You need to select at least 1 adset");
                break;
            case "ad-on":
            case "ad-off":
                if (!Array.isArray(finalData.Data.ids) && finalData.Data.ids !== true) return showError("You need to select at least 1 adset");
                break;
            default: return showError("Unknown automation selected");
        };

        if (finalData.Columns.length === 0) return showError("Add at least 1 column");
        for (let col of finalData.Columns) {
            if (!col.name || !col.op || col.value === "") return showError("Fill out all columns or delete the empty ones!");
            col.value = Number(col.value);
            if (isNaN(col.value)) return showError("Column values must be numeric (no text).");
        };

        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/campaigns/automations/add`,
            data: finalData,
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                if (typeof(props.onChange) === "function") props.onChange();
                onClose();
            } else {
                showError("Error while creating the integration!");
            };
        }).catch(() => {
            showError("Server timed out!");
        }).finally(() => {
            setSpinner(false);
        });
    };

    React.useEffect(() => {
        if (!mainRef.current) return;

        mainRef.current.animate([
            { right: getComputedStyle(mainRef.current).right },
            { right: 0 }
        ], { duration: 300, iterations: 1, fill: "both", easing: "ease" });
    }, [mainRef.current]);

    React.useEffect(() => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/campaigns/getAllCampaignsWithoutData`,
            data: {
                filters: [
                    {name: "ID", op: "eq", value: props.ID}
                ],
                limit: 1
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                if (res.data.data.length > 0) {
                    setCurCampaign(res.data.data[0]);
                    return;
                };
            };
            animateBox(<YesNoModal
                heading="Error"
                text="Error while fetching campaign data"
                buttonLeftHidden={true}
                buttonRightText="Ok"
                isRightButtonNormal={true}
            />);
            onClose();
        }).catch(() => {
            animateBox(<YesNoModal
                heading="Error"
                text="Error while fetching campaign data"
                buttonLeftHidden={true}
                buttonRightText="Ok"
                isRightButtonNormal={true}
            />);
            onClose();
        });
    }, []);

    return <div className="modals__campaignAutomationModal" onClick={onClose}>
        <div className="modals__campaignAutomationModal__wrap" ref={mainRef} onClick={e => e?.stopPropagation()}>
            <div className="modals__campaignAutomationModal__wrap__top">
                <div className="modals__campaignAutomationModal__wrap__top__left">Add automation {header}</div>

                <div className="modals__campaignAutomationModal__wrap__top__right"><img src="/images/icon_close.svg" onClick={() => onClose()} /></div>
            </div>

            <div className="modals__campaignAutomationModal__wrap__content">
                {(()=>{
                    if (!curCampaign) return <Spinner color={themeSelector === "dark" ? "white" : "black"} />

                    if (state === 0) return <CampaignAutomationModal_add_1
                        onClose={() => setState(-1) && props.onClose()}
                        IntegrationType={props.IntegrationType}
                        onChange={e => {
                            setName(e);
                            setState(1);
                        }}
                        goBack={() => {setState(0); setName(); setHeader(""); setData({});}}
                        setHeader={setHeader}
                    />
                    if (state === 1) return <>
                        {name.startsWith("adset-") && <CampaignAutomationModal_add_adset
                            onChange={e => setData(d => {return {...d, ids: e}})}
                            ID={props.ID}
                        />}
                        {name.startsWith("ad-") && <CampaignAutomationModal_add_ad
                            onChange={e => setData(d => {return {...d, ids: e}})}
                            ID={props.ID}
                        />}

                        {name === "adset-budget-up-%" && <CampaignAutomationModal_add_adsetBudgetUpPercent onChange={e => setData(d => {return {...d, ...e}})} />}
                        {name === "adset-budget-down-%" && <CampaignAutomationModal_add_adsetBudgetDownPercent onChange={e => setData(d => {return {...d, ...e}})} />}
                        <div style={{display: "flex", alignItems: "center", marginTop: "20px", gap: "10px"}}>
                            <div style={{display: "flex", flexDirection: "column", justifyContent: "center", gap: "10px"}}>
                                <p>Delay between runs (in hours)</p>
                                <StyledInput value={delay} onChange={e => setDelay(e.target.value)} />
                            </div>
                            <div style={{display: "flex", flexDirection: "column", justifyContent: "center", gap: "10px"}}>
                                <p>Maximum run time (in hours)</p>
                                <StyledInput value={expiresAt} onChange={e => setExpiresAt(e.target.value)} />
                            </div>
                            <div style={{display: "flex", flexDirection: "column", justifyContent: "center", gap: "10px"}}>
                                <p>Data compare period (in days)</p>
                                <StyledInput value={dataCompare} onChange={e => setDataCompare(e.target.value)} />
                            </div>
                        </div>
                        <div style={{display: "flex", alignItems: "center", gap: "10px", marginTop: "10px"}}>
                            <RadioButton checked={showNotification} onChange={e => showNotification !== e && setShowNotification(e)} />
                            <p>Send notification</p>
                        </div>
                        
                        <CampaignAutomationModal_add_columns campaign={curCampaign} columns={props.columns} compare={dataCompare} ID={props.ID} IntegrationType={props.IntegrationType} onChange={e => setColumns(e)} />

                        <StyledButton style={{marginTop: "30px"}} onClick={() => !spinner && addAutomation()} isSpinner={spinner} isDisabled={spinner}>Add automation</StyledButton>
                    </>
                    return null;
                })()}
            </div>
        </div>
    </div>
};

const CampaignAutomationModal_add_1 = props => {
    const automationsSelector = useSelector(state => state?.types?.campaignAutomations?.[String(props.IntegrationType)] ?? []);
    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");

    return <>
        <p>First - select an automation type, then you will be asked to provide any additional info.</p>
        <Dropdown
            theme={themeSelector}
            accent="#6C5DD3"
            inlinePlaceholder="Automation type"
            data={automationsSelector.map(a => {
                return {name: a.name, value: a.value}
            })}
            style={{marginTop: "20px"}}
            onChange={e => {
                if (e?.value) {
                    props.setHeader([<span style={{color: "#6c5dd3"}}>{e.name}</span>, <StyledButton style={{height: "100%", marginLeft: "20px"}} isSecondary onClick={() => props.goBack()}>Change</StyledButton>]);
                    props.onChange(e.value);
                };
            }}
        />
    </>
};

const CampaignAutomationModal_add_adset = props => {
    const [data, setData] = React.useState();
    const [selectedTab, setSelectedTab] = React.useState(1);
    const [adsets, setAdsets] = React.useState([]);

    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");

    React.useEffect(() => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/campaigns/getCampaignAdsetData`,
            data: {
                ID: props.ID
            },
            ...backendModule.axiosConfig
        }).then(res => setData(res.data)).catch(() => setData(backendModule.genericError));
    }, []);

    React.useEffect(() => {
        if (selectedTab === 1) {
            if (adsets.length > 0) setAdsets([]);
            props.onChange(true);
        } else {
            if (adsets.length > 0) props.onChange(adsets);
        };
    }, [selectedTab, adsets]);

    return <>
        <div className="genericTabs">
            <div className={`genericTabs__tab ${selectedTab === 1 ? "genericTabs__tab--active" : ""}`} onClick={() => selectedTab !== 1 && setSelectedTab(1)}>All adsets</div>
            <div className={`genericTabs__tab ${selectedTab === 2 ? "genericTabs__tab--active" : ""}`} onClick={() => selectedTab !== 2 && setSelectedTab(2)}>Selected adsets</div>
            {selectedTab === 1 && <p>All adsets will be checked individually.</p>}
        </div>

        {selectedTab === 2 && <FilteredCustomTable
            theme={themeSelector}
            accent="#6C5DD3"
            headers={["Adset ID", "Adset Name"]}
            customColumns={(new Array(2)).fill("max-content")}
            data={(()=>{
                if (!data) return [[{keyID: "noData-spinner", type: "spinner", color: themeSelector === "dark" ? "white" : "black"}]];
                if (data.status === "error") return [[{keyID: "noData-error", type: "text", text: "Error while fetching data", style: {color: themeSelector === "dark" ? basicStylesModule.errorColor : basicStylesModule.errorColorLight}}]];

                let out = [];
                for (let item of data.data) {
                    out.push([
                        {keyID: item.AdsetID, type: "text", text: item.AdsetID},
                        {keyID: item.AdsetID, type: "text", text: item.AdsetName}
                    ])
                };
                if (out.length === 0) return [[{keyID: "noData-noData", type: "text", text: "Nothing to show..."}]];
                return out;
            })()}
            style={{columnGap: "40px"}}
            checkboxCB={data?.data?.length ? setAdsets : undefined}
        />}
    </>
};

const CampaignAutomationModal_add_ad = props => {
    const [data, setData] = React.useState();
    const [selectedTab, setSelectedTab] = React.useState(1);
    const [ads, setAds] = React.useState([]);

    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");

    React.useEffect(() => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/campaigns/getCampaignAdData`,
            data: {
                ID: props.ID,
                _newTable: true
            },
            ...backendModule.axiosConfig
        }).then(res => setData(res.data)).catch(() => setData(backendModule.genericError));
    }, []);

    React.useEffect(() => {
        if (selectedTab === 1) {
            if (ads.length > 0) setAds([]);
            props.onChange(true);
        } else {
            if (ads.length > 0) props.onChange(ads);
        };
    }, [selectedTab, ads]);

    return <>
        <div className="genericTabs">
            <div className={`genericTabs__tab ${selectedTab === 1 ? "genericTabs__tab--active" : ""}`} onClick={() => selectedTab !== 1 && setSelectedTab(1)}>All ads</div>
            <div className={`genericTabs__tab ${selectedTab === 2 ? "genericTabs__tab--active" : ""}`} onClick={() => selectedTab !== 2 && setSelectedTab(2)}>Selected ads</div>
            {selectedTab === 1 && <p>All ads will be checked individually.</p>}
        </div>

        {selectedTab === 2 && <FilteredCustomTable
            theme={themeSelector}
            accent="#6C5DD3"
            headers={["Ad ID", "Ad Name"]}
            customColumns={(new Array(2)).fill("max-content")}
            data={(()=>{
                if (!data) return [[{keyID: "noData-spinner", type: "spinner", color: themeSelector === "dark" ? "white" : "black"}]];
                if (data.status === "error") return [[{keyID: "noData-error", type: "text", text: "Error while fetching data", style: {color: themeSelector === "dark" ? basicStylesModule.errorColor : basicStylesModule.errorColorLight}}]];

                let out = [];
                for (let item of data.data) {
                    out.push([
                        {keyID: item.AdID, type: "text", text: item.AdID},
                        {keyID: item.AdID, type: "text", text: item.AdName}
                    ])
                };
                if (out.length === 0) return [[{keyID: "noData-noData", type: "text", text: "Nothing to show..."}]];
                return out;
            })()}
            style={{columnGap: "40px"}}
            checkboxCB={data?.data?.length ? setAds : undefined}
        />}
    </>
};

const CampaignAutomationModal_add_adsetBudgetUpPercent = props => {
    const [by, setBy] = React.useState(0);
    const [maximum, setMaximum] = React.useState(100);

    React.useEffect(() => {
        props.onChange({by, max: maximum});
    }, [by, maximum]);

    return <div style={{display: "flex", alignItems: "center", marginTop: "30px", gap: "10px"}}>
        <div style={{display: "flex", flexDirection: "column", justifyContent: "center", gap: "10px"}}>
            <p>Budget increase (in %)</p>
            <StyledInput value={by} onChange={e => setBy(e.target.value)} />
        </div>
        <div style={{display: "flex", flexDirection: "column", justifyContent: "center", gap: "10px"}}>
            <p>Maximum budget allowed</p>
            <StyledInput value={maximum} onChange={e => setMaximum(e.target.value)} />
        </div>
    </div>
};

const CampaignAutomationModal_add_adsetBudgetDownPercent = props => {
    const [by, setBy] = React.useState(0);
    const [minimum, setMinimum] = React.useState(0);

    React.useEffect(() => {
        props.onChange({by, min: minimum});
    }, [by, minimum]);

    return <div style={{display: "flex", alignItems: "center", marginTop: "30px", gap: "10px"}}>
        <div style={{display: "flex", flexDirection: "column", justifyContent: "center", gap: "10px"}}>
            <p>Budget decrease (in %)</p>
            <StyledInput value={by} onChange={e => setBy(e.target.value)} />
        </div>
        <div style={{display: "flex", flexDirection: "column", justifyContent: "center", gap: "10px"}}>
            <p>Minimum budget allowed</p>
            <StyledInput value={minimum} onChange={e => setMinimum(e.target.value)} />
        </div>
    </div>
};

const CampaignAutomationModal_add_columns = props => {
    const [selectedColumns, setSelectedColumns] = React.useState([]);
    const automationOperandsSelector = useSelector(state => state?.types?.campaignAutomationOperands ?? {});
    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");
    const curDefer = useDefer();

    const getCurrentData = (item) => {
        let tmpCompare = Number(props.compare);
        if (isNaN(tmpCompare) || tmpCompare < 1) {
            return setSelectedColumns(sc => sc.map(scm => {
                if (scm._id !== item._id) return scm;
                let tmp = {...scm, _current: "-"};
                return tmp;
            }));
        };

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/campaigns/getAllCampaigns`,
            data: {
                IntegrationID: props.campaign.IntegrationID,
                TableHeaders: [
                    item.name.replace(/ /gmis, "_")
                ],
                utcOffset: (new Date()).getTimezoneOffset(),
                filters: [
                    {name: "ID", op :"eq", value: props.ID}
                ],
                trackFilters: [
                    {name: "createdAt", op: "pdgeq", value: moment().add((tmpCompare - 1) * -1, "days").startOf("day").toDate().getTime()},
                    {name: "createdAt", op: "pdleq", value: moment().endOf("day").toDate().getTime()}
                ]
            },
            ...backendModule.axiosConfig
        }).then(res => {
            return setSelectedColumns(sc => sc.map(scm => {
                if (scm._id !== item._id) return scm;
                let tmp = {...scm, _current: "-"};
                if (res.data.status === "ok") {
                    let tmpC = res.data.data?.[0];
                    if (tmpC) {
                        let curValue = tmpC.TableData?.[item.name.replace(/ /gmis, "_")];
                        if (curValue !== null && curValue !== undefined) {
                            if (String(curValue).split(".")[1]?.length > 2) curValue = Number(curValue).toFixed(2);
                            tmp._current = curValue;
                        };
                    };
                };
                return tmp;
            }));
        }).catch(() => {
            return setSelectedColumns(sc => sc.map(scm => {
                if (scm._id !== item._id) return scm;
                let tmp = {...scm, _current: "-"};
                return tmp;
            }));
        });
    };

    React.useEffect(() => {
        let found = false;
        for (let item of selectedColumns) {
            if (item.name && (item._current === undefined)) {
                found = true;
                break;
            };
        };

        if (found) {
            setSelectedColumns(sc => {
                let items = [];
                let tmp = [...sc];
                for (let item of tmp) {
                    if (item._current === undefined) {
                        items.push({_id: item._id, name: item.name});
                        item._current = null;
                    };
                };

                requestAnimationFrame(() => items.forEach(getCurrentData));
                return tmp;
            });
        };
    }, [selectedColumns]);

    React.useEffect(() => {
        let tmp = selectedColumns.filter(sc => sc?.name).map(i => {
            let ii = {...i};
            delete ii._id;
            delete ii._current;
            return ii;
        });
        props.onChange(tmp);
    }, [selectedColumns]);

    React.useEffect(() => {
        curDefer(() => {
            setSelectedColumns(sc => sc.map(scm => {return {...scm, _current: undefined}}));
        }, 1000);
    }, [props.compare]);

    return <div style={{marginTop: "15px", paddingTop: "15px", borderTop: "1px solid gray"}}>
        <h3>Columns</h3>
        <p style={{color: "gray"}}>NOTE: all columns must pass before this automation gets triggered (AND operator)</p>
        <p style={{color: "gray"}}>NOTE2: "IN" are columns taken from integrations, and "ST value" is data that is pulled from the whole ST campaign (not adset/ad)</p>

        <FilteredCustomTable
            theme={themeSelector}
            accent="#6C5DD3"
            headers={["Name", "Operand", "Value", "ST Value", ""]}
            customColumns={(new Array(5)).fill("max-content")}
            style={{columnGap: "40px", marginTop: "20px"}}
            data={(()=>{
                let out = [];
                for (let item of selectedColumns) {
                    out.push([
                        {keyID: item._id, type: "custom", data: <Dropdown
                            theme={themeSelector}
                            accent="#6C5DD3"
                            data={props.columns.map(col => {
                                return {name: col, value: col}
                            })}
                            onChange={e => {
                                if (e?.value !== item.name) setSelectedColumns(sc => sc.map(scm => {
                                    if (scm._id !== item._id) return {...scm};
                                    return {...scm, name: e.value, _current: undefined};
                                }));
                            }}
                            selected={(()=>{
                                if (!item.name) return null;
                                return props.columns.indexOf(item.name);
                            })()}
                        />},
                        {keyID: item._id, type: "custom", data: <Dropdown
                            theme={themeSelector}
                            accent="#6C5DD3"
                            data={Object.keys(automationOperandsSelector).map(key => {
                                return {name: automationOperandsSelector[key], value: key};
                            })}
                            onChange={e => {
                                if (e?.value !== item.op) setSelectedColumns(sc => sc.map(scm => {
                                    if (scm._id !== item._id) return {...scm};
                                    return {...scm, op: e.value};
                                }));
                            }}
                            selected={(()=>{
                                if (!item.op) return null;
                                return Object.keys(automationOperandsSelector).indexOf(item.op);
                            })()}
                        />},
                        {keyID: item._id, type: "custom", data: <StyledInput value={item.value} onChange={e => {
                            let val = e.target.value;
                            if (val !== item.value) setSelectedColumns(sc => sc.map(scm => {
                                if (scm._id !== item._id) return {...scm};
                                return {...scm, value: val};
                            }));
                        }} />},
                        {keyID: item._id, type: "text", text: ((item._current === null || item._current === undefined) && item.name) ? <Spinner style={{width: "16px", height: "16px"}} color={themeSelector === "dark" ? "white" : "black"} /> : (item._current ?? "-")},
                        {keyID: item._id, type: "custom", data: <StyledButton isSecondary onClick={() => {
                            setSelectedColumns(sc => sc.filter(scf => scf._id !== item._id));
                        }}>Remove</StyledButton>}
                    ]);
                };

                out.push([{keyID: "newItem", type: "custom", data: <StyledButton onClick={() => setSelectedColumns(sc => [...sc, {_id: `sc-${Date.now()}-${Math.floor(Math.random() * 999999)}`, name: "", op: "", value: "", _current: undefined}])}>Add</StyledButton>}]);
                return out;
            })()}
            canAnimate={false}
            noTimeout={true}
        />
    </div>
};

export default CampaignAutomationModal;