import React from "react";
import "./index.scss";

import { animateBox } from "../../../modules/componentAnimation";
import { useSelector } from "react-redux";
import axios from "axios";

import * as backendModule from "../../../modules/backendModule";

import FilterBySearch from "../FilterBySearch";
import Checkbox from "../../customComponents/Checkbox";
import Spinner from "../../customComponents/Spinner";
import StyledButton from "../../styledComponents/Button";

import CampaignCustomColumnsModal from "../../modals/CampaignCustomColumnsModal";

const FilterColumns = (props) => {
    const [columns, setColumns] = React.useState([]);

    const firstTImeRef = React.useRef(true);

    React.useEffect(() => {
        if (firstTImeRef.current) {
            firstTImeRef.current = false;
            return;
        };
        if (props.onChange) props.onChange(columns.filter(c => c.enabled).map(c => c.name));
    }, [columns]);

    React.useEffect(() => {
        if (!props.columns) return;
        if (!Array.isArray(props.columns)) return;

        let finalSelected = props.defaultValue ?? [];
        if (!Array.isArray(finalSelected)) finalSelected = [];

        let idx = 0;
        let finalColumns = [...props.columns, ...props.defaultValue.filter(df => df.endsWith("_CC"))].map(c => {
            return {name: c, enabled: finalSelected.includes(c), ID: idx++, isDragging: false, isDraggedOver: false};
        });

        if (finalSelected.length > 0) {
            finalColumns = finalColumns.sort((a, b) => {
                return finalSelected.indexOf(a.name) > finalSelected.indexOf(b.name) ? 1 : -1;
            }).sort((a, b) => {
                if (!a.enabled && !b.enabled) return 0;
                if (a.enabled && b.enabled) return 0;

                return a.enabled ? -1 : 1;
            });
        };

        setColumns(finalColumns);
    }, []);

    return <div className="component__filters__filterColumn" onClick={e => {
        animateBox(e, <FilterColumnSidePanel defaultPresets={props.defaultPresets} integrationID={props.integrationID} columns={columns} onChange={setColumns} customColumns={props.customColumns} />)
    }} style={{...(props?.style ?? {})}}>
        <img src="/images/icon_editColumn.svg" />
        <p>{props.placeholder ?? "Manage columns"}</p>
    </div>
};

const FilterColumnSidePanel = props => {
    const [columns, setColumns] = React.useState(props.columns ?? []);
    const [search, setSearch] = React.useState("");
    const [spinner, setSpinner] = React.useState(false);
    const [presets, setPresets] = React.useState();
    const [appliedPreset, setAppliedPreset] = React.useState(false);

    const campaignColumnExplanationsSelector = useSelector(state => state?.types?.campaignColumnExplanations ?? {});

    const wrapRef = React.useRef();
    const curDragRef = React.useRef();

    const handleSearch = (item) => {
        if (!search) return true;

        if (item.name.toLowerCase().includes(search.toLowerCase())) return true;

        return false;
    };

    const handleDragStart = (e, ID) => {
        curDragRef.current = ID;

        setColumns(c => c.map(cm => {
            return {...cm, isDragging: ID === cm.ID ? true : false};
        }));
    };

    const handleDragEnd = (e, ID) => {
        e.preventDefault();
        let finalDrag = columns.find(c => c.isDraggedOver === true);

        if (!finalDrag || curDragRef.current === finalDrag.ID) {
            setColumns(c => c.map(cm => {
                return {...cm, isDragging: false};
            }));
            curDragRef.current = null;
            return;
        };

        if (curDragRef.current === null) {
            curDragRef.current = null;
            return;
        };
        
        let final = [...columns];
        let startingDrag = final.find(f => f.ID === curDragRef.current);
        if (!startingDrag) return;

        final = [...final.map((f) => {
            if (f.ID === startingDrag.ID) return null;
            if (f.ID === finalDrag.ID) return [finalDrag, startingDrag];

            return f;
        }).filter(f => f).flat(3)].map(f => {
            f.isDraggedOver = false;
            f.isDragging = false;
            return f;
        });

        setColumns(final);
        curDragRef.current = null;
    };

    const handleDragOver = (e, ID) => {
        if (curDragRef.current === null) return;

        if (ID !== curDragRef.current) {
            setColumns(c => c.map(cm => {
                return {
                    ...cm,
                    isDraggedOver: cm.ID === ID ? true : false
                };
            }));
        };
    };

    const handleDragLeave = (e, ID) => {
        setColumns(c => c.map(cm => {
            return {
                ...cm,
                isDraggedOver: cm.ID === ID ? false : cm.isDraggedOver
            };
        }));
    };

    const handleCheckChanged = (ID, newState) => {
        let curCol = columns.find(c => c.ID === ID);
        if (!curCol) return;

        if (curCol.enabled === newState) return;

        setColumns(c => c.map(cm => {
            return {
                ...cm,
                enabled: cm.ID === ID ? newState : cm.enabled
            };
        }));
    };

    const handleDraggingClass = (col) => {
        let anyDragging = columns.find(c => c.isDragging === true);
        if (!anyDragging) return "";
        
        if (anyDragging.ID === col.ID) return "dragging";
        if (col.isDraggedOver) return "hint";

        return "";
    };

    const handleCheckAll = () => {
        setColumns(c => c.map(cm => {
            return {...cm, enabled: true};
        }));
    };

    const handleUncheckAll = () => {
        setColumns(c => c.map(cm => {
            return {...cm, enabled: false};
        }));
    };

    const handleUpClick = (idx) => {
        if (idx === 0) return;

        let out = [];
        columns.forEach((col, colIdx, colArr) => {
            if (colIdx === idx) {
                return out.push(colArr[idx], colArr[idx-1]);
            };
            if (colIdx === idx - 1) return;
            out.push(col);
        });
        out = out.flat(3);
        setColumns(out);
    };

    const handleDownClick = (idx) => {
        if (idx === columns.length - 1) return;

        let out = [];
        columns.forEach((col, colIdx, colArr) => {
            if (colIdx === idx) {
                return out.push(colArr[idx+1], colArr[idx]);
            };
            if (colIdx === idx + 1) return;
            out.push(col);
        });
        out = out.flat(3);
        setColumns(out);
    };

    const onClose = () => {
        if (props.onChange) props.onChange(columns);

        if (wrapRef.current) {
            wrapRef.current.animate([
                {right: getComputedStyle(wrapRef.current).right},
                {right: "-100%"}
            ], {
                duration: 300,
                iterations: 1,
                fill: "both",
                easing: "ease"
            });
        };

        props.onClose();
    };

    const getCustomColumns = () => {
        let allColumns = columns.filter(c => !c.name.endsWith("_CC"));
        let cColumns = columns.filter(c => c.name.endsWith("_CC"));
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/campaigns/customColumns/getAll`,
            data: {
                IntegrationType: props.customColumns
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                let lastID = Math.max(...allColumns.map(ac => ac.ID)) + 1;
                for (let item of res.data.data) {
                    let curCColumn = cColumns.find(c => c.name === item);
                    if (!allColumns.find(ac => ac.name === item))  {
                        allColumns.push({
                            ID: lastID++,
                            enabled: curCColumn?.enabled ? !!curCColumn.enabled : false,
                            isDraggedOver: false,
                            isDragging: false,
                            name: item
                        });
                    };
                };
                setColumns(allColumns);
            } else {
                setColumns(allColumns);
            };
        }).catch(() => {
            setColumns(allColumns);
        });
    };

    const getPresets = () => {
        let tmpIntegration = props.integrationID ?? "generic-data";
        if (tmpIntegration.startsWith("fb-")) tmpIntegration = "fb";
        if (tmpIntegration.startsWith("mgid-")) tmpIntegration = "mgid";
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/userSavedColumns/getPresets`,
            data: {
                Integration: tmpIntegration
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                return setPresets([
                    ...(props.defaultPresets ?? []),
                    ...res.data.data
                ]);
            };
            setPresets([...(props.defaultPresets ?? [])]);
        }).catch(() => {
            setPresets([...(props.defaultPresets ?? [])]);
        }).finally(() => {
            setSpinner(false);
        });
    };

    const savePreset = (e) => {
        if (spinner) return;
        setSpinner(true);

        let tmpIntegration = props.integrationID ?? "generic-data";
        if (tmpIntegration.startsWith("fb-")) tmpIntegration = "fb";
        if (tmpIntegration.startsWith("mgid-")) tmpIntegration = "mgid";

        animateBox(e, <PresetInfoModal onChange={final => {
            if (final) {
                //TODO: save
                let toSave = columns.filter(c => c.enabled).map(c => c.name);

                axios({
                    method: "POST",
                    url: `${backendModule.backendURL}/userSavedColumns/addPreset`,
                    data: {
                        Name: final,
                        Integration: tmpIntegration,
                        Columns: toSave
                    },
                    ...backendModule.axiosConfig
                }).then((res) => {
                    if (res.data.status === "ok") getPresets();
                }).catch(() => null).finally(() => {
                    setSpinner(false);
                })
            } else {
                setSpinner(false);
            };
        }} />);
    };

    const applyPreset = (preset) => {
        if (!preset) return;
        if (!preset.Columns) return;

        setColumns(c => {
            let tmp = c.map(cc => {
                cc.enabled = preset.Columns.includes(cc.name);
                return cc;
            });
            let out = [];
            for (let col of preset.Columns) {
                for (let item of tmp) {
                    if (item.name === col) {
                        out.push(item);
                        tmp = tmp.filter(t => t.ID !== item.ID);
                        break;
                    };
                };
            };
            out.push(...tmp);
            return out;
        });
        setAppliedPreset(true);
    };

    const removePreset = (preset) => {
        if (!preset) return;

        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/userSavedColumns/removePreset`,
            data: {
                ID: preset.ID
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                return getPresets();
            };
            setSpinner(false);
        }).catch(() => {
            setSpinner(false);
        });
    };

    React.useEffect(() => {
        if (!wrapRef.current) return;

        wrapRef.current.animate([
            {right: getComputedStyle(wrapRef.current).right},
            {right: 0}
        ], {
            duration: 300,
            iterations: 1,
            fill: "both",
            easing: "ease"
        });
    }, [wrapRef.current]);

    React.useEffect(() => {
        if (!appliedPreset) return;

        onClose();
    }, [columns, appliedPreset]);

    React.useEffect(() => {
        getPresets();

        if (props.customColumns !== null && props.customColumns !== undefined) getCustomColumns();
    }, []);

    return <div className="component__filters__filterColumn__sidePanel" onClick={() => onClose()}>
        <div ref={wrapRef} className="component__filters__filterColumn__sidePanel__wrap" onClick={e => e.stopPropagation()}>
            <div className={`component__filters__filterColumn__sidePanel__wrap__spinner ${(spinner || !presets) ? "component__filters__filterColumn__sidePanel__wrap__spinner--active" : ""}`}>
                <Spinner color="white" />
            </div>

            <div className="component__filters__filterColumn__sidePanel__wrap__head">
                <div className="component__filters__filterColumn__sidePanel__wrap__head__left">Manage columns</div>
                <div className="component__filters__filterColumn__sidePanel__wrap__head__right" onClick={onClose}>
                    <img src="/images/icon_close.svg" />
                </div>
            </div>

            <FilterBySearch onChange={setSearch} />

            <div className="component__filters__filterColumn__sidePanel__wrap__buttons">
                <div className="component__filters__filterColumn__sidePanel__wrap__buttons__button" onClick={handleCheckAll}>
                    <img src="/images/filters/filterColumns_selectAll.svg" />
                    <p>Select all</p>
                </div>

                <div className="component__filters__filterColumn__sidePanel__wrap__buttons__button" onClick={handleUncheckAll}>
                    <img src="/images/filters/filterColumns_deselectAll.svg" />
                    <p>Deselect all</p>
                </div>
            </div>

            {props.integrationID && <div className="component__filters__filterColumn__sidePanel__wrap__presets">
                <p className="component__filters__filterColumn__sidePanel__wrap__presets__text">
                    <div className="component__filters__filterColumn__sidePanel__wrap__presets__text__left">Presets</div>
                    <div className="component__filters__filterColumn__sidePanel__wrap__presets__text__right">
                        <div className="component__filters__filterColumn__sidePanel__wrap__presets__text__right__button" onClick={savePreset}>
                            <img src="/images/icon_addRound.svg" />
                            <p>Save selection as preset</p>
                        </div>
                    </div>
                </p>
                <div className="component__filters__filterColumn__sidePanel__wrap__presets__items">
                    {(presets ?? []).map(preset => {
                        return <div className="component__filters__filterColumn__sidePanel__wrap__presets__items__item" onClick={() => applyPreset(preset)}>
                            <p className="component__filters__filterColumn__sidePanel__wrap__presets__items__item__name">{preset.Name}</p>
                            
                            <div className="component__filters__filterColumn__sidePanel__wrap__presets__items__item__close" style={{backgroundImage: `url("/images/icon_close.svg")`}} onClick={e => {
                                e.stopPropagation();
                                removePreset(preset);
                            }}></div>
                        </div>
                    })}
                </div>
            </div>}

            <h3 style={{padding: "20px", paddingTop: "0", display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                <span>Columns</span>
                {(props.customColumns !== null && props.customColumns !== undefined) && <StyledButton style={{height: "100%", width: "max-content", padding: "4px 10px", fontSize: "14px"}} onClick={() => {
                    animateBox(<CampaignCustomColumnsModal integrationType={props.customColumns} columns={columns.filter(c => !c.name.endsWith("_CC") && !c.name.startsWith("@")).map(c => c.name)} onChange={getCustomColumns} />)
                }}>Edit custom columns</StyledButton>}
            </h3>
            <div className="component__filters__filterColumn__sidePanel__wrap__columns">
                {columns.map((col, colIdx) => {
                    if (!handleSearch(col)) return null;
                    
                    return <div
                        className={`component__filters__filterColumn__sidePanel__wrap__columns__item ${handleDraggingClass(col)}`}
                        draggable={true}
                        onDragStart={e => handleDragStart(e, col.ID)}
                        onDragEnd={e => handleDragEnd(e, col.ID)}
                        onDragOver={e => handleDragOver(e, col.ID)}
                        onDragLeave={e => handleDragLeave(e, col.ID)}
                    >
                        <Checkbox checked={col.enabled} onClick={() => handleCheckChanged(col.ID, !col.enabled)} />
                        <p title={(()=>{
                            let vc = col.name.replace(/ /g, "_");
                            if (vc.endsWith("_IN")) vc = vc.substring(0, vc.length-3);
                            return campaignColumnExplanationsSelector[vc] ?? "-"
                        })()}>{col.name}</p>

                        <img src="/images/downArrow.svg" style={{transform: `rotate(180deg)`}} onClick={() => handleUpClick(colIdx)} />
                        <img src="/images/downArrow.svg" onClick={() => handleDownClick(colIdx)} />
                    </div>
                })}
            </div>
        </div>
    </div>
};

const PresetInfoModal = props => {
    const [infoP, setInfoP] = React.useState();

    const nameRef = React.useRef();

    const onClose = (data) => {
        if (props.onChange) props.onChange(data);
        props.onClose();
    };

    return <div className="genericModal">
        <div className="genericModal__wrap">
            <div className="genericModal__wrap__head">
                <div className="genericModal__wrap__head__left">Save as preset</div>
                <div className="genericModal__wrap__head__right" style={{backgroundImage: `url("/images/icon_close.svg")`}} onClick={() => onClose()}></div>
            </div>

            <div className={`genericModal__wrap__input ${infoP ? "genericModal__wrap__input--error" : ""}`}>
                <p>Preset name</p>
                <input type="text" placeholder="Preset name" ref={nameRef} />
            </div>

            <div className="genericModal__wrap__buttons">
                <div className="genericModal__wrap__buttons__btn genericModal__wrap__buttons__btn--secondary" onClick={() => onClose()}>Close</div>
                <div className="genericModal__wrap__buttons__btn" onClick={() => {
                    if (!nameRef.current.value) return setInfoP(`Preset name can't be empty!`);
                    onClose(nameRef.current.value);
                }}>Save</div>
            </div>

            {infoP && <p className="genericModal__wrap__infoP" style={{opacity: 1}}>{infoP}</p>}
        </div>
    </div>
};

export default FilterColumns;