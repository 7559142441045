import React from 'react';
import "./index.scss";

import Spinner from "../../customComponents/Spinner";

import PropTypes from "prop-types";

export default function YesNoModal(props) {
    const [spinnerRight, setSpinnerRight] = React.useState(false);
    const [spinnerLeft, setSpinnerLeft] = React.useState(false);
    const [disabledRight, setDisabledRight] = React.useState(false);
    const [disabledLeft, setDisabledLeft] = React.useState(false);
    const [disabledAll, setDisabledAll] = React.useState(false);
    const [isLeftHovering, setIsLeftHovering] = React.useState(false);
    const [isRightHovering, setIsRightHovering] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState(null);
    const [progress, setProgress] = React.useState();
    const [text, setText] = React.useState(props.text);

    const handleMouseEnterLeft = () => {
        setIsLeftHovering(true);
    };

    const handleMouseLeaveLeft = () => {
        setIsLeftHovering(false);
    };

    const handleMouseEnterRight = () => {
        setIsRightHovering(true);
    };

    const handleMouseLeaveRight = () => {
        setIsRightHovering(false);
    };

    const callbackFunctions = {
        close: props.onClose,
        spinner: (arg) => setSpinnerRight(!!arg),
        disabled: (arg) => setDisabledRight(!!arg),
        disabledAll: (arg) => setDisabledAll(!!arg),
        errorMessage: (arg) => setErrorMessage(arg),
        setProgress: (arg) => setProgress(arg),
        setText: arg => setText(arg)
    };

    const mainRef = React.useRef();

    React.useEffect(() => {
        if (typeof(props.onOpenCallback) === "function") props.onOpenCallback(callbackFunctions);
    }, [props.onOpenCallback]);

    React.useEffect(() => {
        if (!mainRef.current) return;

        mainRef.current.animate([
            {opactiy: getComputedStyle(mainRef.current).opacity},
            {opacity: 1}
        ], {duration: 300, delay: 100, iterations: 1, fill: "both", easing: "ease"});
    }, [mainRef.current]);

    return (
        <div className='component__yesNoModal'>
            <div className='component__yesNoModal__wrapper' ref={mainRef}>
                <img className='component__yesNoModal__wrapper__close' src='images/icon_close.svg' alt='' onClick={() => props.xCallback ? props.xCallback() : props.onClose()} />
                <strong className='component__yesNoModal__wrapper__heading'>{props.heading}</strong>
                <p className='component__yesNoModal__wrapper__text'>
                    {text}
                </p>
                <div className='component__yesNoModal__wrapper__buttons'>
                    <div
                        style={{
                            display: props.buttonLeftHidden ? 'none' : 'flex',
                            background: disabledAll || disabledLeft ? '#373A43' : isLeftHovering && props.isLeftButtonNormal ? '#5A49CE' : isLeftHovering && !props.isLeftButtonNormal ? '#0D0F11' : props.isLeftButtonNormal ? '#6C5DD3' : '#1B1D21',
                            pointerEvents: disabledLeft || disabledAll ? 'none' : ''
                        }}
                        className='component__yesNoModal__wrapper__buttons__leftBtn'
                        onClick={() => !props.buttonLeftCallback ? props.onClose() : props.buttonLeftCallback(callbackFunctions)}
                        onMouseEnter={handleMouseEnterLeft}
                        onMouseLeave={handleMouseLeaveLeft}
                    >
                        {!spinnerLeft && <p>{props.buttonLeftText ? props.buttonLeftText : 'No'}</p>}
                        {spinnerLeft && <Spinner style={{ width: "32px", height: "32px" }} color="white" />}
                    </div>
                    <div
                        style={{
                            display: props.buttonRightHidden ? 'none' : 'flex',
                            background: disabledAll || disabledRight ? '#373A43' : isRightHovering && props.isRightButtonNormal ? '#5A49CE' : isRightHovering && !props.isRightButtonNormal ? '#0D0F11' : props.isRightButtonNormal ? '#6C5DD3' : '#1B1D21',
                            pointerEvents: disabledRight || disabledAll ? 'none' : ''
                        }}
                        className='component__yesNoModal__wrapper__buttons__rightBtn'
                        onClick={() => !props.buttonRightCallback ? props.onClose() : props.buttonRightCallback(callbackFunctions)}
                        onMouseEnter={handleMouseEnterRight}
                        onMouseLeave={handleMouseLeaveRight}
                    >
                        {!spinnerRight && <p>{props.buttonRightText ? props.buttonRightText : 'Yes'}</p>}
                        {spinnerRight && <Spinner style={{ width: "32px", height: "32px" }} color="white" />}
                    </div>
                </div>
                {progress && <>
                    <p className='component__yesNoModal__wrapper__progressValue'>
                        {progress.value} / {progress.max}
                    </p>
                    <div className="component__yesNoModal__wrapper__progress">
                        <div className='component__yesNoModal__wrapper__progress__item' style={{
                            width: `${100 / progress.max * progress.value}%`
                        }}></div>
                    </div>
                </>}
                {errorMessage && <p className='component__yesNoModal__wrapper__errorMessage'>{errorMessage}</p>}
            </div>
        </div>
    )
}

YesNoModal.propTypes = {
    heading: PropTypes.string,
    text: PropTypes.string,
    buttonLeftText: PropTypes.string,
    buttonRightText: PropTypes.string,
    buttonLeftCallback: PropTypes.func,
    buttonRightCallback: PropTypes.func,
    buttonLeftHidden: PropTypes.bool,
    buttonRightHidden: PropTypes.bool,
    isLeftButtonNormal: PropTypes.bool,
    isRightButtonNormal: PropTypes.bool,
    onOpenCallback: PropTypes.func,
    xCallback: PropTypes.func,
}