import React from "react";

import axios from "axios";
import moment from "moment";

import * as backendModule from "../../../modules/backendModule";
import * as basicStylesModule from "../../../modules/basicStylesModule";
import { animateBox } from "../../../modules/componentAnimation";

import { useSelector } from "react-redux";
import { FilteredCustomTable } from "../../../components/customComponents/Table";
import Spinner from "../../../components/customComponents/Spinner";
import PreviewImageModal from "../../../components/modals/PreviewImageModal";

import FilterByDate from "../../../components/filters/FilterByDate";
import AdvancedDropdown from "../../../components/customComponents/AdvancedDropdown";

const UserCreatives_FB = () => {
    const [allOffers, setAllOffers] = React.useState();
    const [data, setData] = React.useState();
    const [adNames, setAdNames] = React.useState({});
    const [filterByAdStatus, setFilterByAdStatus] = React.useState(null);
    const [filterByOffer, setFilterByOffer] = React.useState(null);
    const [filterByDate, setFilterByDate] = React.useState(null);
    const [order, setOrder] = React.useState();

    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");
    const currencySignSelector = useSelector(state => state?.types?.currencySign ?? "?");

    const adDataRef = React.useRef([]);

    const prepareTableData = (data, column) => {
        column = column.replace("_IN", "");
        switch (column) {
            case "Revenue":
            case "Spent":
            case "Profit":
            case "EPV":
            case "CPA":
            case "CPAO":
            case "CPC":
            case "CostPerAnyLead":
            case "CostPerLead":
            case "ADP":
            case "TSADP":
            case "LTV":
                let tmpRevenue = Number(data);
                if (isNaN(tmpRevenue)) return "-";
                return `${Number(tmpRevenue.toFixed(2)).toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })} ${currencySignSelector}`;
            case "CR":
            case "TSCR":
            case "AR":
            case "TSAR":
            case "CARC":
            case "CUR":
            case "CRR":
            case "ROI":
            case "CTR":
            case "TSDR":
                let tmpCR = Number(data);
                if (isNaN(tmpCR)) return "-";
                return `${Number(tmpCR.toFixed(2)).toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })} %`;
            case "ROAS":
                let tmpROAS = Number(data);
                if (isNaN(tmpROAS)) return "-";
                return `${Number(tmpROAS.toFixed(2)).toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}x`;
            default:
                let tmp = Number(data);
                if (isNaN(tmp)) return data;
                return tmp.toLocaleString("en-US");
        };
    };

    const getAdIDs = () => {
        if (!data) return [];
        if (data?.status !== "ok") return [];

        let curIntegration = data.data?.Integrations?.[0];
        if (!curIntegration) return [];
        curIntegration = curIntegration?.TableData?.["CR"];
        if (!curIntegration || !Array.isArray(curIntegration)) return [];

        let tmp = [];
        for (let item of curIntegration) {
            if (!tmp.includes(String(item["Date_IntegrationParams-stadid"]))) tmp.push(String(item["Date_IntegrationParams-stadid"]));
        };

        return tmp;
    };

    const getTableData = (column, AdID) => {
        if (data?.status !== "ok") return "-";

        let curIntegration = data.data?.Integrations?.[0]?.TableData;
        if (!curIntegration) return "-";
        if (!curIntegration?.[column] || !Array.isArray(curIntegration[column])) return prepareTableData(0, column);

        for (let item of curIntegration[column]) {
            if (String(item["Date_IntegrationParams-stadid"]) === String(AdID)) return prepareTableData(item.Value, column);
        };
        return prepareTableData(0, column);
    };

    const getAdNames = (ads) => {
        if (ads.length === 0) return;

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/integrations/facebook/getAdNames`,
            data: {
                AdIDs: ads
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                let tmp = {};
                for (let item of res.data.data) {
                    tmp[String(item["AdID"])] = item["AdName"];
                };
                for (let item of ads) {
                    if (!tmp[String(item)]) {
                        tmp[String(item)] = <span style={{ color: "gray" }}>Not found</span>
                    };
                };
                setAdNames(tmp);
            } else {
                let tmp = {};
                for (let item of ads) {
                    tmp[String(item)] = <span style={{ color: themeSelector === "dark" ? basicStylesModule.errorColor : basicStylesModule.errorColorLight }}>Server error</span>
                };
                setAdNames(tmp);
            };
        }).catch(() => {
            let tmp = {};
            for (let item of ads) {
                tmp[String(item)] = <span style={{ color: themeSelector === "dark" ? basicStylesModule.errorColor : basicStylesModule.errorColorLight }}>Server error</span>
            };
            setAdNames(tmp);
        });
    };

    const getData = async () => {
        if (!filterByDate) return;

        let filters = [];
        let campaignFilters = [];

        filters.push(...[
            { name: "createdAt", op: "pdgeq", value: filterByDate.start.toDate().getTime() },
            { name: "createdAt", op: "pdleq", value: filterByDate.end.toDate().getTime() }
        ]);
        campaignFilters.push(...[
            { name: "lastTrackedAt", op: "pdgeq", value: filterByDate.start.toDate().getTime() },
        ]);
        if (filterByOffer) filters.push({ name: "OfferID", op: "eq", value: filterByOffer });

        setData();
        setAdNames({});

        let adData = await axios({
            method: "POST",
            url: `${backendModule.backendURL}/campaigns/getAllCampaignsAdData`,
            data: {
                filters: [
                    ...campaignFilters,
                    { name: "IntegrationType", op: "eq", value: 0 }
                ],
                adFilters: [
                    {
                        not: [
                            { name: "AdID", op: "startsWith", value: "c_spentchange" },
                        ]
                    },
                    { name: "createdAt", op: "pdgeq", value: moment(filterByDate.start).add(-14, "days").toDate().getTime() }
                ]
            },
            ...backendModule.axiosConfig
        }).then(res => res.data).catch(() => backendModule.genericError);
        adDataRef.current = adData.status === "ok" ? adData.data : [];

        if ([true, false].includes(filterByAdStatus)) {
            let activeAds = adDataRef.current.filter(a => a.AdStatus === "ACTIVE").map(a => a.AdID);
            if (filterByAdStatus) {
                filters.push({ and: [{ name: "IntegrationParams.stadid", op: "in", value: activeAds }] });
            } else {
                filters.push({ and: [{ name: "IntegrationParams.stadid", op: "notIn", value: activeAds }] });
            };
        };

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/campaigns/getTrackingStats`,
            data: {
                TableHeaders: [
                    "Date_IntegrationParams-stadid",
                    "Clicks_IN",
                    "CTR_IN",
                    "CR",
                    "AR",
                    "CPA",
                    "CPAO"
                ],
                filters: [
                    {
                        and: [
                            { name: "IntegrationParams.stadid", op: "neq", value: null },
                            { name: "IntegrationParams.stadid", op: "neq", value: "" }
                        ]
                    },
                    ...filters
                ],
                allUsers: false,
                IntegrationType: 0,
                trackGroupByDate: true,
                utcOffset: (new Date()).getTimezoneOffset(),
                skipIntegrationDataPull: false,
                _o_useDateHints: true,
            },
            ...backendModule.axiosConfig
        }).then(res => {
            setData(res.data);
        }).catch(() => {
            setData(backendModule.genericError);
        });
    };

    const performSort = (data, headers) => {
        let finalOrd = order;

        if (!finalOrd) finalOrd = {name: "Clicks", order: "desc"};
        if (!finalOrd?.name || !finalOrd?.order) finalOrd = {name: "Conversions", order: "desc"};
        if (finalOrd?.name === "Date" || finalOrd?.name === "Offer") return data;

        
        headers = headers ? headers : [];
        let curHeaderIndex = headers.indexOf(finalOrd.name);
        if (curHeaderIndex < 0) return data;

        const checkExists = item => item !== null && item !== undefined;
        const removeExtra = item => {
            let final = String(item).replace(/x/gmis, "").replace(/,/gmis, "").split(" ")[0];
            return Number(final);
        };
        return data.sort((a, b) => {
            if (
                (
                    !checkExists(a.columns[curHeaderIndex]?.text) ||
                    !checkExists(b.columns[curHeaderIndex]?.text)
                ) &&
                (
                    !checkExists(a.columns[curHeaderIndex]?.group?.[0]?.text) ||
                    !checkExists(b.columns[curHeaderIndex]?.group?.[0]?.text)
                )
            ) return -1;

            let a1 = null;
            let b1 = null;

            if (Array.isArray(a.columns[curHeaderIndex]?.group)) {
                a1 = removeExtra(a.columns[curHeaderIndex]?.group?.[0]?.text);
            } else {
                a1 = removeExtra(a.columns[curHeaderIndex].text);
            };
            if (Array.isArray(b.columns[curHeaderIndex]?.group)) {
                b1 = removeExtra(b.columns[curHeaderIndex]?.group?.[0]?.text);
            } else {
                b1 = removeExtra(b.columns[curHeaderIndex].text);
            };

            if (finalOrd.order === "asc") {
                if (+a1 > +b1) return 1; else return -1;
            } else {
                if (+b1 > +a1) return 1; else return -1;
            };
        });
    };

    React.useEffect(() => {
        if (data?.status !== "ok") return;

        getAdNames(getAdIDs());
    }, [data]);

    React.useEffect(() => {
        getData();
    }, [filterByAdStatus, filterByOffer, filterByDate]);

    React.useEffect(() => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/offers/getAllOffers`,
            data: {
                filters: [
                    { name: "isActive", op: "eq", value: true }
                ],
                limit: null
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                setAllOffers(res.data.data);
            } else {
                setAllOffers([]);
            };
        }).catch(() => {
            setAllOffers([]);
        });
    }, []);

    return <div className="route__user__creatives__fb">
        <div className="route__user__creatives__fb__filters">
            <AdvancedDropdown
                headline="Filter by ad status"
                data={[
                    { key: "any", name: "Any status", value: null },
                    { key: "active", name: "Only active", value: true },
                    { key: "inactive", name: "Only inactive", value: false }
                ]}
                onChange={e => filterByAdStatus !== e?.value && setFilterByAdStatus(e?.value)}
                selected={(() => {
                    switch (filterByAdStatus) {
                        case null: return 0;
                        case true: return 1;
                        case false: return 2;
                        default: return null;
                    };
                })()}
            />
            <AdvancedDropdown
                headline="Filter by offer"
                showSearch={true}
                data={[
                    { key: "all-all", name: "All offers", value: null },
                    ...(allOffers || []).map(offer => {
                        return { key: offer.ID, name: `${offer.OfferName} (${offer.OfferType ?? "-"}, ${offer.Country ?? "-"})`, value: offer.ID }
                    })
                ]}
                onChange={e => e?.value !== filterByOffer && setFilterByOffer(e?.value)}
                selected={(() => {
                    if (!allOffers) return null;
                    if (!filterByOffer) return 0;

                    return allOffers.indexOf(allOffers.find(o => o.ID === filterByOffer)) + 1;
                })()}
            />
            <FilterByDate
                disable24h={true}
                disableAll={true}
                defaultValue="today"
                onChange={e => setFilterByDate(e)}
            />
        </div>

        <FilteredCustomTable
            theme={themeSelector}
            accent="#6C5DD3"
            headers={["", "ID", "Name", "Status", "Clicks", "CTR", "CR", "AR", "CPA", "CPAO"]}
            customColumns={(new Array(9)).fill("max-content")}
            style={{ width: "100%", overflow: "auto", columnGap: "40px" }}
            data={(() => {
                if (!data) return [{ columns: [{ keyID: "noData-spinner", type: "spinner", color: themeSelector === "dark" ? "white" : "black" }] }];
                if (data.status !== "ok") return [{ columns: { keyID: "noData-error", type: "text", text: "Error while fetching data!", style: { color: themeSelector === "dark" ? basicStylesModule.errorColor : basicStylesModule.errorColorLight } } }];

                let out = [];
                let allAds = getAdIDs();
                for (let ad of allAds) {
                    out.push({
                        columns: [
                            { keyID: ad, type: "text", text: <UserCreatives_FB__adImage key={`adImage-${ad}`} id={ad} /> },
                            { keyID: ad, type: "text", text: ad },
                            { keyID: ad, type: "text", text: adNames[ad] ?? <Spinner style={{ width: "17px", height: "17px" }} color={themeSelector === "dark" ? "white" : "black"} /> },
                            { keyID: ad, type: "text", text: adDataRef.current.find(a => a.AdID === ad)?.AdStatus ?? "-" },
                            { keyID: ad, type: "text", text: getTableData("Clicks_IN", ad) },
                            { keyID: ad, type: "text", text: getTableData("CTR_IN", ad) },
                            { keyID: ad, type: "text", text: getTableData("CR", ad) },
                            { keyID: ad, type: "text", text: getTableData("AR", ad) },
                            { keyID: ad, type: "text", text: getTableData("CPA", ad) },
                            { keyID: ad, type: "text", text: getTableData("CPAO", ad) }
                        ]
                    })
                };
                if (out.length > 0) out = performSort(out, ["", "ID", "Name", "Status", "Clicks", "CTR", "CR", "AR", "CPA", "CPAO"]);
                if (out.length === 0) return [[{ keyID: "noData-noData", type: "text", text: "Nothing to show..." }]];
                return out;
            })()}
            canAnimate={false}
            noTimeout={true}
            orderCB={setOrder}
        />
    </div>
};

const UserCreatives_FB__adImage = props => {
    const [data, setData] = React.useState();
    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");

    React.useEffect(() => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/integrations/facebook/getAdImage`,
            data: {
                AdID: props.id
            },
            ...backendModule.axiosConfig
        }).then(res => {
            setData(res.data);
        }).catch(() => setData(backendModule.genericError));
    }, []);

    if (!data) return <Spinner style={{ width: "32px", height: "32px" }} color={themeSelector === "dark" ? "white" : "black"} />
    return <div>
        <img src={data?.status === "ok" ? data.data : "#"} style={{ width: "32px", height: "32px" }} onError={e => e.target.src = "/images/image-missing.png"} onClick={() => animateBox(<PreviewImageModal image={data?.status === "ok" ? data.data : "#"} />)} />
    </div>
};

export default UserCreatives_FB;