import React from "react";
import "./index.scss";

import axios from "axios";
import * as mathjs from "mathjs";
import { useSelector, useDispatch } from "react-redux";
import { renderMatches, useNavigate } from "react-router-dom";


import * as backendModule from "../../../../modules/backendModule";
import * as basicStylesModule from "../../../../modules/basicStylesModule";
import * as siteFunctionsActions from "../../../../actions/siteFunctionsActions";
import animateModule from "../.,./../../../../modules/animateModule";
import { animateBox } from "../../../../modules/componentAnimation";

import { FilteredCustomTable } from "../../../../components/customComponents/Table";
import StyledButton from "../../../../components/styledComponents/Button";
import Dropdown from "../../../../components/customComponents/Dropdown";
import Spinner from "../../../../components/customComponents/Spinner";
import Checkbox from "../../../../components/customComponents/Checkbox";

import YesNoModal from "../../../../components/modals/YesNoModal";

const math = mathjs.create(mathjs.all);
const limitedEvaluate = math.evaluate;

math.import({
  'import':     function () { throw new Error('Function import is disabled') },
  'createUnit': function () { throw new Error('Function createUnit is disabled') },
  'reviver':    function () { throw new Error('Function reviver is disabled') },

  'evaluate':   function () { throw new Error('Function evaluate is disabled') },
  'parse':      function () { throw new Error('Function parse is disabled') },
  'simplify':   function () { throw new Error('Function simplify is disabled') },
  'derivative': function () { throw new Error('Function derivative is disabled') },
  'resolve':    function () { throw new Error('Function resolve is disabled') },
}, { override: true });

const CustomReport = () => {
    const [data, setData] = React.useState();

    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");
    const curDispatch = useDispatch();
    const curNavigate = useNavigate();
    const animateNavigate = to => animateModule(curNavigate, to, document.querySelector(".root__content"));

    const tableButtonStyle = {
        height: "100%",
        margin: "0 auto",
        display: "inline-block",
        background: "transparent",
        border: "1px solid #5C7582B2",
        color: themeSelector === "dark" ? "white" : "black",
        padding: "0 10px",
        fontSize: "14px"
    };

    const getData = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/reports/custom/getAll`,
            data: {
                limit: null,
                offset: 0
            },
            ...backendModule.axiosConfig
        }).then(res => setData(res.data)).catch(() => setData(backendModule.genericError));
    };

    const removeReport = (item) => {
        animateBox(<YesNoModal
            heading="Remove report"
            text={[
                "Are you sure?",
                <br />,
                "Removal of ",
                <span style={{color: "rgb(108, 93, 211)"}}>{item.Name}</span>,
                " is permanent, and can't be recovered!"
            ]}
            buttonLeftText="No"
            buttonRightText="Yes"
            isRightButtonNormal={true}
            buttonRightCallback={args => {
                args.errorMessage("");
                args.spinner(true);
                args.disabledAll(true);

                axios({
                    method: "POST",
                    url: `${backendModule.backendURL}/reports/custom/remove`,
                    data: {
                        ID: item.ID
                    },
                    ...backendModule.axiosConfig
                }).then(res => {
                    if (res.data.status === "ok") {
                        args.close();
                        getData();
                    } else {
                        args.errorMessage("There was an error while removing the report");
                    };
                }).catch(() => {
                    args.errorMessage("Server timed out!");
                }).finally(() => {
                    args.spinner(false);
                    args.disabledAll(false);
                });
            }}
        />);
    };

    React.useEffect(() => {
        let handler = () => {
            getData();
        };

        curDispatch(siteFunctionsActions.addHeaderRefreshAction(handler));
        handler();

        return () => {
            curDispatch(siteFunctionsActions.removeHeaderRefreshAction(handler));
        };
    }, []);

    return <div className="routes__reports__custom">
        <div className="routes__reports__custom__head">
            <h1>Custom reports</h1>
            <StyledButton onClick={() => animateBox(<CustomReport_add onChange={getData} />)}>+ Add</StyledButton>
        </div>

        <FilteredCustomTable
            theme={themeSelector}
            accent="#6C5DD3"
            headers={["", "Report name"]}
            customColumns={["max-content", "max-content"]}
            style={{columnGap: "40px"}}
            data={(()=>{
                if (!data) return [[{keyID: "noData-spinner", type: "spinner", color: themeSelector === "dark" ? "white" : "black"}]];
                if (data.status === "error") return [[{keyID: "noData-error", type: "text", text: "Error while fetching data", style: {color: themeSelector === "dark" ? basicStylesModule.errorColor : basicStylesModule.errorColorLight}}]];

                let out = [];
                for (let item of data.data) {
                    out.push([
                        {keyID: item.ID, type: "custom", data: <>
                            <StyledButton style={{...tableButtonStyle}} onClick={() => animateNavigate(`/reports/custom/${item.ID}`)}>View</StyledButton>
                            <StyledButton style={{...tableButtonStyle, marginLeft: "10px"}} onClick={() => animateBox(<CustomReport_add edit={item} onChange={getData} />)}>Edit</StyledButton>
                            <StyledButton style={{...tableButtonStyle, marginLeft: "10px"}} onClick={() => removeReport(item)}>Remove</StyledButton>
                            <StyledButton style={{...tableButtonStyle, marginLeft: "10px"}} onClick={() => animateBox(<CustomReport_exportString ID={item.ID} />)}>Export</StyledButton>
                        </>},
                        {keyID: item.ID, type: "text", text: item.Name},
                    ])
                };

                if (out.length === 0) return [[{keyID: "noData-noData", type: "text", text: "Nothing to show..."}]];
                return out;
            })()}
        />
    </div>
};

const CustomReport_add = props => {
    const [dimensions, setDimensions] = React.useState([]);
    const [metrics, setMetrics] = React.useState([]);
    const [removals, setRemovals] = React.useState([]);
    const [customStyles, setCustomStyles] = React.useState([]);
    const [allUsers, setAllUsers] = React.useState(false);
    const [spinner, setSpinner] = React.useState(false);
    const [globalSpinner, setGlobalSpinner] = React.useState(false);
    const [infoP, setInfoP] = React.useState("");

    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");
    const customReportsSelector = useSelector(state => state?.types?.customReports ?? {});

    const reportNameRef = React.useRef();

    const dimension_moveDown = idx => {
        setDimensions(d => {
            let newIdx = idx + 1;
            if (newIdx > d.length-1) newIdx = d.length-1;
            let tmp = [...d];
            [tmp[idx], tmp[newIdx]] = [tmp[newIdx], tmp[idx]];
            return tmp;
        });
    };
    const dimension_moveUp = idx => {
        setDimensions(d => {
            let newIdx = idx - 1;
            if (newIdx < 0) newIdx = 0;
            let tmp = [...d];
            [tmp[idx], tmp[newIdx]] = [tmp[newIdx], tmp[idx]];
            return tmp;
        });
    };

    const metric_moveDown = idx => {
        setMetrics(d => {
            let newIdx = idx + 1;
            if (newIdx > d.length-1) newIdx = d.length-1;
            let tmp = [...d];
            [tmp[idx], tmp[newIdx]] = [tmp[newIdx], tmp[idx]];
            return tmp;
        });
    };
    const metric_moveUp = idx => {
        setMetrics(d => {
            let newIdx = idx - 1;
            if (newIdx < 0) newIdx = 0;
            let tmp = [...d];
            [tmp[idx], tmp[newIdx]] = [tmp[newIdx], tmp[idx]];
            return tmp;
        });
    };

    const customStyles_moveDown = idx => {
        setCustomStyles(d => {
            let newIdx = idx + 1;
            if (newIdx > d.length-1) newIdx = d.length-1;
            let tmp = [...d];
            [tmp[idx], tmp[newIdx]] = [tmp[newIdx], tmp[idx]];
            return tmp;
        });
    };
    const customStyles_moveUp = idx => {
        setCustomStyles(d => {
            let newIdx = idx - 1;
            if (newIdx < 0) newIdx = 0;
            let tmp = [...d];
            [tmp[idx], tmp[newIdx]] = [tmp[newIdx], tmp[idx]];
            return tmp;
        });
    };

    const addReport = () => {
        setInfoP("");

        if (!reportNameRef.current.value) return setInfoP("Report name can't be empty");
        if (dimensions.length === 0) return setInfoP("Add at least 1 dimension");
        if (metrics.length === 0) return setInfoP("Add at least 1 metric");

        let data = {
            Name: reportNameRef.current.value,
            Dimensions: dimensions,
            Metrics: metrics,
            Removals: removals,
            CustomStyles: customStyles,
            predefinedFilters: {
                Campaign: [{name: "lastTrackedAt", custom: "%%7days"}],
                Tracking: [{name: "createdAt", custom: "%%7days"}],
                allUsers   
            }
        };
        if (props.edit?.ID) data["ID"] = props.edit.ID;

        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/reports/custom/${props.edit ? "edit" : "add"}`,
            data,
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                if (typeof(props.onChange) === "function") props.onChange();
                props.onClose();
            } else {
                if (res.data.data === "ALREADY_EXISTS") return setInfoP("Report with this name already exists!");
                setInfoP(`There was an error while ${props.edit ? "editing" : "adding"} a report!`);
            };
        }).catch(() => {
            setInfoP("Server timed out!");
        }).finally(() => {
            setSpinner(false);
        });
    };

    const confirmClose = fn => {
        animateBox(<YesNoModal
            heading="Close"
            text="Are you sure? All unsaved changes will be lost!"
            buttonLeftText="No"
            isRightButtonNormal={true}
            buttonRightText="Yes"
            buttonRightCallback={args => {
                args.close();
                fn();
            }}
        />);
    };

    const performDataImport = e => {
        setMetrics([]);
        setDimensions([]);
        setRemovals([]);
        setCustomStyles([]);

        if (Array.isArray(e?.Metrics)) setMetrics(e.Metrics);
        if (Array.isArray(e?.Dimensions)) setDimensions(e.Dimensions);
        if (Array.isArray(e?.Removals)) setRemovals(e.Removals);
        if (Array.isArray(e?.CustomStyles)) setCustomStyles(e.CustomStyles);
        if (e?.predefinedFilters?.allUsers) setAllUsers(true);
    };

    React.useEffect(() => {
        if (!props.edit) return;

        setGlobalSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/reports/custom/getAll`,
            data: {
                limit: 1,
                offset: 0,
                filters: [
                    {name: "ID", op: "eq", value: props.edit.ID}
                ],
                extended: true
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                if (res.data.data.length === 1) {
                    setDimensions(res.data.data[0].Dimensions);
                    setMetrics(res.data.data[0].Metrics);
                    setRemovals(res.data.data[0].Removals);
                    setCustomStyles(res.data.data[0].CustomStyles);
                    setAllUsers(!!res.data.data[0]?.predefinedFilters?.allUsers);
                    return;
                };
            };
            props.onClose();
        }).catch(() => {
            props.onClose();
        }).finally(() => {
            setGlobalSpinner(false);
        });
    }, [props.edit]);

    return <div className="genericModal">
        <div className="genericModal__wrap" style={{width: "700px"}}>
            <div className="genericModal__wrap__spinner" style={{
                opacity: globalSpinner ? 1 : 0,
                pointerEvents: globalSpinner ? "all" : "none"
            }}>
                <Spinner style={{width: "32px", height: "32px", pointerEvents: "none"}} color={themeSelector === "dark" ? "white" : "black"} />
            </div>
            <div className="genericModal__wrap__head">
                <div className="genericModal__wrap__head__left">{props.edit ? "Edit" : "Add"} report</div>
                <div className="genericModal__wrap__head__right" style={{backgroundImage: `url("/images/icon_close.svg")`}} onClick={props.onClose}></div>
            </div>

            <div className="genericModal__wrap__input">
                <p>Report name</p>
                <input ref={reportNameRef} type="text" placeholder="Report name" defaultValue={props.edit?.Name ?? ""} />
            </div>

            <div className="genericModal__wrap__input" onClick={() => setAllUsers(u => !u)} style={{cursor: "pointer"}}>
                <p style={{display: "flex", alignItems: "center", gap: "5px"}}>
                    <Checkbox checked={allUsers} style={{pointerEvents: "none"}} />
                    <span>Allow filter by all users</span>
                </p>
            </div>

            <div className="genericModal__wrap__input">
                <h3>Dimensions</h3>
                <p style={{color: "gray"}}>What columns to use when grouping data. Order of the columns matter and can't repeat</p>
                {dimensions.map((d, dIndex) => {
                    return <div style={{display: "flex", alignItems: "center"}}>
                        <span style={{marginRight: "10px"}}>{customReportsSelector?.supportedDimensions?.[d]}</span>
                        {dIndex !== 0 && <StyledButton onClick={() => dimension_moveUp(dIndex)} isSecondary style={{padding: "5px 10px", width: "auto", height: "max-content", display: "flex", justifyContent: "center", alignItems: "center"}}>↑</StyledButton>}
                        {(dIndex !== dimensions.length-1) && <StyledButton onClick={() => dimension_moveDown(dIndex)} isSecondary style={{padding: "5px 10px", width: "auto", height: "max-content", display: "flex", justifyContent: "center", alignItems: "center"}}>↓</StyledButton>}
                        <StyledButton onClick={() => setDimensions(dd => dd.filter(ddf => ddf !== d))} isSecondary style={{padding: "5px 10px", width: "auto", height: "max-content", display: "flex", justifyContent: "center", alignItems: "center"}}>Remove</StyledButton>
                    </div>
                })}
                <StyledButton onClick={() => animateBox(<CustomReport_add_addDimension dimensions={dimensions} onChange={e => setDimensions(d => [...d, e])} />)}>+ Add dimension</StyledButton>
            </div>

            <div className="genericModal__wrap__input">
                <h3>Metrics</h3>
                <p style={{color: "gray"}}>Metrics that will be shown, they are grouped by dimensions above</p>
                {metrics.map((d, dIndex) => {
                    return <div style={{display: "flex", alignItems: "center"}}>
                        <span style={{marginRight: "10px"}}>{!d.Visible ? `[Hidden] ` : ""}{d.Name}</span>
                        {dIndex !== 0 && <StyledButton onClick={() => metric_moveUp(dIndex)} isSecondary style={{padding: "5px 10px", width: "auto", height: "max-content", display: "flex", justifyContent: "center", alignItems: "center"}}>↑</StyledButton>}
                        {(dIndex !== metrics.length-1) && <StyledButton onClick={() => metric_moveDown(dIndex)} isSecondary style={{padding: "5px 10px", width: "auto", height: "max-content", display: "flex", justifyContent: "center", alignItems: "center"}}>↓</StyledButton>}
                        <StyledButton onClick={() => animateBox(<CustomReport_add_addMetric
                            edit={d}
                            editIdx={dIndex}
                            columns={metrics}
                            onChange={e => setMetrics((rm) => [
                                ...rm.filter((_, rmfIdx) => rmfIdx < dIndex),
                                e,
                                ...rm.filter((_, rmfIdx) => rmfIdx > dIndex)
                            ])}
                        />)} isSecondary style={{padding: "5px 10px", width: "auto", height: "max-content", display: "flex", justifyContent: "center", alignItems: "center"}}>Edit</StyledButton>
                        <StyledButton onClick={() => setMetrics(dd => dd.filter(ddf => ddf !== d))} isSecondary style={{padding: "5px 10px", width: "auto", height: "max-content", display: "flex", justifyContent: "center", alignItems: "center"}}>Remove</StyledButton>
                    </div>
                })}
                <StyledButton onClick={() => animateBox(<CustomReport_add_addMetric columns={metrics} onChange={e => setMetrics(m => [...m, e])} />)}>+ Add metric</StyledButton>
            </div>

            <div className="genericModal__wrap__input">
                <h3>Removals</h3>
                <p style={{color: "gray"}}>What rows should be removed from the report. Useful when cleaning empty rows</p>
                {removals.map((d, dIndex) => {
                    return <div style={{display: "flex", alignItems: "center"}}>
                        <span style={{marginRight: "10px"}}>{d.Name.substring(0, 30)}{d.Name.length > 30 ? "..." : ""}</span>
                        <StyledButton onClick={() => animateBox(<CustomReport_add_addRemoval
                            metrics={metrics}
                            edit={d}
                            onChange={e => setRemovals((rm) => [
                                ...rm.filter((_, rmfIdx) => rmfIdx < dIndex),
                                e,
                                ...rm.filter((_, rmfIdx) => rmfIdx > dIndex)
                            ])}
                        />)} isSecondary style={{padding: "5px 10px", width: "auto", height: "max-content", display: "flex", justifyContent: "center", alignItems: "center"}}>Edit</StyledButton>
                        <StyledButton onClick={() => setRemovals(dd => dd.filter(ddf => ddf !== d))} isSecondary style={{padding: "5px 10px", width: "auto", height: "max-content", display: "flex", justifyContent: "center", alignItems: "center"}}>Remove</StyledButton>
                    </div>
                })}
                <StyledButton onClick={() => animateBox(<CustomReport_add_addRemoval metrics={metrics} onChange={e => setRemovals(r => [...r, e])} />)}>+ Add removal</StyledButton>
            </div>

            <div className="genericModal__wrap__input">
                <h3>Custom styles</h3>
                <p style={{color: "gray"}}>Styles that will be applied to the table if the expression is truthy. It can be applied to the whole row or just one column. Order matters, last one is always applied.</p>
                {customStyles.map((d, dIndex) => {
                    return <div style={{display: "flex", alignItems: "center"}}>
                        <span style={{marginRight: "10px"}}>[{`${d.Type.charAt(0).toUpperCase()}${d.Type.substring(1, d.Type.length)}-${Object.keys(d.Styles).length}`}] {d.Name}</span>
                        {dIndex !== 0 && <StyledButton onClick={() => customStyles_moveUp(dIndex)} isSecondary style={{padding: "5px 10px", width: "auto", height: "max-content", display: "flex", justifyContent: "center", alignItems: "center"}}>↑</StyledButton>}
                        {(dIndex !== customStyles.length-1) && <StyledButton onClick={() => customStyles_moveDown(dIndex)} isSecondary style={{padding: "5px 10px", width: "auto", height: "max-content", display: "flex", justifyContent: "center", alignItems: "center"}}>↓</StyledButton>}
                        <StyledButton onClick={() => animateBox(<CustomReport_add_addCustomStyle
                            edit={d}
                            editIdx={dIndex}
                            columns={metrics}
                            customStyles={customStyles}
                            onChange={e => setCustomStyles((rm) => [
                                ...rm.filter((_, rmfIdx) => rmfIdx < dIndex),
                                e,
                                ...rm.filter((_, rmfIdx) => rmfIdx > dIndex)
                            ])}
                        />)} isSecondary style={{padding: "5px 10px", width: "auto", height: "max-content", display: "flex", justifyContent: "center", alignItems: "center"}}>Edit</StyledButton>
                        <StyledButton onClick={() => setCustomStyles(dd => dd.filter(ddf => ddf !== d))} isSecondary style={{padding: "5px 10px", width: "auto", height: "max-content", display: "flex", justifyContent: "center", alignItems: "center"}}>Remove</StyledButton>
                    </div>
                })}
                <StyledButton onClick={() => animateBox(<CustomReport_add_addCustomStyle columns={metrics} customStyles={customStyles} onChange={e => setCustomStyles(m => [...m, e])} />)}>+ Add custom style</StyledButton>
            </div>

            <div className="genericModal__wrap__buttons">
                <div className="genericModal__wrap__buttons__btn genericModal__wrap__buttons__btn--secondary" style={{marginRight: "auto"}} onClick={() => animateBox(<CustomReport_importString onChange={performDataImport} />)}>Import</div>
                <div className="genericModal__wrap__buttons__btn genericModal__wrap__buttons__btn--secondary" onClick={() => confirmClose(props.onClose)}>Close</div>
                <div className="genericModal__wrap__buttons__btn" onClick={() => !spinner && addReport()}>
                    {spinner ? <Spinner style={{width: "17px", height: "17px"}} color={themeSelector === "dark" ? "white" : "black"} /> : (props.edit ? "Edit" : "Add")}
                </div>
            </div>

            {infoP && <div className="genericModal__wrap__infoP" style={{opacity: 1}}>{infoP}</div>}
        </div>
    </div>
};

const CustomReport_add_addDimension = props => {
    const [selectedDimension, setSelectedDimension] = React.useState();
    const [infoP, setInfoP] = React.useState("");

    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");
    const supportedDimensionsSelector = useSelector(state => state?.types?.customReports?.supportedDimensions ?? {});

    const addDimension = () => {
        setInfoP();

        if (!selectedDimension) return setInfoP("Dimension not selected");
        if (props.dimensions.find(d => d === selectedDimension)) return setInfoP("Dimension already exists.");
        if (typeof(props.onChange) === "function") {
            props.onChange(selectedDimension);
        };
        props.onClose();
    };

    return <div className="genericModal">
        <div className="genericModal__wrap">
            <div className="genericModal__wrap__head">
                <div className="genericModal__wrap__head__left">Add dimension</div>
                <div className="genericModal__wrap__head__right" style={{backgroundImage: `url("/images/icon_close.svg")`}} onClick={props.onClose}></div>
            </div>

            <div className="genericModal__wrap__input">
                <p>Select a dimension</p>
                <Dropdown
                    theme={themeSelector}
                    accent="#6C5DD3"
                    data={Object.keys(supportedDimensionsSelector).map(key => {
                        return {name: supportedDimensionsSelector[key], value: key}
                    })}
                    onChange={e => setSelectedDimension(e?.value)}
                />
            </div>

            <div className="genericModal__wrap__buttons">
                <div className="genericModal__wrap__buttons__btn genericModal__wrap__buttons__btn--secondary" onClick={props.onClose}>Cancel</div>
                <div className="genericModal__wrap__buttons__btn" onClick={addDimension}>Add</div>
            </div>

            {infoP && <div className="genericModal__wrap__infoP" style={{opacity: 1}}>{infoP}</div>}
        </div>
    </div>
};

const CustomReport_add_addMetric = props => {
    const [metricType, setMetricType] = React.useState();
    const [metricIncludes, setMetricIncludes] = React.useState();
    const [metricColumn, setMetricColumn] = React.useState();
    const [metricRequiredColumns, setMetricRequiredColumns] = React.useState([]);
    const [metricVisible, setMetricVisible] = React.useState();
    const [infoP, setInfoP] = React.useState("");

    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");
    const supportedColumnsSelector = useSelector(state => state?.types?.customReports?.supportedColumns ?? {});

    const metricNameRef = React.useRef();
    const metricDecimalPlacesRef = React.useRef();
    const metricSuffixRef = React.useRef();
    const metricExpressionRef = React.useRef();

    const addMetric = () => {
        let data = {
            Name: metricNameRef.current?.value || undefined,
            Type: metricType,
            Include: metricIncludes,
            Column: metricColumn,
            DecimalPlaces: metricDecimalPlacesRef.current?.value || undefined,
            Suffix: metricSuffixRef.current?.value || undefined,
            Expression: metricExpressionRef.current?.value || undefined,
            RequiredColumns: metricRequiredColumns,
            Visible: !!metricVisible
        };

        setInfoP();
        if (!data.Name) return setInfoP("Metric name can't be empty");
        if (!data.Type) return setInfoP("Metric type not specified");

        if (data.DecimalPlaces) {
            data.DecimalPlaces = Number(data.DecimalPlaces);
            if (isNaN(data.DecimalPlaces) || data.DecimalPlaces < 0 || data.DecimalPlaces > 3) return setInfoP("Decimal places must be a number between 0 and 3");
        } else {
            data.DecimalPlaces = 0;
        };

        if (data.Type !== "custom") {
            delete data["Expression"];
            delete data["RequiredColumns"];

            if (!data.Include) return setInfoP("Included data not specified");
            if (!data.Column) return setInfoP("Data column not specified");
        } else {
            delete data["Includes"];
            delete data["Column"];

            if (!data.Expression) return setInfoP("Expression can't be empty");
            if (!Array.isArray(data.RequiredColumns)) data.RequiredColumns = [];

            let scope = {};
            for (let m of data.RequiredColumns) scope[m.As] = Math.floor(Math.random() * 100);
            try {
                let finalExp = limitedEvaluate(data["Expression"], scope);
                if (Array.isArray(finalExp?.entries)) finalExp = finalExp.entries.pop();
            } catch (e) {
                return setInfoP([
                    "Error while evaluating the expression",
                    <br />,
                    String(e)
                        .replace("Error: ", "")
                        .replace("Undefined symbol", "Included column not found -> ")
                ]);
            };
        };

        let foundMetric = props.columns.find(col => col.Name === data.Name);
        if (foundMetric) {
            if (props.edit) {
                if (props.editIdx !== props.columns.indexOf(foundMetric)) return setInfoP(`Metric ${data.Name} already exists!`);
            } else {
                return setInfoP(`Metric ${data.Name} already exists!`);
            };
        };

        if (typeof(props.onChange) === "function") props.onChange(data);
        props.onClose();
    };

    React.useEffect(() => {
        if (props.edit) {
            setMetricType(props.edit?.Type ?? "sum");
            setMetricIncludes(props.edit?.Include ?? "all");
            setMetricColumn(props.edit?.Column ?? undefined);
            setMetricRequiredColumns(props.edit?.RequiredColumns ?? []);
            setMetricVisible(!!props.edit?.Visible);
        } else {
            setMetricType("sum");
            setMetricIncludes("all");
            setMetricVisible(true);
        };
    }, [props.edit]);

    return <div className="genericModal">
        <div className="genericModal__wrap">
            <div className="genericModal__wrap__head">
                <div className="genericModal__wrap__head__left">{props.edit ? "Edit" : "Add"} metric</div>
                <div className="genericModal__wrap__head__right" style={{backgroundImage: `url("/images/icon_close.svg")`}} onClick={props.onClose}></div>
            </div>

            <div className="genericModal__wrap__input">
                <p>Metric name (used for display and removals)</p>
                <input ref={metricNameRef} type="text" placeholder="Metric name" defaultValue={props.edit?.Name ?? ""} />
            </div>
            <div className="genericModal__wrap__input">
                <p>Type</p>
                <Dropdown
                    theme={themeSelector}
                    accent="#6C5DD3"
                    inlinePlaceholder="Metric includes"
                    data={[
                        {name: "Sum", value: "sum"},
                        {name: "Average", value: "avg"},
                        {name: "Minimum", value: "min"},
                        {name: "Maximum", value: "max"},
                        {name: "Custom expression", value: "custom"}
                    ]}
                    onChange={e => setMetricType(e?.value)}
                    selected={(()=>{
                        if (!metricType) return null;

                        switch (metricType) {
                            case "sum": return 0;
                            case "avg": return 1;
                            case "min": return 2;
                            case "max": return 3;
                            case "custom": return 4;
                            default: return null;
                        };
                    })()}
                />
            </div>
            {metricType !== "custom" && <div className="genericModal__wrap__input">
                <p>Column</p>                
                <Dropdown
                    theme={themeSelector}
                    accent="#6C5DD3"
                    inlinePlaceholder="Column"
                    data={supportedColumnsSelector.map(c => {
                        return {name: c, value: c};
                    })}
                    onChange={e => setMetricColumn(e?.value)}
                    selected={(()=>{
                        if (!metricColumn) return null;
                        return supportedColumnsSelector.indexOf(metricColumn);
                    })()}
                />
            </div>}
            {metricType !== "custom" && <div className="genericModal__wrap__input">
                <p>Included data (from where to fetch)</p>
                <Dropdown
                    theme={themeSelector}
                    accent="#6C5DD3"
                    inlinePlaceholder="Metric type"
                    data={[
                        {name: "All data", value: "all"},
                        {name: "Scale-Track only", value: "st"},
                        {name: "Integration only", value: "in"}
                    ]}
                    onChange={e => setMetricIncludes(e?.value)}
                    selected={(()=>{
                        if (!metricIncludes) return null;

                        switch (metricIncludes) {
                            case "all": return 0;
                            case "st": return 1;
                            case "in": return 2;
                            default: return null;
                        };
                    })()}
                />
            </div>}

            {metricType === "custom" && <hr style={{marginBottom: "10px"}} />}
            {metricType === "custom" && <div className="genericModal__wrap__input genericModal__wrap__input--text">
                <p>Expression (use aggregation, columns are given as array. eg: sum(Visits))</p>
                <textarea ref={metricExpressionRef} placeholder="Custom expression" defaultValue={props.edit?.Expression ?? ""} />
            </div>}
            {metricType === "custom" && <div className="genericModal__wrap__input">
                <p>
                    <span>Included columns in expression</span>
                    <StyledButton style={{height: "100%", padding: "5px 10px", marginLeft: "10px"}} onClick={() => animateBox(<CustomReport_add_addMetric_addColumn columns={metricRequiredColumns} onChange={e => setMetricRequiredColumns(mrc => [...mrc, e])} />)}>Add</StyledButton>
                </p>
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "5px",
                    paddingLeft: "10px",
                    borderLeft: "1px solid white"
                }}>
                    {metricRequiredColumns.map((mrc, mrcrIdx) => {
                        return <p style={{display: "flex", alignItems: "center", gap: "5px"}}>
                            <span>{mrc.Name} ({mrc.Include.toUpperCase()}){mrc.As !== mrc.Name ? ` -> as ${mrc.As}` : ""}</span>
                            <StyledButton onClick={() => animateBox(<CustomReport_add_addMetric_addColumn editIdx={mrcrIdx} columns={metricRequiredColumns} edit={mrc} onChange={e => setMetricRequiredColumns((mrcr) => [
                                ...mrcr.filter((_, mrcrrIdx) => mrcrrIdx < mrcrIdx),
                                e,
                                ...mrcr.filter((_, mrcrrIdx) => mrcrrIdx > mrcrIdx)
                            ])} />)} isSecondary style={{padding: "5px 10px", width: "auto", height: "max-content", display: "flex", justifyContent: "center", alignItems: "center"}}>Edit</StyledButton>
                            <StyledButton onClick={() => setMetricRequiredColumns(dd => dd.filter(ddf => ddf !== mrc))} isSecondary style={{padding: "5px 10px", width: "auto", height: "max-content", display: "flex", justifyContent: "center", alignItems: "center"}}>Remove</StyledButton>
                        </p>
                    })}
                </div>
            </div>}
            {metricType === "custom" && <hr style={{marginBottom: "10px"}} />}

            <div className="genericModal__wrap__input">
                <p>Decimal places (after '.')</p>
                <input ref={metricDecimalPlacesRef} type="text" placeholder="Decimal places" defaultValue={props.edit?.DecimalPlaces ?? 0} />
            </div>
            <div className="genericModal__wrap__input">
                <p>Value suffix (optional)</p>
                <input ref={metricSuffixRef} type="text" placeholder="Value suffix" defaultValue={props.edit?.Suffix ?? ""} />
            </div>

            <div className="genericModal__wrap__input" onClick={() => setMetricVisible(m => !m)} style={{cursor: "pointer"}}>
                <p style={{display: "flex", alignItems: "center", gap: "5px"}}>
                    <Checkbox checked={metricVisible} style={{pointerEvents: "none"}} />
                    <span>Metric visible</span>
                </p>
            </div>

            <div className="genericModal__wrap__buttons">
                <div className="genericModal__wrap__buttons__btn genericModal__wrap__buttons__btn--secondary" onClick={props.onClose}>Cancel</div>
                <div className="genericModal__wrap__buttons__btn" onClick={addMetric}>{props.edit ? "Edit" : "Add"}</div>
            </div>

            {infoP && <div className="genericModal__wrap__infoP" style={{opacity: 1}}>{infoP}</div>}
        </div>
    </div>
};

const CustomReport_add_addMetric_addColumn = props => {
    const [metricColumn, setMetricColumn] = React.useState();
    const [metricIncludes, setMetricIncludes] = React.useState("all");
    const [infoP, setInfoP] = React.useState("");

    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");
    const supportedColumnsSelector = useSelector(state => state?.types?.customReports?.supportedColumns ?? {});
    const metricAsRef = React.useRef();

    const addColumn = () => {
        setInfoP("");

        if (!metricColumn) return setInfoP("Column can't be empty");
        if (!metricIncludes) return setInfoP("Metric inclusion not selected");

        let data = {
            Name: metricColumn,
            Include: metricIncludes,
            As: metricAsRef.current.value
        };
        if (!data.As) data.As = data.Name;
        let tmp = props.columns.find(col => col.As === data.As);
        if (tmp) {
            if (props.edit) {
                if (props.editIdx !== props.columns.indexOf(tmp)) return setInfoP("Column name cant appear twice (use 'As' text to change the column name)");
            } else {
                return setInfoP("Column name cant appear twice (use 'As' text to change the column name)");
            };
        };

        if (typeof(props.onChange) === "function") props.onChange(data);
        props.onClose();
    };

    React.useEffect(() => {
        if (props.edit) {
            setMetricColumn(props.edit.Name);
            setMetricIncludes(props.edit.Include);
        };
    }, [props.edit]);

    return <div className="genericModal">
        <div className="genericModal__wrap">
            <div className="genericModal__wrap__head">
                <div className="genericModal__wrap__head__left">{props.edit ? "Edit" : "Add"} column</div>
                <div className="genericModal__wrap__head__right" style={{backgroundImage: `url("/images/icon_close.svg")`}} onClick={props.onClose}></div>
            </div>

            <div className="genericModal__wrap__input">
                <p>Column</p>
                <Dropdown
                    theme={themeSelector}
                    accent="#6C5DD3"
                    data={supportedColumnsSelector.map(sc => {
                        return {name: sc, value: sc}
                    })}
                    onChange={e => setMetricColumn(e?.value)}
                    selected={((()=>{
                        if (!metricColumn) return null;

                        return supportedColumnsSelector.indexOf(metricColumn);
                    })())}
                />
            </div>
            <div className="genericModal__wrap__input">
                <p>Included data (from where to fetch)</p>
                <Dropdown
                    theme={themeSelector}
                    accent="#6C5DD3"
                    inlinePlaceholder="Metric includes"
                    data={[
                        {name: "All data", value: "all"},
                        {name: "Scale-Track only", value: "st"},
                        {name: "Integration only", value: "in"}
                    ]}
                    onChange={e => setMetricIncludes(e?.value)}
                    selected={(()=>{
                        if (!metricIncludes) return null;

                        switch (metricIncludes) {
                            case "all": return 0;
                            case "st": return 1;
                            case "in": return 2;
                            default: return null;
                        };
                    })()}
                />
            </div>
            <div className="genericModal__wrap__input">
                <p>As name (optional) (used in expressions)</p>
                <input ref={metricAsRef} type="text" placeholder="As..." defaultValue={props.edit?.As ?? ""} />
            </div>

            <div className="genericModal__wrap__buttons">
                <div className="genericModal__wrap__buttons__btn genericModal__wrap__buttons__btn--secondary" onClick={props.onClose}>Cancel</div>
                <div className="genericModal__wrap__buttons__btn" onClick={addColumn}>{props.edit ? "Edit" : "Add"}</div>
            </div>

            {infoP && <div className="genericModal__wrap__infoP" style={{opacity: 1}}>{infoP}</div>}
        </div>
    </div>
};

const CustomReport_add_addRemoval = props => {
    const [removalType, setRemovalType] = React.useState("column");
    const [removalName, setRemovalName] = React.useState("");
    const [infoP, setInfoP] = React.useState("");

    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");
    const supportedColumnsSelector = useSelector(state => state?.types?.customReports?.supportedColumns ?? {});

    const addRemoval = () => {
        setInfoP("");

        if (!removalName) {
            if (removalType === "column") return setInfoP("Column not selected");
            return setInfoP("Expression missing");
        };
        if (removalType === "column") {
            if (!supportedColumnsSelector.includes(removalName)) return setInfoP("Invalid column selected");
            if (!props.metrics.find(m => m.Name === removalName)) return setInfoP(`Metric not found ${removalName}`);
        } else {
            let scope = {};
            for (let m of props.metrics) scope[m.Name] = Math.floor(Math.random() * 100);
            try {
                let out = limitedEvaluate(removalName, scope);
                if (Array.isArray(out?.entries)) out = out.entries.pop();
                if (typeof(!!out) !== "boolean") return setInfoP("Expression must return a boolean-like value.");
            } catch (e) {
                return setInfoP([
                    "Error while evaluating the expression",
                    <br />,
                    String(e)
                        .replace("Error: ", "")
                        .replace("Undefined symbol", "Metric not found")
                ]);
            };
        };

        if (typeof(props.onChange) === "function") {
            props.onChange({Name: removalName, Type: removalType});
        };
        props.onClose();
    };

    React.useEffect(() => {
        if (props.edit) {
            setRemovalName(props.edit.Name);
            setRemovalType(props.edit.Type);
        };
    }, [props.edit]);

    return <div className="genericModal">
        <div className="genericModal__wrap">
            <div className="genericModal__wrap__head">
                <div className="genericModal__wrap__head__left">{props.edit ? "Edit" : "Add"} removal</div>
                <div className="genericModal__wrap__head__right" style={{backgroundImage: `url("/images/icon_close.svg")`}} onClick={props.onClose}></div>
            </div>

            <div className="genericModal__wrap__input">
                <p>Removal type</p>
                <Dropdown
                    theme={themeSelector}
                    accent="#6C5DD3"
                    data={[
                        {value: "column", name: "By column"},
                        {value: "custom", name: "By custom expression"}
                    ]}
                    selected={removalType === "column" ? 0 : 1}
                    onChange={e => {
                        setRemovalType(e?.value);
                        if (props.edit) {
                            if (e?.value !== props.edit.Type && removalName !== props.edit.Name) setRemovalName("");
                        } else {
                            if (removalName) setRemovalName("");
                        };
                    }}
                />
            </div>

            {removalType === "column" && <div className="genericModal__wrap__input">
                <p>Column</p>    
                <Dropdown
                    theme={themeSelector}
                    accent="#6C5DD3"
                    data={supportedColumnsSelector.map(sc => {
                        return {name: sc, value: sc}
                    })}
                    selected={(()=>{
                        if (!removalName) return null;

                        return supportedColumnsSelector.indexOf(removalName);
                    })()}
                    onChange={e => setRemovalName(e?.value)}
                />
            </div>}

            {removalType === "custom" && <div className="genericModal__wrap__input genericModal__wrap__input--text">
                <p>
                    <span>Enter the expression below. No need for aggregation.</span><br />
                    <span>Example: Visits &gt; 0 or (Impressions / Reach) != 1...etc</span><br />
                    <span>Make sure that the column is first added as a metric before creating a removal.</span><br />
                    <span>If you plan on using strings, check if the input is a number first - otherwise it will not allow you to save.</span>
                </p>
                <textarea placeholder="Expression" value={removalName} onChange={e => setRemovalName(e.target.value)} />
            </div>}

            <div className="genericModal__wrap__buttons">
                <div className="genericModal__wrap__buttons__btn genericModal__wrap__buttons__btn--secondary" onClick={props.onClose}>Cancel</div>
                <div className="genericModal__wrap__buttons__btn" onClick={addRemoval}>{props.edit ? "Edit" : "Add"}</div>
            </div>

            {infoP && <div className="genericModal__wrap__infoP" style={{opacity: 1}}>{infoP}</div>}
        </div>
    </div>
};

const CustomReport_add_addCustomStyle = props => {
    const [styleType, setStyleType] = React.useState();
    const [styleColumn, setStyleColumn] = React.useState();
    const [styles, setStyles] = React.useState([]);
    const [infoP, setInfoP] = React.useState("");
    
    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");
    const styleNameRef = React.useRef();
    const expressionRef = React.useRef();

    React.useEffect(() => {
        if (props.edit) {
            setStyleType(props.edit.Type);
            setStyleColumn(props.edit.Column);
            setStyles([
                ...Object.keys(props.edit.Styles).map(s => {
                    return {name: s, value: props.edit.Styles[s]};
                }),
                {name: "", value: ""}
            ]);
        } else {
            setStyleType("column");
            setStyleColumn(undefined);
            setStyles([{name: "", value: ""}]);
        };
    }, [props.edit]);

    const addCustomStyle = () => {
        setInfoP("");

        let data = {
            Name: styleNameRef.current.value,
            Expression: expressionRef.current.value,
            Type: styleType,
            Column: styleColumn,
            Styles: styles.filter(s => s.name && s.value).reduce((acc, val) => {
                acc[val.name] = val.value;
                return acc;
            }, {})
        };
        if (data.Type === "row") delete data["Column"];

        if (!data.Name) return setInfoP("Style name can't be empty");
        if (!data.Expression) return setInfoP("Expression can't be empty");
        if (!data.Type) return setInfoP("Type not selected");
        if (Object.keys(data.Styles).length === 0) return setInfoP("Add at least 1 style");

        let scope = {};
        for (let m of props.columns) scope[m.Name] = Math.floor(Math.random() * 100);
        try {
            let out = limitedEvaluate(data.Expression, scope);
            if (Array.isArray(out?.entries)) out = out.entries.pop();
            if (typeof(!!out) !== "boolean") return setInfoP("Expression must return a boolean-like value.");
        } catch (e) {
            return setInfoP([
                "Error while evaluating the expression",
                <br />,
                String(e)
                    .replace("Error: ", "")
                    .replace("Undefined symbol", "Metric not found")
            ]);
        };

        if (props.edit) {
            let foundStyle = props.customStyles.find(s => s.Name === data.Name);
            if (foundStyle) {
                if (props.customStyles.indexOf(foundStyle) !== props.editIdx) return setInfoP("Style with this name already exists");
            };
        } else {
            if (props.customStyles.find(s => s.Name === data.Name)) return setInfoP("Style with this name already exists");
        };

        if (typeof(props.onChange) === "function") props.onChange(data);
        props.onClose();
    };

    return <div className="genericModal">
        <div className="genericModal__wrap">
            <div className="genericModal__wrap__head">
                <div className="genericModal__wrap__head__left">{props.edit ? "Edit" : "Add"} custom styles</div>
                <div className="genericModal__wrap__head__right" style={{backgroundImage: `url("/images/icon_close.svg")`}} onClick={props.onClose}></div>
            </div>

            <div className="genericModal__wrap__input">
                <p>Style name</p>
                <input ref={styleNameRef} type="text" placeholder="Style name" defaultValue={props.edit?.Name ?? ""} />
            </div>
            <div className="genericModal__wrap__input">
                <p>Type</p>
                <Dropdown
                    theme={themeSelector}
                    accent="#6C5DD3"
                    data={[
                        {name: "Column", value: "column"},
                        {name: "Whole row", value: "row"}
                    ]}
                    onChange={e => setStyleType(e?.value)}
                    selected={((()=>{
                        if (!styleType) return null;

                        switch (styleType) {
                            case "column": return 0;
                            case "row": return 1;
                            default: break;
                        };
                    })())}
                />
            </div>
            {styleType === "column" && <div className="genericModal__wrap__input">
                <p>Column</p>
                <Dropdown
                    theme={themeSelector}
                    accent="#6C5DD3"
                    data={props.columns.map(col => {
                        return {name: col.Name, value: col.Name};
                    })}
                    onChange={e => setStyleColumn(e?.value)}
                    selected={(()=>{
                        if (!styleColumn) return null;

                        return props.columns.indexOf(props.columns.find(col => col.Name === styleColumn));
                    })()}
                />
            </div>}

            <div className="genericModal__wrap__input genericModal__wrap__input--text">
                <p>Expression (anything that will take the metric already defined and return a truthy / falsy value)</p>
                <textarea ref={expressionRef} placeholder="eg: my_value > 50" defaultValue={props.edit?.Expression ?? ""}></textarea>
            </div>

            <div className="genericModal__wrap__input">
                <p>
                    <span>Styles that will be applied (React-like CSS)</span><br />
                    <span>(instead of 'background-color' use 'backgroundColor'...etc)</span>
                </p>

                <div className="genericModal__wrap__input genericModal__wrap__input--split">
                    {styles.map((s, sIdx) => {
                        return <>
                            <input
                                type="text"
                                placeholder="Name"
                                value={s.name}
                                onChange={e => {
                                    let val = e.target.value;
                                    setStyles(ss => {
                                        let tmp = [...ss].map(st => {return {...st}});
                                        tmp[sIdx].name = val;
                                        tmp = tmp.filter(tt => tt.name || tt.value);
                                        tmp.push({name: "", value: ""});
                                        return tmp;
                                    });
                                }}
                            />
                            <input
                                type="text"
                                placeholder="Value"
                                value={s.value}
                                onChange={e => {
                                    let val = e.target.value;
                                    setStyles(ss => {
                                        let tmp = [...ss].map(st => {return {...st}});
                                        tmp[sIdx].value = val;
                                        tmp = tmp.filter(tt => tt.name || tt.value);
                                        tmp.push({name: "", value: ""});
                                        return tmp;
                                    });
                                }}
                            />
                        </>;
                    })}
                </div>
            </div>

            <div className="genericModal__wrap__buttons">
                <div className="genericModal__wrap__buttons__btn genericModal__wrap__buttons__btn--secondary" onClick={props.onClose}>Cancel</div>
                <div className="genericModal__wrap__buttons__btn" onClick={addCustomStyle}>{props.edit ? "Edit" : "Add"}</div>
            </div>

            {infoP && <div className="genericModal__wrap__infoP" style={{opacity: 1}}>{infoP}</div>}
        </div>
    </div>
};

const CustomReport_exportString = props => {
    const [data, setData] = React.useState();

    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");

    React.useEffect(() => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/reports/custom/getExportString`,
            data: {
                ID: props.ID
            },
            ...backendModule.axiosConfig
        }).then(res => setData(res.data)).catch(() => setData(backendModule.genericError));
    }, []);

    return <div className="genericModal">
        <div className="genericModal__wrap">
            <div className="genericModal__wrap__head">
                <div className="genericModal__wrap__head__left">Export string</div>
                <div className="genericModal__wrap__head__right" style={{backgroundImage: `url("/images/icon_close.svg")`}} onClick={props.onClose}></div>
            </div>

            {!data && <Spinner style={{width: "17px", height: "17px"}} color={themeSelector === "dark" ? "white" : "black"} />}
            {data?.status === "error" && <p>There was an error while generating the export string!</p>}
            {data?.status === "ok" && <div className="genericModal__wrap__input genericModal__wrap__input--text">
                <p>Copy and share the code below with your team</p>
                <textarea readOnly value={data.data}></textarea>

                <StyledButton onClick={() => navigator.clipboard.writeText(data.data)}>Copy to clipboard</StyledButton>
            </div>}
        </div>
    </div>
};

const CustomReport_importString = props => {
    const [infoP, setInfoP] = React.useState();
    const [spinner, setSpinner] = React.useState(false);

    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");
    const textRef = React.useRef();

    const importReport = () => {
        if (spinner) return;
        setInfoP("");

        let val = textRef.current.value;
        if (!val) return setInfoP("Import string can't be empty");

        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/reports/custom/getImportObject`,
            data: {
                ImportString: val
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                if (typeof(props.onChange) === "function") props.onChange(res.data.data);
                props.onClose();
            } else {
                setInfoP("Import string is not valid!");
            };
        }).catch(() => {
            setInfoP("Server timed out!");
        }).finally(() => {
            setSpinner(false);
        });
    };

    return <div className="genericModal">
        <div className="genericModal__wrap">
            <div className="genericModal__wrap__head">
                <div className="genericModal__wrap__head__left">Import</div>
                <div className="genericModal__wrap__head__right" style={{backgroundImage: `url("/images/icon_close.svg")`}} onClick={props.onClose}></div>
            </div>

            <div className="genericModal__wrap__input genericModal__wrap__input--text">
                <p>
                    <span>Enter the import string below</span>
                </p>
                <textarea ref={textRef} placeholder="Import string"></textarea>
            </div>

            <div className="genericModal__wrap__buttons">
                <div className="genericModal__wrap__buttons__btn genericModal__wrap__buttons__btn--secondary" onClick={props.onClose}>Cancel</div>
                <div className="genericModal__wrap__buttons__btn" onClick={importReport}>
                    {spinner ? <Spinner style={{width: "17px", height: "17px"}} color={themeSelector === "dark" ? "white" : "black"} /> : "Import"}
                </div>
            </div>

            {infoP && <div className="genericModal__wrap__infoP" style={{opacity: 1}}>{infoP}</div>}
        </div>
    </div>
};

export default CustomReport;