import React from "react";
import "./index.scss";

import axios from "axios";
import * as mathjs from "mathjs";
import { useSelector } from "react-redux";

import * as backendModule from "../../../modules/backendModule";
import * as basicStylesModule from "../../../modules/basicStylesModule";
import { animateBox } from "../../../modules/componentAnimation";

import { FilteredCustomTable } from "../../../components/customComponents/Table";
import StyledButton from "../../styledComponents/Button";
import Dropdown from "../../customComponents/Dropdown";
import Spinner from "../../customComponents/Spinner";

import YesNoModal from "../../../components/modals/YesNoModal";

const math = mathjs.create(mathjs.all);
const limitedEvaluate = math.evaluate;

math.import({
  'import':     function () { throw new Error('Function import is disabled') },
  'createUnit': function () { throw new Error('Function createUnit is disabled') },
  'reviver':    function () { throw new Error('Function reviver is disabled') },

  'evaluate':   function () { throw new Error('Function evaluate is disabled') },
  'parse':      function () { throw new Error('Function parse is disabled') },
  'simplify':   function () { throw new Error('Function simplify is disabled') },
  'derivative': function () { throw new Error('Function derivative is disabled') },
  'resolve':    function () { throw new Error('Function resolve is disabled') },
}, { override: true });

const CampaignCustomColumnsModal = (props) => {
    const [data, setData] = React.useState();

    const wrapRef = React.useRef();
    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");

    const tableButtonStyle = {
        height: "100%",
        margin: "0 auto",
        display: "inline-block",
        background: "transparent",
        border: "1px solid #5C7582B2",
        color: themeSelector === "dark" ? "white" : "black",
        padding: "0 10px",
        fontSize: "14px"
    };

    const onClose = () => {
        if (props.onChange) props.onChange();

        if (wrapRef.current) {
            wrapRef.current.animate([
                { right: getComputedStyle(wrapRef.current).right },
                { right: "-100%" }
            ], {
                duration: 300,
                iterations: 1,
                fill: "both",
                easing: "ease"
            });
        };

        props.onClose();
    };

    const getData = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/campaigns/customColumns/getAll`,
            data: {
                IntegrationType: props.integrationType,
                extended: true
            },
            ...backendModule.axiosConfig
        }).then(res => setData(res.data)).catch(() => setData(backendModule.genericError));
    };

    const removeColumn = (item) => {
        animateBox(<YesNoModal
            heading="Are you sure?"
            text={[`Are you sure that you want to remove ${item.Name}?`, <br/>, "This action is irreversible!"]}
            buttonLeftText="No"
            buttonRightText="Yes"
            isRightButtonNormal={true}
            buttonRightCallback={args => {
                args.errorMessage("");
                args.spinner(true);
                args.disabledAll(true);

                axios({
                    method: "POST",
                    url: `${backendModule.backendURL}/campaigns/customColumns/remove`,
                    data: {
                        ID: item.ID
                    },
                    ...backendModule.axiosConfig
                }).then(res => {
                    if (res.data.status === "ok") {
                        if (typeof(props.onChange) === "function") props.onChange();
                        args.close();
                        getData();
                    } else {
                        args.errorMessage("Error while removing the column, please try again later.");
                    };
                }).catch(() => {
                    args.errorMessage("Server timed out!");
                }).finally(() => {
                    args.spinner(false);
                    args.disabledAll(false);
                });
            }}
        />);
    };

    React.useEffect(() => {
        if (!wrapRef.current) return;

        wrapRef.current.animate([
            { right: getComputedStyle(wrapRef.current).right },
            { right: 0 }
        ], {
            duration: 300,
            iterations: 1,
            fill: "both",
            easing: "ease"
        });
    }, [wrapRef.current]);

    React.useEffect(() => {
        getData();
    }, []);

    return <div className="modals__campaignCustomColumns" onClick={onClose}>
        <div className="modals__campaignCustomColumns__wrap" ref={wrapRef} onClick={e => e?.stopPropagation()}>
            <div className="modals__campaignCustomColumns__wrap__top">
                <div className="modals__campaignCustomColumns__wrap__top__left">Custom columns</div>

                <StyledButton style={{marginLeft: "auto", marginRight: "10px", height: "100%"}} onClick={() => {
                    animateBox(<CampaignCustomColumnsModal_add columns={props.columns} onChange={() => {
                        if (typeof(props.onChange) === "function") props.onChange();
                        getData();
                    }} integrationType={props.integrationType} />);
                }}>+ Add</StyledButton>

                <div className="modals__campaignCustomColumns__wrap__top__right" onClick={props.onClose} style={{ backgroundImage: `url("/images/icon_close.svg")` }}></div>
            </div>

            <div className="modals__campaignCustomColumns__wrap__content">
                <FilteredCustomTable
                    accent="#6C5DD3"
                    theme={themeSelector}
                    headers={["Column name", "Expression", ""]}
                    customColumns={(new Array(3)).fill("max-content")}
                    style={{columnGap: "40px", overflowX: "auto"}}
                    data={(()=>{
                        if (!data) return [[{keyID: "noData-spinner", type: "spinner", color: themeSelector === "dark" ? "white" : "black"}]];
                        if (data.status === "error") return [[{keyID: "noData-error", type: "text", text: "Error while fetching columns", style: {color: themeSelector === "dark" ? basicStylesModule.errorColor : basicStylesModule.errorColorLight}}]];

                        let out = [];
                        for (let item of data.data) {
                            out.push([
                                {keyID: item.ID, type: "text", text: item.Name.replace("_CC", "")},
                                {keyID: item.ID, type: "text", text: item.Expression},
                                {keyID: item.ID, type: "custom", data: <div>
                                    <StyledButton style={{...tableButtonStyle, marginRight: "10px"}} onClick={() => {
                                        animateBox(<CampaignCustomColumnsModal_add edit={item} columns={props.columns} onChange={() => {
                                            if (typeof(props.onChange) === "function") props.onChange();
                                            getData();
                                        }} integrationType={props.integrationType} />);
                                    }}>Edit</StyledButton>
                                    <StyledButton style={tableButtonStyle} onClick={() => removeColumn(item)}>Remove</StyledButton>
                                </div>}
                            ]);
                        };

                        if (out.length === 0) return [[{keyID: "noData-noData", type: "text", text: "Nothing to show..."}]];
                        return out;
                    })()}
                />
            </div>
        </div>
    </div>
};

const CampaignCustomColumnsModal_add = props => {
    const [spinner, setSpinner] = React.useState(false);
    const [infoP, setInfoP] = React.useState({inputs: [], hadError: false, text: ""});
    let allColumns = Array.isArray(props.columns) ? props.columns.map(c => c.replace(/ /g, "_")) : [];

    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");

    const nameRef = React.useRef();
    const expressionRef = React.useRef();

    const InsertColumn = props2 => {
        const selectionRef = React.useRef();

        return <div className="genericModal">
            <div className="genericModal__wrap">
                <div className="genericModal__wrap__head">
                    <div className="genericModal__wrap__head__left">Select column</div>
                    <div className="genericModal__wrap__head__right" onClick={props2.onClose} style={{backgroundImage: `url("/images/icon_close.svg")`}}></div>
                </div>

                <div className="genericModal__wrap__input">
                    <p>Column</p>
                    <Dropdown
                        accent="#6C5DD3"
                        theme={themeSelector}
                        data={allColumns.map(col => {
                            return {name: col.replace(/_/g, " "), value: col}
                        })}
                        onChange={e => selectionRef.current = e?.value}
                    />
                </div>

                <div className="genericModal__wrap__buttons">
                    <div className="genericModal__wrap__buttons__btn genericModal__wrap__buttons__btn--secondary" onClick={props2.onClose}>Close</div>
                    <div className="genericModal__wrap__buttons__btn" onClick={() => {
                        if (!selectionRef.current) return;
                        props2.onChange(selectionRef.current);
                        props2.onClose();
                    }}>Insert</div>
                </div>
            </div>
        </div>
    };

    const getVariables = (expr) => {
        const node = mathjs.parse(expr);
        const variables = [];
        node.traverse((node) => {
            if (node.isSymbolNode && !(node.name in mathjs)) {
                variables.push(node.name);
            };
        });
        return [...new Set(variables)];
    };

    const addColumn = () => {
        if (spinner) return;
        setInfoP(ip => {return {...ip, hadError: false, inputs: []}});

        let curName = nameRef.current.value;
        if (!curName) return setInfoP(ip => {return {...ip, hadError: true, inputs: ["name"], text: "Column name can't be empty!"}});
        if (!curName.match(/^[a-zA-Z0-9_-]+$/gmis)) return setInfoP(ip => {return {...ip, hadError: true, inputs: ["name"], text: "Column name cant contain anything except 'a-z', 'A-Z', '0-9', '_', '-'"}})
        if (!curName.match(/^[A-Za-z].*$/gmis)) return setInfoP(ip => {return {...ip, hadError: true, inputs: ["name"], text: "Column name must start with a letter (a-z or A-Z)"}});
        
        let curExpression = expressionRef.current.value;
        if (!curExpression) return setInfoP(ip => {return {...ip, hadError: "true", inputs: ["expression"], text: "Expression can't be empty"}})
        let variables = [];
        try {
            variables = getVariables(curExpression);
        } catch {
            return setInfoP(ip => {return {...ip, hadError: true, inputs: ["expression"], text: "Expression is not valid (syntax error)"}});
        };
        
        for (let v of variables) {
            if (!allColumns.includes(v)) variables = variables.filter(vf => vf !== v);
        };

        // test the exec
        try {
            let scope = {};
            for (let v of variables) scope[v] = 10;
            let out = limitedEvaluate(curExpression, scope);
            if (Array.isArray(out?.entries)) out = out.entries.pop();
            if (typeof(out) !== "number") return setInfoP(ip => {return {...ip, hadError: true, inputs: ["expression"], text: `Expected a number, but got ${typeof(out)}`}});
        } catch (e) {
            return setInfoP(ip => {return {...ip, hadError: true, inputs: ["expression"], text: ["Error while evaluating the expression!", <br />, String(e).replace("Error: ", "")]}});
        };

        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/campaigns/customColumns/${props.edit ? "edit" : "create"}`,
            data: {
                Name: curName,
                Expression: curExpression,
                RequiredVariables: variables,


                ...(props.edit ? {ID: props.edit.ID} : {})
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                if (typeof(props.onChange) === "function") props.onChange();
                props.onClose();
            } else {
                if (res.data.data === "ALREADY_EXISTS") {
                    return setInfoP(ip => {return {...ip, hadError: true, inputs: [], text: `Column ${curName} already exists!`}});
                };
                setInfoP(ip => {return {...ip, hadError: true, inputs: [], text: `There was an error while ${props.edit ? "editing" : "adding"} the column`}});
            };
        }).catch(() => {
            setInfoP(ip => {return {...ip, hadError: true, inputs: [], text: "Server timed out!"}});
        }).finally(() => {
            setSpinner(false);
        });
    };

    return <div className="genericModal">
        <div className="genericModal__wrap">
            <div className="genericModal__wrap__head">
                <div className="genericModal__wrap__head__left">{props.edit ? "Edit" : "Add"} column</div>
                <div className="genericModal__wrap__head__right" onClick={props.onClose} style={{backgroundImage: `url("/images/icon_close.svg")`}}></div>
            </div>

            <div className={`genericModal__wrap__input ${infoP.inputs.includes("name") ? "genericModal__wrap__input--error" : ""}`}>
                <p>Column name</p>
                <input ref={nameRef} defaultValue={props.edit?.Name ?? ""} readOnly={!!props.edit} type="text" placeholder="Column name" />
            </div>
            <div className={`genericModal__wrap__input genericModal__wrap__input--text ${infoP.inputs.includes("expression") ? "genericModal__wrap__input--error" : ""}`}>
                <p>
                    <span>Column expression</span>
                    <StyledButton style={{height: "100%", padding: "5px 10px", marginLeft: "10px"}} onClick={() => {
                        animateBox(<InsertColumn onChange={e => {
                            expressionRef.current.value += e;
                        }} />);
                    }}>Insert column</StyledButton>
                </p>
                <textarea ref={expressionRef} defaultValue={props.edit?.Expression ?? ""} placeholder="Visits * 2, Spent_IN / Clicks_IN * Visits....etc"></textarea>
            </div>

            <div className="genericModal__wrap__buttons">
                <div className="genericModal__wrap__buttons__btn genericModal__wrap__buttons__btn--secondary" onClick={() => animateBox(<CampaignCustomColumnsModal_examples />)}>Examples</div>
                <div className="genericModal__wrap__buttons__btn genericModal__wrap__buttons__btn--secondary" onClick={props.onClose}>Close</div>
                <div className="genericModal__wrap__buttons__btn" onClick={() => !spinner && addColumn()}>{spinner ? <Spinner style={{width: "17px", height: "17px"}} color={themeSelector === "dark" ? "white" : "black"} /> : (props.edit ? "Edit" : "Add")}</div>
            </div>

            {infoP.text && <p className="genericModal__wrap__infoP" style={{opacity: infoP?.hadError ? 1 : 0}}>{infoP.text}</p>}
        </div>
    </div>
};

const CampaignCustomColumnsModal_examples = props => {
    return <div className="genericModal">
        <div className="genericModal__wrap">
            <div className="genericModal__wrap__head">
                <div className="genericModal__wrap__head__left">Examples</div>
                <div className="genericModal__wrap__head__right" onClick={props.onClose} style={{backgroundImage: `url("/images/icon_close.svg")`}}></div>
            </div>

            <p style={{color: "gray", marginBottom: "20px"}}>
                <span>NOTE: Some columns are pulled from integrations (like facebook), and have suffix _IN (eg: Spent_IN)</span>
                <br />
                <span>Its best to check your 'campaigns' first for column names.</span>
            </p>

            <p>Multiply visits by 2</p>
            <div className="modals__campaignCustomColumns__example">Visits * 2</div>

            <p>Calculate ROAS</p>
            <div className="modals__campaignCustomColumns__example">Revenue / Spent</div>
            <p>Calculate ROI</p>
            <div className="modals__campaignCustomColumns__example">((Revenue - Spent) / Spent) * 100</div>

            <p>Conditional expression</p>
            <div className="modals__campaignCustomColumns__example">Visits &gt; 0 and Spent &gt; 0 ? Spent / Visits : 0</div>

            <p>Unit conversions</p>
            <div className="modals__campaignCustomColumns__example">number(120 seconds, minutes)</div>

            <p>Functions</p>
            <div className="modals__campaignCustomColumns__example">{`f(x) = x*2; f(5);`}</div>
        </div>
    </div>
};

export default CampaignCustomColumnsModal;