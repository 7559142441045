import React from "react";
import "./index.scss";

import axios from "axios";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";

import * as backendModule from "../../../../../modules/backendModule";
import * as basicStylesModule from "../../../../../modules/basicStylesModule";
import * as siteFunctionsActions from "../../../../../actions/siteFunctionsActions";
import { animateBox } from "../../../../../modules/componentAnimation";
import useDefer from "../../../../../modules/hooks/useDefer";
import useOnScreen from "../../../../../modules/hooks/useOnScreen";

import { FilteredCustomTable } from "../../../../../components/customComponents/Table";
import Dropdown from "../../../../../components/customComponents/Dropdown";
import Spinner from "../../../../../components/customComponents/Spinner";
import StyledInput from "../../../../../components/styledComponents/Input";
import StyledButton from "../../../../../components/styledComponents/Button";
import RadioButton from "../../../../../components/customComponents/RadioButton";

import YesNoModal from "../../../../../components/modals/YesNoModal";

const AdminAutomations_FbWatcher = () => {
    const [data, setData] = React.useState();
    const [offers, setOffers] = React.useState();
    const [showOffers, setShowOffers] = React.useState(true);
    const [allUsers, setAllUsers] = React.useState(false);
    const [search, setSearch] = React.useState("");

    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");
    const userInfoSelector = useSelector(state => state?.userData?.userData?.UserInfo ?? {});
    const curDispatch = useDispatch();
    const curDefer = useDefer();
    const searchDefer = useDefer();

    const tableButtonStyle = {
        height: "100%",
        margin: "0 auto",
        display: "inline-block",
        background: "transparent",
        border: "1px solid #5C7582B2",
        color: themeSelector === "dark" ? "white" : "black",
        padding: "0 10px",
        fontSize: "14px"
    };

    const getData = () => {
        let filters = [];
        if (!allUsers) {
            filters.push({name: "UserID", op: "eq", value: userInfoSelector?.ID || null});
        };

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/integrations/facebook/watcher/getAll`,
            data: {
                limit: null,
                orders: [{name: "createdAt", order: "desc"}],
                filters
            },
            ...backendModule.axiosConfig
        }).then(res => setData(res.data)).catch(() => setData(backendModule.genericError));
    };

    const getOffers = () => {
        if (offers?.status === "ok") return;
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/offers/getAllOffers`,
            data: {
                limit: null
            },
            ...backendModule.axiosConfig
        }).then(res => {
            setOffers(res.data);
        }).catch(() => {
            setOffers(backendModule.genericError);
        });
    };

    const getStatusText = (item, isBackup) => {
        if (isBackup) {
            if (item.BackupErrorStatusText) return themeSelector === "dark" ? basicStylesModule.errorColor : basicStylesModule.errorColorLight;
            if (item.BackupStatus === 0) return "gray";
            if (item.BackupStatus >= 90 && item.Status <= 99) return themeSelector === "dark" ? basicStylesModule.errorColor : basicStylesModule.errorColorLight;
            if (item.BackupStatus >= 100) return themeSelector === "dark" ? basicStylesModule.successColor : basicStylesModule.successColorLight;
        } else {
            if (item.ActiveErrorStatusText) return themeSelector === "dark" ? basicStylesModule.errorColor : basicStylesModule.errorColorLight;
            if (item.ActiveStatus === 0) return "gray";
            if (item.ActiveStatus >= 90 && item.Status <= 99) return themeSelector === "dark" ? basicStylesModule.errorColor : basicStylesModule.errorColorLight;
            if (item.ActiveStatus >= 100) return themeSelector === "dark" ? basicStylesModule.successColor : basicStylesModule.successColorLight;
        };
        return "orange";
    };

    const skipWarmup = (ID, isBackup = false) => {
        animateBox(<YesNoModal
            heading="Are you sure?"
            text="This account is not yet ready to be used. Are you sure that you want to prematurely skip to the next step?"
            buttonLeftText="Cancel"
            buttonRightText="Skip"
            isRightButtonNormal={true}
            buttonRightCallback={args => {
                args.spinner(true);
                args.disabledAll(true);
                args.errorMessage("");

                axios({
                    method: "POST",
                    url: `${backendModule.backendURL}/integrations/facebook/watcher/skipWarmup`,
                    data: {
                        ID,
                        isBackup
                    },
                    ...backendModule.axiosConfig
                }).then(res => {
                    if (res.data.status === "ok") {
                        getData();
                        args.close();
                    } else {
                        args.errorMessage("Error while skipping to the next step!");
                    };
                }).catch(() => {
                    args.errorMessage("Server timed out!");
                }).finally(() => {
                    args.spinner(false);
                    args.disabledAll(false);
                });
            }}
        />);
    };

    const removeAccount = (ID) => {
        animateBox(<YesNoModal
            heading="Are you sure?"
            text="This will permanently remove the account from this list. Any warmup started will automatically be stopped."
            buttonLeftText="Cancel"
            buttonRightText="Remove"
            isRightButtonNormal={true}
            buttonRightCallback={args => {
                args.spinner(true);
                args.disabledAll(true);
                args.errorMessage("");

                axios({
                    method: "POST",
                    url: `${backendModule.backendURL}/integrations/facebook/watcher/remove`,
                    data: {
                        ID
                    },
                    ...backendModule.axiosConfig
                }).then(res => {
                    if (res.data.status === "ok") {
                        getData();
                        args.close();
                    } else {
                        args.errorMessage("There was an error while removing the account");
                    };
                }).catch(() => {
                    args.errorMessage("Server timed out!");
                }).finally(() => {
                    args.spinner(false);
                    args.disabledAll(false);
                });
            }}
        />);
    };

    const removeBackupAccount = (ID) => {
        animateBox(<YesNoModal
            heading="Are you sure?"
            text="This will permanently remove the backup account from this list. Any warmup started will automatically be stopped."
            buttonLeftText="Cancel"
            buttonRightText="Remove"
            isRightButtonNormal={true}
            buttonRightCallback={args => {
                args.spinner(true);
                args.disabledAll(true);
                args.errorMessage("");

                axios({
                    method: "POST",
                    url: `${backendModule.backendURL}/integrations/facebook/watcher/removeBackup`,
                    data: {
                        ID
                    },
                    ...backendModule.axiosConfig
                }).then(res => {
                    if (res.data.status === "ok") {
                        getData();
                        args.close();
                    } else {
                        args.errorMessage("There was an error while removing the backup account");
                    };
                }).catch(() => {
                    args.errorMessage("Server timed out!");
                }).finally(() => {
                    args.spinner(false);
                    args.disabledAll(false);
                });
            }}
        />);
    };

    const activateBackupAccount = (ID) => {
        animateBox(<YesNoModal
            heading="Are you sure?"
            text="This will remove the main account from the list, and move the backup account to the main position. After this you will need to add a new backup account as soon as possible!!"
            buttonLeftText="Cancel"
            buttonRightText="Switch"
            isRightButtonNormal={true}
            buttonRightCallback={args => {
                args.spinner(true);
                args.disabledAll(true);
                args.errorMessage("");

                axios({
                    method: "POST",
                    url: `${backendModule.backendURL}/integrations/facebook/watcher/activateBackup`,
                    data: {
                        ID
                    },
                    ...backendModule.axiosConfig
                }).then(res => {
                    if (res.data.status === "ok") {
                        getData();
                        args.close();
                    } else {
                        args.errorMessage("There was an error while moving to the backup account");
                    };
                }).catch(() => {
                    args.errorMessage("Server timed out!");
                }).finally(() => {
                    args.spinner(false);
                    args.disabledAll(false);
                });
            }}
        />);
    };

    const showOffersText = itemOffers => {
        if (!showOffers) return `${itemOffers?.length ?? 0} offers`;
        let tmp = Array.isArray(itemOffers) ? itemOffers : [];

        if (tmp.length === 0 || offers?.status !== "ok") return "0 offers";

        let finalOffers = [];
        for (let o of tmp) {
            let foundOffer = offers.data.find(fo => fo.ID === o);
            if (foundOffer) {
                finalOffers.push(`${foundOffer.OfferName} (${foundOffer.OfferType ?? "-"}, ${foundOffer.Country ?? "-"})`);
            } else {
                finalOffers.push("?");
            };
        };

        return finalOffers.join(", ");
    };

    const performSearch = items => {
        if (!items) return items;
        if (items.length === 0) return items;

        let searchItems = search.toLowerCase().split(" ").filter(f => f);

        let tmp = items.filter(i => {
            let columnsMatched = i.map((ic, icIdx) => {
                if (icIdx === 0) return null;
                let text = ic?.text;
                text = String(text).toLowerCase();
                for (let ss of searchItems) {
                    if (text.includes(ss)) return ss;
                }
                return null;
            }).filter(f => f);
            return [...new Set(columnsMatched)].length === searchItems.length;
        });

        return tmp;
    };

    React.useEffect(() => {
        const handler = () => {
            setData();
            curDefer(() => {
                getData();
                getOffers();
            }, 500);
        };

        handler();
        curDispatch(siteFunctionsActions.addHeaderRefreshAction(handler));

        return () => curDispatch(siteFunctionsActions.removeHeaderRefreshAction(handler));
    }, [allUsers]);

    return <div className="route__automations__fbWatcher">
        <div className="route__automations__fbWatcher__filters">
            <StyledInput alternateStyle placeholder="Search..." onChange={e => {
                let tmp = e.target.value;
                searchDefer(() => setSearch(tmp), 500);
            }} />
            <StyledButton onClick={() => {
                animateBox(<AdminAutomations_FbWatcher_add onChange={getData} />);
            }} style={{display: "flex", alignItems: "center", gap: "10px"}}>
                <img src="/images/icon_add.svg" />
                <span>Add</span>
            </StyledButton>
        </div>
        <div className="route__automations__fbWatcher__filters2">
            <div>
                <RadioButton checked={showOffers} onChange={e => e !== showOffers && setShowOffers(e)} />
                <span>Show offers</span>
            </div>
            <div>
                <RadioButton checked={allUsers} onChange={e => e !== allUsers && setAllUsers(e)} />
                <span>All users</span>
            </div>
        </div>

        <FilteredCustomTable
            theme={themeSelector}
            accent="#6C5DD3"
            headers={[
                "", "User",
                "Account name", "Page name", "Offers", "Domain", "AdsPowerID", "Status", "-",
                "Backup name", "Page name", "Offers", "Domain", "AdsPowerID", "Status", "", "", ""
            ]}
            customHeaders={data?.status === "ok" ? {
                "-": <div style={{
                    position: "absolute",
                    top: 0,
                    width: "2px",
                    height: "100%",
                    backgroundColor: "gray"
                }}></div>
            } : undefined}
            customColumns={(new Array(16)).fill("max-content")}
            style={{
                columnGap: "40px",
                maxWidth: "100%",
                overflow: "auto"
            }}
            data={(()=>{
                if (!data || !offers) return [[{keyID: "noData-spinner", type: "spinner", color: themeSelector === "dark" ? "white" : "black"}]];
                if (data.status === "error" || offers.status === "error") return [[{keyID: "noData-error", type: "text", text: "Error while fetching data!", style: {color: themeSelector === "dark" ? basicStylesModule.errorColor : basicStylesModule.errorColorLight}}]];

                let out = [];
                let count = 0;
                for (let item of data.data) {
                    count += 1;
                    out.push([
                        {keyID: item.ID, type: "text", text: count},
                        {keyID: item.ID, type: "text", text: item.Username},
                        {keyID: item.ID, type: "text", text: `[ ${item.ActiveAccountID.replace("fb-act_", "")} ] ${item.ActiveAccountName}`},
                        {keyID: item.ID, type: "text", text: item.ActiveAccountPageName},
                        {keyID: item.ID, type: "text", text: showOffersText(item.ActiveAccountOffers), style: {cursor: "pointer"}, onClick: () => animateBox(<AdminAutomations_FbWatcher_offers onChange={getData} ID={item.ID} offers={item.ActiveAccountOffers} />)},
                        {keyID: item.ID, type: "text", text: item.ActiveAccountCMSDomainName || "-"},
                        {keyID: item.ID, type: "text", text: item.ActiveAccountAdsPowerID || "-", onClick: () => animateBox(<AdminAutomations_FbWatcher_changeAdsPower isMain={true} ID={item.ID} defaultValue={item.ActiveAccountAdsPowerID} onChange={getData} />), style: {cursor: "pointer"}},
                        {keyID: item.ID, type: "text", text: <>
                            <span style={{cursor: "pointer"}} onClick={() => animateBox(<AdminAutomations_FbWatcher_history ID={item.ID} />)}>{item.ActiveErrorStatusText || item.ActiveStatusText || "-"}</span>
                            {(item.ActiveStatus === 10 || item.ActiveStatus === 20) && <StyledButton onClick={() => skipWarmup(item.ID, false)} style={{...tableButtonStyle, marginLeft: "5px"}}>Skip</StyledButton>}
                        </>, style: {color: getStatusText(item)}},

                        {keyID: item.ID, type: "text", text: ""}, // spacer,

                        ...(item.BackupAccountID ? [
                            {keyID: item.ID, type: "text", text: `[ ${item.BackupAccountID.replace("fb-act_", "")} ] ${item.BackupAccountName}`},
                            {keyID: item.ID, type: "text", text: item.BackupAccountPageName},
                            {keyID: item.ID, type: "text", text: showOffersText(item.BackupAccountOffers)},
                            {keyID: item.ID, type: "text", text: item.BackupAccountCMSDomainName || "-"},
                            {keyID: item.ID, type: "text", text: item.BackupAccountAdsPowerID || "-", onClick: () => animateBox(<AdminAutomations_FbWatcher_changeAdsPower isMain={false} ID={item.ID} defaultValue={item.BackupAccountAdsPowerID} onChange={getData} />), style: {cursor: "pointer"}},
                            {keyID: item.ID, type: "text", text: <>
                                <span style={{cursor: "pointer"}} onClick={() => animateBox(<AdminAutomations_FbWatcher_history ID={item.ID} isBackup />)}>{item.BackupErrorStatusText || item.BackupStatusText || "-"}</span>
                                {(item.BackupStatus === 10 || item.BackupStatus === 20) && <StyledButton onClick={() => skipWarmup(item.ID, true)} style={{...tableButtonStyle, marginLeft: "5px"}}>Skip</StyledButton>}
                            </>, style: {color: getStatusText(item, true)}},
                        ] : [
                            {keyID: item.ID, type: "custom", data: <StyledButton style={tableButtonStyle} onClick={() => animateBox(<AdminAutomations_FbWatcher_add isBackup={item.ID} onChange={getData} />)}>Add backup account</StyledButton>}
                        ]),

                        ((item.BackupAccountID) ? {keyID: item.ID, type: "custom", data: <StyledButton style={tableButtonStyle} onClick={() => activateBackupAccount(item.ID)}>Switch to backup account</StyledButton>} : null),
                        (!item.BackupAccountID ? {keyID: item.ID, type: "custom", data: <StyledButton style={tableButtonStyle} onClick={() => removeAccount(item.ID)}>Remove account</StyledButton>} : {keyID: item.ID, type: "custom", data: <StyledButton style={tableButtonStyle} onClick={() => removeBackupAccount(item.ID)}>Remove backup</StyledButton>})
                    ].filter(f => f))
                };
                if (search) out = performSearch(out);
                if (out.length === 0) return [[{keyID: "noData-noData", type: "text", text: "Nothing to show..."}]];
                return out;
            })()}
            noTimeout={true}
            canAnimate={false}
        />
    </div>
};

const AdminAutomations_FbWatcher_changeAdsPower = props => {
    const [spinner, setSpinner] = React.useState(false);

    const newIDRef = React.useRef();

    const updateID = () => {
        if (spinner) return;
        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/integrations/facebook/watcher/editAdsPowerID`,
            data: {
                ID: props.ID,
                isMain: props.isMain,
                newID: newIDRef.current.value
            },
            ...backendModule.axiosConfig
        }).then(() => null).catch(() => null).finally(() => {
            setSpinner(false);
            if (typeof(props.onChange) === "function") props.onChange();
            props.onClose();
        })
    };

    return <div className="genericModal">
        <div className="genericModal__wrap">
            <div className="genericModal__wrap__head">
                <div className="genericModal__wrap__head__left">Edit AdsPower ID</div>
                <div className="genericModal__wrap__head__right" onClick={props.onClose} style={{backgroundImage: `url("/images/icon_close.svg")`}}></div>
            </div>

            <div className="genericModal__wrap__input">
                <p>AdsPower ID</p>
                <input ref={newIDRef} type="text" defaultValue={props.defaultValue ?? ""} />
            </div>

            <div className="genericModal__wrap__buttons">
                <div className="genericModal__wrap__buttons__btn genericModal__wrap__buttons__btn--secondary" onClick={props.onClose}>Close</div>
                <div className="genericModal__wrap__buttons__btn" onClick={updateID}>
                    {spinner ? <Spinner style={{width: "17px", height: "17px"}} color="white" /> : "Save"}
                </div>
            </div>
        </div>
    </div>
};

const AdminAutomations_FbWatcher_add = props => {
    const [accountID, setAccountID] = React.useState();
    const [pages, setPages] = React.useState();
    const [selectedPage, setSelectedPage] = React.useState();
    const [spinner, setSpinner] = React.useState(false);
    const [ignoreWarmup, setIgnoreWarmup] = React.useState(false);
    const [offers, setOffers] = React.useState([]);
    const [allOffers, setAllOffers] = React.useState();

    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");
    const accountInputRef = React.useRef();
    const adsPowerIDRef = React.useRef();
    const dropdownResetFnRef = React.useRef(() => null);

    const getPages = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/integrations/facebook/watcher/getPages`,
            data: {
                AccountID: accountID
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                setPages(res.data.data);
            } else {
                setPages([]);
            };
        }).catch(() => setPages([]));
    };

    const getOffers = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/offers/getAllOffers`,
            data: {
                limit: null,
                offset: 0,
                filters: [
                    {name: "isActive", op: "eq", value: true},
                    {name: "isOnHold", op: "eq", value: false}
                ]
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                setAllOffers(res.data.data);
            } else {
                setAllOffers([]);
            };
        }).catch(() => setAllOffers([]));
    };

    const addPage = () => {
        if (spinner) return;
        if (!selectedPage) return;

        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/integrations/facebook/watcher/${props.isBackup ? "createBackup" : "create"}`,
            data: {
                AccountID: accountID,
                PageID: selectedPage,
                AdsPowerID: adsPowerIDRef.current.value,
                WatcherID: props.isBackup || undefined,
                ignoreWarmup,
                
                ...(props.isSecondary ? {} : {
                    Offers: offers
                })
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                if (props.onChange) props.onChange();
                props.onClose();
            } else {
                let text = "There was an error while adding the account. Maybe it already exists?";
                if (res.data.data === "NOT_SAME_USER") text = "Backup account must be integrated on the same user as the main account!";
                animateBox(<YesNoModal
                    heading="Error"
                    text={text}
                    buttonLeftHidden={true}
                    buttonRightText="Ok"
                    isRightButtonNormal={true}
                />);
            };
        }).catch(() => {
            animateBox(<YesNoModal
                heading="Error"
                text="Server timed out!"
                buttonLeftHidden={true}
                buttonRightText="Ok"
                isRightButtonNormal={true}
            />);
        }).finally(() => {
            setSpinner(false);
        });
    };

    React.useEffect(() => {
        setPages();
        setSelectedPage();

        if (accountID) getPages();
    }, [accountID]);

    React.useEffect(() => {
        if (!props.isBackup) getOffers();
    }, []);

    return <div className="genericModal">
        <div className="genericModal__wrap">
            <div className="genericModal__wrap__head">
                <div className="genericModal__wrap__head__left">Add {props.isBackup ? "backup" : "account"}</div>
                <div className="genericModal__wrap__head__right" style={{backgroundImage: `url("/images/icon_close.svg")`}} onClick={props.onClose}></div>
            </div>

            {accountID ? <>
                <p style={{marginBottom: "20px"}}>Account ID: {accountID}</p>
                <div className="genericModal__wrap__input">
                    <p>Facebook page</p>
                    {pages ? <Dropdown
                        theme={themeSelector}
                        accent="#6C5DD3"
                        data={pages.map(p => {
                            return {name: p.Name, value: p.ID};
                        })}
                        onChange={e => e?.value !== selectedPage && setSelectedPage(e?.value)}
                        selected={(()=>{
                            if (!selectedPage) return null;
                            return pages.indexOf(pages.find(p => p.ID === selectedPage));
                        })()}
                    /> : <Spinner style={{width: "54px", height: "54px"}} color={themeSelector === "dark" ? "white" : "black"} />}
                </div>
                <div className="genericModal__wrap__input">
                    <p>AdsPower ID (optional)</p>
                    <input type="text" placeholder="AdsPower ID" ref={adsPowerIDRef} />
                </div>

                {/* OPTIONAL - add offers */}
                {!props.isBackup && <div className="genericModal__wrap__input">
                    <p>Select offers (optional - disables auto offer detect)</p>
                    {offers.map(o => {
                        let curOffer = allOffers.find(ao => ao.ID === o);
                        return <div>
                            <span>{curOffer?.OfferName ?? "-"} ({curOffer?.OfferType ?? "-"}, {curOffer?.Country ?? "-"})</span>
                            <StyledButton isSecondary style={{height: "100%", padding: "5px 10px", marginLeft: "10px"}} onClick={() => setOffers(ao => ao.filter(aof => aof !== o))}>Remove</StyledButton>
                        </div>
                    })}
                    {allOffers ? <>
                        <Dropdown
                            theme={themeSelector}
                            accent="#6C5DD3"
                            data={allOffers.filter(o => !offers.includes(o.ID)).map(o => {
                                return {name: `${o.OfferName} (${o.OfferType ?? "-"}, ${o.Country ?? "-"})`, value: o.ID};
                            })}
                            onReset={e => dropdownResetFnRef.current = e}
                            onChange={e => {
                                setOffers(o => [...new Set([...o, e.value])]);
                                if (dropdownResetFnRef.current) dropdownResetFnRef.current();
                            }}
                        />
                    </> : <Spinner style={{width: "17px", height: "17px"}} color="white" />}
                </div>}

                <div onClick={() => setIgnoreWarmup(iw => !iw)} style={{display: "flex", alignItems: "center", gap: "5px", marginBottom: "10px"}}>
                    <RadioButton checked={!ignoreWarmup} />
                    <span>Warmup enabled</span>
                </div>

                <div className="genericModal__wrap__buttons">
                    <div className="genericModal__wrap__buttons__btn genericModal__wrap__buttons__btn--secondary" onClick={() => setAccountID()}>Go back</div>
                    <div className="genericModal__wrap__buttons__btn" onClick={addPage}>
                        {spinner ? <Spinner style={{width: "17px", height: "17px"}} color="white" /> : "Add"}
                    </div>
                </div>

            </> : <>

                <div className="genericModal__wrap__input">
                    <p>Enter your account ID</p>
                    <input type="text" placeholder="xxxx or act_xxxx or fb-act_xxxx" ref={accountInputRef} />
                </div>

                <div className="genericModal__wrap__buttons">
                    <div className="genericModal__wrap__buttons__btn" onClick={() => {
                        if (accountInputRef.current.value) setAccountID(accountInputRef.current.value);
                    }}>Next</div>
                </div>

            </>}
        </div>
    </div>
};

const AdminAutomations_FbWatcher_offers = props => {
    const [offers, setOffers] = React.useState(props.offers ?? []);
    const [allOffers, setAllOffers] = React.useState();
    const [spinner, setSpinner] = React.useState(false);
    const [error, setError] = React.useState("");

    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");
    const dropdownResetFnRef = React.useRef(() => null);
    
    const getOffers = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/offers/getAllOffers`,
            data: {
                limit: null,
                offset: 0,
                filters: [
                    {name: "isActive", op: "eq", value: true},
                    {name: "isOnHold", op: "eq", value: false}
                ]
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                setAllOffers(res.data.data);
            } else {
                setAllOffers([]);
            };
        }).catch(() => setAllOffers([]));
    };

    const saveOffers = () => {
        if (offers.length === 0) return;
        if (spinner) return;
        setError("");

        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/integrations/facebook/watcher/editOffers`,
            data: {
                ID: props.ID,
                Offers: offers
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                if (typeof(props.onChange) === "function") props.onChange();
                props.onClose();
            } else {
                setError("Error while saving new offers");
            };
        }).catch(() => {
            setError("Server timed out");
        }).finally(() => {
            setSpinner(false);
        });
    };

    React.useEffect(() => {
        getOffers();
    }, []);

    return <div className="genericModal">
        <div className="genericModal__wrap">
            <div className="genericModal__wrap__head">
                <div className="genericModal__wrap__head__left">Edit offers</div>
                <div className="genericModal__wrap__head__right" onClick={props.onClose} style={{backgroundImage: `url("/images/icon_close.svg")`}}></div>
            </div>

            <div className="genericModal__wrap__input">
                <p>Offers</p>
                {allOffers ? <>
                    {offers.map(o => {
                        let curOffer = allOffers.find(ao => ao.ID === o);
                        return <div>
                            <span>{curOffer?.OfferName ?? "-"} ({curOffer?.OfferType ?? "-"}, {curOffer?.Country ?? "-"})</span>
                            <StyledButton isSecondary style={{height: "100%", padding: "5px 10px", marginLeft: "10px"}} onClick={() => setOffers(ao => ao.filter(aof => aof !== o))}>Remove</StyledButton>
                        </div>
                    })}
                    <Dropdown
                        theme={themeSelector}
                        accent="#6C5DD3"
                        data={allOffers.filter(o => !offers.includes(o.ID)).map(o => {
                            return {name: `${o.OfferName} (${o.OfferType ?? "-"}, ${o.Country ?? "-"})`, value: o.ID};
                        })}
                        onReset={e => dropdownResetFnRef.current = e}
                        onChange={e => {
                            setOffers(o => [...new Set([...o, e.value])]);
                            if (dropdownResetFnRef.current) dropdownResetFnRef.current();
                        }}
                    />
                </> : <Spinner style={{width: "17px", height: "17px"}} color={themeSelector === "dark" ? "white" : "black"} />}
            </div>

            <div className="genericModal__wrap__buttons">
                <div className="genericModal__wrap__buttons__btn genericModal__wrap__buttons__btn--secondary" onClick={props.onClose}>Close</div>
                {offers.length > 0 && <div className="genericModal__wrap__buttons__btn" onClick={() => !spinner && saveOffers()}>
                    {spinner ? <Spinner style={{width: "16px", height: "16px"}} color="white" /> : "Save"}
                </div>}
            </div>

            <p className="genericModal__wrap__infoP" style={{opacity: 1}}>{error}</p>
        </div>
    </div>
};

const AdminAutomations_FbWatcher_history = props => {
    const [data, setData] = React.useState();
    const [canPaginate, setCanPaginate] = React.useState(false);

    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");
    const wrapRef = React.useRef();
    const timestampRef = React.useRef();

    const curOnScreen = useOnScreen();
    const curDefer = useDefer();

    const getData = (ts) => {
        if (timestampRef.current !== ts) return;
        setCanPaginate(false);

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/integrations/facebook/watcher/getHistory`,
            data: {
                ID: props.ID,
                limit: 20,
                offset: 0,
                orders: [{name: "createdAt", order: "desc"}],
                filters: [
                    {name: "isBackup", op: "eq", value: props.isBackup ? true : false}
                ].filter(f => f)
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (timestampRef.current !== ts) return;
            setData(res.data);
            if (res.data.status === "ok") {
                if (res.data.data.length >= 20) setCanPaginate(true);
            };
        }).catch(() => {
            if (timestampRef.current !== ts) return;
            setData(backendModule.genericError);
        });
    };

    const continueData = ts => {
        if (timestampRef.current !== ts) return;
        if (!canPaginate) return;
        if (data?.status !== "ok") return;
        setCanPaginate(false);

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/integrations/facebook/watcher/getHistory`,
            data: {
                ID: props.ID,
                limit: 20,
                offset: 0,
                orders: [{name: "createdAt", order: "desc"}],
                filters: [
                    {name: "ID", op: "notIn", value: data.data.map(d => d.ID)},
                    {name: "isBackup", op: "eq", value: props.isBackup ? true : false}
                ].filter(f => f)
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (timestampRef.current !== ts) return;

            if (res.data.status === "ok") {
                setData(d => {
                    return {
                        ...d,
                        data: [
                            ...d.data,
                            ...res.data.data
                        ]
                    };
                });
            };
        }).catch(() => null);
    };

    const onClose = () => {
        if (wrapRef.current) {
            wrapRef.current.animate([
                { right: getComputedStyle(wrapRef.current).right },
                { right: "-100%" }
            ], {
                duration: 300,
                iterations: 1,
                fill: "both",
                easing: "ease"
            });
        };

        props.onClose();
    };

    React.useEffect(() => {
        if (!wrapRef.current) return;

        wrapRef.current.animate([
            { right: getComputedStyle(wrapRef.current).right },
            { right: 0 }
        ], {
            duration: 300,
            iterations: 1,
            fill: "both",
            easing: "ease"
        });
    }, [wrapRef.current]);

    React.useEffect(() => {
        if (!canPaginate) return;
        if (!curOnScreen.isIntersecting) return;

        try {
            curOnScreen.observer.unobserve(curOnScreen.measureRef.current);
        } catch {};

        let ts = Date.now();
        timestampRef.current = ts;
        continueData(ts);
    }, [canPaginate, curOnScreen.isIntersecting]);

    React.useEffect(() => {
        let ts = Date.now();
        timestampRef.current = ts;
        getData(ts);
    }, []);

    return <div className="route__automations__fbWatcher__history" onClick={onClose}>
        <div className="route__automations__fbWatcher__history__wrap" ref={wrapRef} onClick={e => e?.stopPropagation?.()}>
            <div className="route__automations__fbWatcher__history__wrap__top">
                <div className="route__automations__fbWatcher__history__wrap__top__left">History</div>
                <div className="route__automations__fbWatcher__history__wrap__top__right"><img src="/images/icon_close.svg" onClick={() => onClose()} /></div>
            </div>

            <div className="route__automations__fbWatcher__history__wrap__content">
                <FilteredCustomTable
                    theme={themeSelector}
                    accent="#6C5DD3"
                    headers={["Date", "Value"]}
                    customColumns={(new Array(2)).fill("max-content")}
                    style={{columnGap: "40px"}}
                    data={(()=>{
                        if (!data) return [[{keyID: "noData-spinner", type: "spinner", color: themeSelector === "dark" ? "white" : "black"}]];
                        if (data.status !== "ok") return [[{keyID: "noData-error", type: "text", text: "Error while fetching data", style: {color: themeSelector === "dark" ? basicStylesModule.errorColor : basicStylesModule.errorColorLight}}]];

                        let out = [];
                        for (let item of data.data) {
                            out.push([
                                {keyID: item.ID, type: "text", text: moment(item.createdAt).toDate().toLocaleString()},
                                {keyID: item.ID, type: "text", text: item.Text}
                            ])
                        };

                        if (out.length === 0) return [[{keyID: "noData-noData", type: "text", text: "Nothing to show..."}]];
                        return out;
                    })()}
                />
                {canPaginate && <div ref={curOnScreen.measureRef}>
                    <Spinner style={{width: "32px", height: "32px"}} color={themeSelector === "dark" ? "white" : "black"} />
                </div>}
            </div>
        </div>
    </div>
};

export default AdminAutomations_FbWatcher;