import React from "react";

import axios from "axios";
import moment from "moment";

import { useSelector } from "react-redux";

import * as backendModule from "../../../modules/backendModule";
import * as basicStylesModule from "../../../modules/basicStylesModule";
import useDefer from "../../../modules/hooks/useDefer";
import useOnScreen from "../../../modules/hooks/useOnScreen";

import { FilteredCustomTable } from "../../../components/customComponents/Table";
import Spinner from "../../../components/customComponents/Spinner";

const AccountManager_apiHistory = props => {
    const [data, setdata] = React.useState();
    const [canPaginate, setCanPaginate] = React.useState(false);
    const wrapRef = React.useRef();
    const timestampRef = React.useRef();

    const curOnScreen = useOnScreen();
    const curDefer = useDefer();

    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");

    const getData = ts => {
        if (timestampRef.current !== ts) return;
        setCanPaginate(false);

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/common/getIntegrationAPIHistory`,
            data: {
                AccountID: props.AccountID,
                IntegrationID: props.IntegrationID,
                limit: 100,
                offset: 0,
                orders: [{name: "createdAt", order: "desc"}]
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (timestampRef.current !== ts) return;
            setdata(res.data);
            if (res.data.status === "ok") {
                if (res.data.data.length >= 100) setCanPaginate(true);
            };
        }).catch(() => {
            if (timestampRef.current !== ts) return;
            setdata(backendModule.genericError);
        });
    };

    const continueData = ts => {
        if (timestampRef.current !== ts) return;
        if (!canPaginate) return;
        if (data?.status !== "ok") return;

        setCanPaginate(false);

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/common/getIntegrationAPIHistory`,
            data: {
                AccountID: props.AccountID,
                IntegrationID: props.IntegrationID,
                limit: 100,
                offset: 0,
                orders: [{name: "createdAt", order: "desc"}],
                filters: [
                    {name: "ID", op: "notIn", value: data.data.map(d => d.ID)}
                ]
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (timestampRef.current !== ts) return;
            if (res.data.status === "ok") {
                setdata(d => {
                    return {
                        ...d,
                        data: [
                            ...d.data,
                            ...res.data.data
                        ]
                    };
                });
                if (res.data.data.length >= 100) setCanPaginate(true);
            };
        }).catch(() => null);
    };

    const onClose = () => {
        if (wrapRef.current) {
            wrapRef.current.animate([
                { right: getComputedStyle(wrapRef.current).right },
                { right: "-100%" }
            ], {
                duration: 300,
                iterations: 1,
                fill: "both",
                easing: "ease"
            });
        };

        props.onClose();
    };

    React.useEffect(() => {
        if (!canPaginate) return;
        if (!curOnScreen.isIntersecting) return;

        try {
            curOnScreen.observer.unobserve(curOnScreen.measureRef.current);
        } catch {};

        let ts = Date.now();
        timestampRef.current = ts;
        continueData(ts);
    }, [curOnScreen.isIntersecting, canPaginate]);

    React.useEffect(() => {
        if (!wrapRef.current) return;

        wrapRef.current.animate([
            { right: getComputedStyle(wrapRef.current).right },
            { right: 0 }
        ], {
            duration: 300,
            iterations: 1,
            fill: "both",
            easing: "ease"
        });
    }, [wrapRef.current]);

    React.useEffect(() => {
        let ts = Date.now();
        timestampRef.current = ts;
        getData(ts);
    }, []);

    return <div className="route__user__accountManager__activityAds" onClick={() => onClose()}>
        <div className="route__user__accountManager__activityAds__wrap" ref={wrapRef} onClick={e => e?.stopPropagation()}>

            <div className="route__user__accountManager__activityAds__wrap__top">
                <div className="route__user__accountManager__activityAds__wrap__top__left">API History for {props.AccountID}</div>
                <div className="route__user__accountManager__activityAds__wrap__top__right"><img src="/images/icon_close.svg" onClick={() => onClose()} /></div>
            </div>

            <div className="route__user__accountManager__activityAds__wrap__content">
                <FilteredCustomTable
                        canAnimate={false}
                        noTimeout={true}
                        accent="#6C5DD3"
                        theme={themeSelector}
                        headers={["Date", "Message"]}
                        style={{columnGap: "20px"}}
                        customColumns={["max-content", "max-content"]}
                        data={(()=>{
                            if (!data) return [[{keyID: "noData-spinner", type: "spinner", color: themeSelector === "dark" ? "white" : "black"}]];
                            if (data.status !== "ok") return [[{keyID: "noData-error", type: "text", text: "Error while fetching data!", style: {color: themeSelector === "dark" ? basicStylesModule.errorColor : basicStylesModule.errorColorLight}}]];

                            let out = [];
                            for (let item of data.data) {
                                out.push([
                                    {keyID: item.ID, type: "text", text: moment(item.createdAt).toDate().toLocaleString()},
                                    {keyID: item.ID, type: "text", text: item.Message, style: {color: item.isError ? (themeSelector === "dark" ? basicStylesModule.errorColor : basicStylesModule.errorColorLight) : null}},
                                ])
                            };

                            if (out.length === 0) return [[{keyID: "noData-noData", type: "text", text: "Nothing to show..."}]];
                            return out;
                        })()}
                    />
                    {canPaginate && <div ref={curOnScreen.measureRef}><Spinner color={themeSelector === "dark" ? "white" : "black"} /></div>}
            </div>
        </div>
    </div>
};

export default AccountManager_apiHistory;