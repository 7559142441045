import React from "react";
import "./index.scss";

import { useNavigate } from "react-router-dom";

import animateModule from "../../../modules/animateModule";

const AdminAutomations = () => {
    const curNavigate = useNavigate();

    const animateNavigate = to => animateModule(curNavigate, to, document.querySelector(".root__content"));

    return <div className="route__automations">
        {/* <div className="route__automations__item" onClick={() => animateNavigate("/admin-automations/fb-adgen")}>
            <h3>Facebook Ad generator</h3>
            <p>Easy way to generate ads across multiple facebook ad accounts</p>
        </div>
        <div className="route__automations__item" onClick={() => animateNavigate("/admin-automations/fb-warmup")}>
            <h3>Facebook Account warmup</h3>
            <p>Warms up new ad accounts by creating page engagement ads periodically</p>
        </div>
        <div className="route__automations__item" onClick={() => animateNavigate("/admin-automations/fb-watcher")}>
            <h3>Facebook Account backup list</h3>
            <p>Warms up and shows the list of all added ad accounts and their backups</p>
        </div> */}
        <div className="route__automations__item" onClick={() => animateNavigate("/admin-automations/fb-warmup")}>
            <h3>Facebook campaign automation</h3>
            <p>Automatically sets up ad accounts for creating Page engagement campaigns</p>
        </div>
    </div>
};

export default AdminAutomations;